.grid_1, .grid_2, .grid_3, .grid_4, .grid_5, .grid_6, .grid_7, .grid_8, .grid_9, .grid_10, .grid_11, .grid_12 {
  float: left;
  margin: 0;
  display: block;
}

.container .grid_1 {
  width: 80px;
}

.container .grid_2 {
  width: 160px;
}

.container .grid_3 {
  width: 240px;
}

.container .grid_4 {
  width: 320px;
}

.container .grid_5 {
  width: 400px;
}

.container .grid_6 {
  width: 480px;
}

.container .grid_7 {
  width: 560px;
}

.container .grid_8 {
  width: 640px;
}

.container .grid_9 {
  width: 720px;
}

.container .grid_10 {
  width: 800px;
}

.container .grid_11 {
  width: 880px;
}

.container .grid_12 {
  width: 960px;
}

.purple-line {
  clear: both;
  border-top: 4px solid #7b2cea;
}

.chatArticle {
  width: 620px;
  margin: 0 0 0 -80px;
}

.chatArticle .header-comments, .chatArticle .thx-for-comment {
  color: #7b2cea;
  margin-left: 10px;
  font-size: 30px;
  line-height: 60px;
}

.chatArticle .thx-for-comment {
  margin-left: 0;
  font-size: 23px;
  line-height: 25px;
  display: block;
}

.chatArticle .infotext {
  padding-top: 20px;
  display: block;
}

.chatArticle .infotext.gray {
  color: #827d78;
  font-size: 16px;
  line-height: 17px;
}

.chatArticle .infotext.gray.small {
  float: left;
  max-width: 260px;
  padding-right: 130px;
  font-size: 12px;
  display: block;
}

.chatArticle .infotext.pink {
  color: #fa5ab9;
}

.chatArticle .infotext.pink .big {
  font-size: 25px;
}

.chatArticle .infotext.pink .strong {
  font-weight: bold;
}

#ot-msgs {
  color: red;
  margin: 0 10px 10px;
}

#frm-ot.frm-disable, #ot-msgs-sms.disable-msgs-sms, #text-sms-limit-full.disable-msgs-sms {
  display: none;
}

.padding-horizontal-10 {
  padding: 0 10px;
}

.infotext-bottom-wrapper {
  clear: both;
  height: 90px;
  margin: 0 20px;
  display: block;
  position: relative;
}

.infotext-bottom-wrapper.nomargin {
  height: 110px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.infotext-bottom-wrapper.nomargin a {
  display: block;
  right: 0;
  margin: 0 !important;
  position: relative !important;
  top: 70px !important;
}

.infotext-bottom-wrapper a.btn--purple, .infotext-bottom-wrapper .infotext {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
}

.infotext-bottom-wrapper a.btn--purple {
  width: 50%;
  right: 0;
}

.infotext-bottom-wrapper .infotext {
  left: 0;
}

#ot-msgs .dalsiDotaz {
  text-align: center;
  background: #fff;
  margin: 0 0 10px;
  padding: 10px 0;
  position: relative;
}

#frm-ot {
  color: #827d7a;
  background: #fff;
  padding: 0 10px;
  position: relative;
}

#frm-ot .zbyva {
  float: left;
  margin: 10px 0;
  display: block;
}

#frm-ot textarea {
  resize: none;
  color: #98a3af;
  background: #f5f3f1;
  border: 1px solid #cad5df;
  width: 578px;
  min-width: 578px;
  max-width: 578px;
  height: 88px;
  min-height: 88px;
  max-height: 88px;
  margin-bottom: 14px;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
}

#frm-ot .normalInp {
  margin: 10px 0 0;
  padding: 0 10px;
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
}

#frm-ot #ot-btn-submit {
  float: right;
  width: 259px;
  display: block;
  position: relative;
}

#frm-ot #ot-btn-submit:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  cursor: default;
  background: url("/images/blesk-horoskopy/btn-shadow.png") no-repeat;
  width: 260px;
  height: 40px;
  position: absolute;
  bottom: -40px;
  right: 0;
}

#ot-msgs-sms-limit-full {
  padding-bottom: 20px;
}

#ot-msgs-sms-limit-full a {
  float: right;
  position: relative;
}

.chatRecords {
  position: relative;
}

.chatRecords .record {
  background: #fff;
  margin: 10px 0 0;
  padding: 15px 20px;
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
}

.chatRecords .recordTop {
  color: #fa5ab9;
  margin-bottom: 5px;
}

.chatRecords .recordTop:before {
  content: "";
  background: url("/images/horoskopy/chat-sms-top-star.png?v=1") no-repeat;
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
}

.chatRecords:first-of-type {
  padding: 20px 0 0;
}

.chatRecords .shadowUp {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") 0 -1250px no-repeat;
}

.chatRecords .record.w540 {
  max-width: 540px;
}

.chatRecords .record .question {
  font-weight: bold;
}

.chatRecords .record .answer {
  min-height: 50px;
  margin: 10px 0 0;
  display: block;
  position: relative;
}

.chatRecords .record .answer .obrazek, .chatRecords .record .answer .obrazek img {
  float: left;
  width: 50px;
  height: 50px;
  display: block;
}

.chatRecords .record .answer .text {
  padding: 0 0 0 60px;
  display: block;
  position: relative;
}

.onlineTalkDiv .paging {
  text-align: center;
  color: #707b87;
  margin: 20px 0;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 16px;
  line-height: 16px;
}

.refresh-wrapper {
  padding: 10px;
  display: block;
}

.refresh-button, .refresh-updating, .refresh-updated {
  float: left;
  font-family: Arial, sans-serif;
  display: block;
  color: #827d78 !important;
}

.refresh-button {
  width: 110px;
  padding-right: 10px;
  text-decoration: none;
  color: #827d78 !important;
}

.refresh-button:hover {
  text-decoration: none;
  color: #000 !important;
}

.refresh-button:before {
  content: "";
  float: left;
  background: url("/images/blesk-horoskopy/horoskopy/reload_ico.png") no-repeat;
  width: 17px;
  height: 17px;
  margin-top: 3px;
  margin-right: 6px;
  display: block;
}

.refresh-updating, .refresh-updated {
  width: 200px;
}

.onlineTalkDiv .paging a:hover {
  color: red;
}

.onlineTalkDiv .paging a {
  color: #707b87;
  margin-top: 0;
  padding: 0 5px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 16px;
  text-decoration: none;
}

.onlineTalkDiv .paging a.aktpage {
  color: #16212d;
}

.onlineTalkDiv .paging .previousPage {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -850px -400px no-repeat;
  width: 10px;
  height: 20px;
  margin-top: 0;
  margin-right: 10px;
  padding: 0;
  display: inline-block;
  position: relative;
  top: 3px;
}

.onlineTalkDiv .paging .previousPage:hover {
  background-position: -850px -450px;
}

.onlineTalkDiv .paging .nextPage {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -900px -400px no-repeat;
  width: 10px;
  height: 20px;
  margin-left: 10px;
  padding: 0;
  display: inline-block;
  position: relative;
  top: 3px;
}

.onlineTalkDiv .paging .nextPage:hover {
  background-position: -900px -450px;
}

.online_report .date {
  float: left;
  text-align: center;
  color: #99a3af;
  background: none;
  width: 80px;
  margin: 0 0 2px;
  font-family: Arial, sans-serif;
  font-size: 11px;
  font-weight: bold;
  line-height: 15px;
  display: block;
}

.chatArticle .filters {
  border-top: 1px solid #d7e0e8;
  border-bottom: 1px solid #d7e0e8;
  margin-bottom: 20px;
  line-height: 34px;
}

.chatArticle .filters a {
  color: #98a3af;
  text-transform: uppercase;
  margin: 0 30px -10px;
  padding-bottom: 10px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 16px;
  display: inline-block;
}

.chatArticle .filters a.active {
  color: #16212d;
  background: url("https://img2.cncenter.cz/images/blesk-2013/selected-tab.png") bottom no-repeat;
}

.chatArticle .filters .groups {
  float: right;
}

.chatArticle .calendar {
  color: #98a3af;
  font-size: 15px;
}

.chatArticle .calendar a {
  color: #98a3af;
  font-size: 15px;
  text-decoration: none;
}

.chatArticle .calendar a:hover {
  color: #16212d;
}

.chatArticle .filters .groups select {
  color: #acb6c0;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  text-transform: uppercase;
  background: #fff;
  border: none;
  border-right: 0 solid #d7e0e8;
  outline: none;
  margin: 0;
  padding: 4px 18px 5px 4px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 16px;
  display: inline-block;
}

.chatArticle .filters .groups label {
  position: relative;
}

.chatArticle .filters .groups label:after {
  content: "►";
  color: #aaa;
  pointer-events: none;
  padding: 0 0 2px;
  font: 30px Consolas, monospace;
  position: absolute;
  top: -10px;
  right: 8px;
  transform: rotate(90deg);
}

.chatArticle .filters .groups label:before {
  content: "";
  pointer-events: none;
  background: #fff;
  border-left: 1px solid #eee;
  width: 30px;
  height: 32px;
  display: block;
  position: absolute;
  top: -6px;
  right: 1px;
}

.chatArticle .online_report .important {
  float: left;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -1150px 0 no-repeat;
  width: 23px;
  height: 23px;
  margin-top: 0;
  margin-left: 28px;
}

.chatArticle .online_report .details {
  float: left;
  width: 80px;
}

.chatArticle .online_report .fb_iframe_widget {
  margin: 30px 0 0 -70px;
}

.h-daily {
  box-shadow: -10px 20px 20px -20px #6e6964;
}

.h-daily__content, .h-daily__content--ah {
  background: #fff;
  height: 409px;
}

.h-daily__content .t-title, .h-daily__content--ah .t-title {
  margin: 24px 0;
}

.h-daily__content .sign-info, .h-daily__content--ah .sign-info {
  height: 305px;
}

.h-daily__content .img-wrapp, .h-daily__content--ah .img-wrapp {
  width: 300px;
  height: 220px;
  padding: 40px 0;
}

.h-daily__content .img-wrapp img, .h-daily__content--ah .img-wrapp img {
  margin: auto;
  display: block;
}

.h-chinese .h-daily__content .t-title--gray, .h-chinese .h-daily__content--ah .t-title--gray {
  color: #beb9b4;
}

.h-chinese .h-daily__content .sign-info .t-title, .h-chinese .h-daily__content--ah .sign-info .t-title {
  margin-top: 0;
}

.h-num .h-daily__content .img-wrapp, .h-num .h-daily__content--ah .img-wrapp {
  height: 300px;
  padding: 10px 0;
}

.h-char .h-daily__content .t-par, .h-char .h-daily__content--ah .t-par {
  min-height: 281px;
}

.h-char .h-daily__content .img-wrapp, .h-char .h-daily__content--ah .img-wrapp {
  height: auto;
  padding: 10px 0;
}

.h-char .h-daily__content .sign-info, .h-char .h-daily__content--ah .sign-info {
  height: 396px;
}

.ascendent .h-daily__content .img-wrapp, .ascendent .h-daily__content--ah .img-wrapp {
  height: 380px;
}

.ascendent .h-daily__content .img-wrapp--ah, .ascendent .h-daily__content--ah .img-wrapp--ah {
  height: auto;
}

.h-daily__content--ah {
  height: auto;
  padding-bottom: 50px;
}

.h-daily__footer {
  background: #f2f0ed;
  height: 38px;
}

.h-daily__links .link, .h-daily__links .link--last {
  float: left;
  color: #7b2cea;
  text-align: center;
  border-right: 1px solid #fff;
  width: 89px;
  height: 40px;
  font: 15px / 20px Roboto Regular, sans-serif;
  display: block;
}

.h-daily__links .link--last {
  border-right: none;
  width: 90px;
}

.h-daily__links .ico {
  margin: auto;
}

.h-types__item {
  float: left;
  text-align: center;
  width: 33.3333%;
}

.h-types__link {
  font: 15px / 38px Roboto Regular, sans-serif;
  text-decoration: none;
}

.h-types__link:hover, .h-types__link:focus {
  text-decoration: underline;
}

.box--pink .h-daily .is-active .tabs__link, .box--pink .h-daily .tabs__link:hover, .box--pink .h-daily .tabs__link:focus, .box--pink .h-types__link, .box--pink .link-small {
  color: #fa5ab9;
}

.box--purple .h-daily .is-active .tabs__link, .box--purple .h-daily .tabs__link:hover, .box--purple .h-daily .tabs__link:focus, .box--purple .h-types__link, .box--purple .link-small {
  color: #7b2cea;
}

.box--gray .h-daily .is-active .tabs__link, .box--gray .h-daily .tabs__link:hover, .box--gray .h-daily .tabs__link:focus, .box--gray .h-types__link, .box--gray .link-small {
  color: #827d78;
}

.box--cerise .h-daily .is-active .tabs__link, .box--cerise .h-daily .tabs__link:hover, .box--cerise .h-daily .tabs__link:focus, .box--cerise .h-types__link, .box--cerise .link-small {
  color: #dc3296;
}

.sms-partner {
  background: #fff;
}

.sms-partner .sms-banner {
  border-top: 2px solid #e6e1dc;
  border-bottom: 2px solid #e6e1dc;
  margin: 0 5px;
}

.sms-partner .sms-text {
  float: left;
  width: 340px;
  margin-left: 75px;
}

.sms-partner .sms-text p {
  line-height: 18px;
}

.sms-partner .sms-image {
  float: left;
  background: url("/images/blesk-horoskopy/sprites/sprites.png?v=4") -248px -67px no-repeat;
  width: 49px;
  height: 41px;
  margin: 20px 20px 0 0;
}

.sms-partner .sms-claim {
  float: left;
  width: 115px;
  margin-top: 4px;
  font-size: 12px;
}

.sms-partner .arrow {
  float: left;
  background: url("/images/blesk-horoskopy/sprites/sprites.png?v=4") -320px -50px no-repeat;
  width: 10px;
  height: 16px;
  margin-top: 28px;
  display: block;
}

.sms-partner .t-title {
  color: #fa5ab9;
  margin: 5px 0;
  font-size: 22px;
  line-height: 1;
}

.sms-partner strong {
  color: #fa5ab9;
}

.h-daily__wrapp {
  background: #fff;
}

.h-daily__wrapp hr {
  background-color: #dcd7d2;
  height: 1px;
  margin: 10px;
  display: block;
}

.h-daily__addons {
  padding: 50px 30px;
}

.h-daily__family {
  padding: 20px 30px;
}

.addon, .addon--vasi-blizci, .addon--zdravi, .addon--kariera, .addon--laska {
  padding: 0 0 10px 50px;
  position: relative;
}

.addon .ico, .addon--vasi-blizci .ico, .addon--zdravi .ico, .addon--kariera .ico, .addon--laska .ico {
  position: absolute;
  top: 12px;
  left: 0;
}

.h-daily--full .sign-info {
  height: auto;
  min-height: 257px;
}

.symbol-daily, .symbol-indian, .symbol-chinese {
  width: 67px;
  height: 67px;
  display: block;
}

.symbol-chinese {
  background: url("/images/blesk-horoskopy/sprites/sprites.png?v=4") -248px 0 no-repeat;
}

.symbol-indian {
  background: url("/images/blesk-horoskopy/sprites/sprites.png?v=4") -181px 0 no-repeat;
}

.symbol-daily {
  background: url("/images/blesk-horoskopy/sprites/sprites.png?v=4") -181px -67px no-repeat;
}

.stars-says, .another-sign {
  background: url("/images/blesk-horoskopy/sprites/sprites.png?v=4") no-repeat;
  width: 181px;
  height: 52px;
  display: block;
  position: absolute;
  top: 25px;
  left: 60px;
}

.stars-says i, .another-sign i {
  background: url("/images/blesk-horoskopy/sprites/arrows.png") -148px 0 no-repeat;
  width: 33px;
  height: 27px;
  display: block;
  position: absolute;
  top: -15px;
  left: 5px;
}

.another-sign {
  background-position: 0 -52px;
  height: 56px;
}

.choose-signs {
  padding-bottom: 20px;
}

.choose-signs ul {
  margin: 30px 0;
  padding: 0;
}

.choose-signs .choose__item img {
  width: 100%;
  min-height: 120px;
  display: block;
}

.choose-signs .choose__item strong {
  font-size: 19px;
}

.choose-signs .choose__sign-link {
  color: #827d78;
  padding: 20px;
  display: block;
  position: relative;
}

.choose-signs .choose__sign-link:hover, .choose-signs .choose__sign-link:focus {
  text-decoration: none;
  top: -2px;
  box-shadow: 0 2px 10px #beb9b4;
}

.img-tarots .img-wrapp {
  bottom: 0;
  left: 195px;
}

.img-tarots .shadow--half:after {
  background-position: 100% -4px;
}

.img-chinese .img-wrapp img {
  width: 120px;
}

.img-chinese .text-wrapp {
  z-index: 1;
  width: 150px;
  margin: 15px 0 0;
}

.img-chinese .title-wrapp {
  z-index: 1;
}

.img-chinese .t-title {
  margin-bottom: 0;
}

.img-chinese p {
  line-height: 18px;
}

.img-indian .img-wrapp {
  bottom: -25px;
  left: 52%;
}

.img-indian .img-wrapp img {
  width: 150px;
}

.img-indian .text-wrapp {
  margin: 5px 10px 0 0;
}

.img-indian .t-title {
  margin-bottom: 0;
}

.img-indian p {
  line-height: 18px;
}

.happiness .front, .happiness .back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.happiness.is-animated .front, .happiness.is-animated .back {
  transition: all 1s ease-in;
}

.happiness .back {
  display: block;
}

.happiness .back .t-title {
  font-size: 30px;
  line-height: 32px;
}

.happiness .is-reverted {
  transform-origin: 50%;
  perspective: 150px;
  display: block;
  transform: rotateY(180deg);
}

.happiness .link-small {
  color: #fff;
}

.happiness .loader {
  z-index: 5;
  background: rgba(250, 90, 185, .8) url("/images/blesk-horoskopy/loader-white.gif") center no-repeat;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.happiness__wrapp {
  height: 260px;
  position: relative;
}

.happiness--full .loader {
  z-index: 5;
  background: url("/images/blesk-horoskopy/loader-pink.gif") no-repeat;
  width: 42px;
  height: 42px;
  margin: auto;
  display: none;
  position: static;
}

.love {
  background: url("/images/blesk-horoskopy/birds.jpg") 10px 240px no-repeat;
  height: 452px;
  position: relative;
}

.love .sign-up__wrapp {
  padding: 20px 0;
}

.love .input-select {
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}

.love .input-select.love__by-date__day {
  width: 69px;
}

.love .input-select.love__by-date__month {
  width: 99px;
}

.love .input-select.love__by-date__year {
  width: 69px;
}

.love .input-text {
  width: 236px;
}

.love .link-small {
  color: #827d78;
}

.love .link-small.is-active {
  color: #fa5ab9;
}

.love .vs {
  text-align: center;
  color: #beb9b4;
  font: 40px / 1 Roboto, sans-serif;
}

.love .love__btn-wrapp {
  margin-top: 170px;
}

.love .love__submit {
  width: 259px;
  padding: 0;
}

.love .is-hidden {
  display: none;
}

.love__content {
  background: url("/images/blesk-horoskopy/birds.jpg") 20px 140px no-repeat;
  height: 447px;
  padding-left: 319px;
}

.pendulum .image__wrapp {
  height: 245px;
  position: relative;
}

.pendulum .pendulum__anim {
  width: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.pendulum .pendulum__anim img {
  margin: auto;
  display: none;
}

.pendulum .loader {
  background: url("/images/blesk-horoskopy/loader-purple.gif") no-repeat;
  width: 42px;
  height: 42px;
  position: absolute;
  top: 420px;
  left: 130px;
}

.pendulum--full .loader {
  top: 300px;
  left: 140px;
}

.pendulum--full .fb-wrapp {
  position: absolute;
  bottom: 0;
  left: 20px;
}

.lunar .lunar__values {
  float: right;
  width: 180px;
}

.lunar .lunar__values .item {
  float: left;
  text-align: center;
  width: 50px;
}

.lunar .lunar__values .item span {
  padding-top: 5px;
  display: inline-block;
}

.lunar .lunar__values .item.item-1 {
  width: 80px;
}

.lunar .link-small {
  color: #827d78;
}

.lunar .link-small.is-active {
  color: #7323eb;
}

.lunar .t-title {
  color: #7323eb;
  margin-bottom: 0;
}

.lunar .img-wrapp {
  position: absolute;
  top: -35px;
  left: 45%;
}

.lunar .img-wrapp img {
  width: 140px;
}

.card-of-day .img-wrapp {
  position: absolute;
  bottom: 0;
  left: 177px;
}

.card-of-day .btn--pink {
  width: 90px;
}

.card-of-day--full .btn-wrapp {
  width: 100%;
  bottom: 85px;
  left: 0;
}

.card-of-day--full .card-magic {
  height: 600px;
}

.card-of-day--full .loader-wrapp, .tarots--full .loader-wrapp {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
}

.card-of-day--full .loader, .tarots--full .loader {
  display: inline-block;
}

.tarots--full {
  padding-bottom: 30px;
}

.tarots--full .card-magic {
  height: 520px;
}

.tarots--full .btn-wrapp {
  height: 40px;
}

.card-results {
  padding-bottom: 30px;
  display: none;
}

.card-results__item {
  width: 66.6666%;
  min-height: 440px;
}

.card-results__wrapp {
  float: left;
  border: 1px solid #beb9b4;
  border-radius: 18px;
  width: 232px;
  height: 395px;
  margin-right: 50px;
  list-style-type: none;
  display: none;
}

.card-results__item--monthly .card-results__wrapp {
  display: block;
}

.card-canvas {
  perspective-origin: bottom;
  perspective: 600px;
  display: none;
  position: relative;
  top: 60px;
}

.cards-container {
  width: 122px;
  height: 201px;
  margin: 0 auto 30px;
  padding: 0;
  list-style-type: none;
  position: relative;
}

.cards-container li {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  cursor: pointer;
  pointer-events: auto;
  -webkit-user-select: none;
  user-select: none;
  background: #fff url("/images/blesk-horoskopy/card-back.png") no-repeat;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .08);
}

.cards-container li img {
  border-radius: 10px 10px 0 0;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: block;
}

.cards-container li .front, .cards-container li .back {
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
}

.cards-container li .back {
  transform-origin: 50%;
  perspective: 1000px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.cards-container li:hover {
  background: #7b2cea url("/images/blesk-horoskopy/card-back.png") no-repeat;
}

.cards-container.is-inactive li {
  cursor: default;
  background-color: #fff;
}

.no-js .cards-container {
  text-align: center;
  width: auto;
  height: auto;
}

.no-js .cards-container li {
  width: 112px;
  height: 191px;
  margin: 10px;
  display: inline-block;
  position: relative;
}

.tarots--love .cards-container li {
  background: #fff url("/images/blesk-horoskopy/card-love-back.png") no-repeat;
}

.tarots--love .cards-container li:hover {
  background-color: #fa5ab9;
}

.card-spots {
  padding-bottom: 20px;
}

.card-spots .card-spot {
  text-align: center;
  border: 1px solid #beb9b4;
  border-radius: 10px;
  width: 137px;
  height: 231px;
  margin: 0 10px;
  line-height: 203px;
  list-style-type: none;
}

.rune-love .t-title {
  letter-spacing: -.05ex;
}

.seers-right .article {
  margin-bottom: 10px;
}

.seers-right .t-title {
  letter-spacing: -.05ex;
}

.seers-right .thumb {
  width: 100px;
  height: 130px;
  position: absolute;
  top: 190px;
  right: 5px;
  transform: rotate(7deg);
  box-shadow: 0 8px 20px rgba(0, 0, 0, .5);
}

.seers-right .article .t-title, .seers-right .article .t-subtitle {
  width: 180px;
  margin: 0;
}

.seers-right .article .t-subtitle {
  color: #9b81de;
  min-height: 35px;
  margin-bottom: 30px;
}

.seers-right__claim {
  height: 180px;
}

.seers {
  margin-top: 30px;
}

.seers .article {
  border-top: 1px dashed #e6e1dc;
  padding: 29px 0 30px;
}

.seers .thumb {
  float: left;
  width: 100px;
  height: 129px;
  margin-right: 20px;
}

.seers .t-title {
  margin: 0;
}

.seers .t-subtitle {
  color: #9b81de;
  margin-bottom: 10px;
}

.seers .seersItem {
  margin-top: 0;
  line-height: 1;
}

.seers .seersItem .link {
  color: #7b2cea;
}

.seers .rating__results {
  position: absolute;
  top: 35px;
  right: 0;
}

.seer-detail .right {
  float: left;
  width: 350px;
}

.seer-detail .thumb {
  float: left;
  width: 200px;
  height: 260px;
  margin: 20px 20px 20px 0;
}

.seer-detail .thumb .image {
  width: 100%;
  height: 100%;
}

.seer-detail .t-subtitle {
  color: #9b81de;
}

.seer-detail .body {
  border-bottom: 1px dashed #e6e1dc;
  padding-bottom: 20px;
}

.seer-detail .seerRating {
  padding-bottom: 1px;
}

.seer-detail .ratingSmall {
  position: absolute;
  top: 25px;
  right: 20px;
}

.seer-detail .ratingSmall .voteStars .star {
  text-indent: -9999px;
  float: left;
  background: url("/images/blesk-horoskopy/rating-small.png") 0 -24px no-repeat;
  width: 20px;
  height: 20px;
  padding-right: 5px;
  display: block;
}

.seer-detail .ratingSmall .voteStars .star:hover, .seer-detail .ratingSmall .voteStars .star.hover {
  background-position: 0 0;
}

.seer-detail .ratingBig {
  width: 325px;
  margin: 10px auto 30px;
}

.seer-detail .ratingBig .voteStars .star {
  text-indent: -9999px;
  float: left;
  background: url("/images/blesk-horoskopy/rating-big.png") 0 -55px no-repeat;
  width: 50px;
  height: 50px;
  padding-right: 15px;
  display: block;
}

.seer-detail .ratingBig .voteStars .star.hover {
  background-position: 0 0;
}

.seer-detail .cats {
  height: 80px;
}

.seer-detail .cats a {
  color: #9b81de;
}

.seer-detail .rating__results {
  position: absolute;
  top: 25px;
  right: 20px;
}

.rating__wrapper {
  width: 100px;
  height: 20px;
  position: relative;
  overflow: hidden;
}

.rating__wrapper .rating__blank {
  background: url("/images/blesk-horoskopy/rating-small.png") 0 -24px repeat-x;
  width: 100%;
  height: 20px;
  position: absolute;
  top: 0;
}

.rating__wrapper .rating__value {
  background: url("/images/blesk-horoskopy/rating-small.png") repeat-x;
  width: 0;
  height: 20px;
  position: absolute;
  top: 0;
}

.rating__notice {
  display: none;
}

.voted .rating__notice {
  width: 200px;
  display: block;
  position: absolute;
  top: 27px;
  right: 0;
}

.tailored .t-subtitle.info {
  float: left;
  width: 400px;
}

.tailored .infoImg {
  float: left;
  background: url("/images/blesk-horoskopy/namiru-head.png") no-repeat;
  width: 416px;
  height: 137px;
  position: relative;
  top: -55px;
  left: 15px;
}

.tailored .previews .preview {
  float: left;
  width: 265px;
  margin: 10px 15px 20px;
  position: relative;
}

.tailored .previews .preview .icoPreview {
  background: url("/images/blesk-horoskopy/namiru-sprites.png") no-repeat;
  width: 95px;
  height: 95px;
  display: block;
  position: absolute;
  top: 80px;
  right: 0;
}

.tailored .previews .preview .icoPreviewSample {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  background: #fa5ab9;
  border-radius: 50px;
  width: 95px;
  height: 60px;
  padding-top: 35px;
  font-size: 9px;
  font-weight: 400;
  line-height: 13px;
  display: block;
  position: absolute;
  top: 80px;
  right: 0;
}

.tailored .previews .preview .icoPreviewSample span {
  letter-spacing: 1px;
  font-size: 13px;
}

.tailored .previews .preview .icoPreviewSample span:after {
  content: "";
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-bottom: 5px solid rgba(0, 0, 0, 0);
  border-left: 6px solid #fff;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: 70px;
  left: 45px;
}

.tailored .previews .preview .details .name {
  color: #fa5ab9;
  margin: 20px 0 10px;
}

.tailored .previews .preview .details .desc {
  color: #989898;
  border-bottom: 1px solid #989898;
  height: 100px;
  margin: 10px 0 15px;
  font-size: 14px;
  line-height: 22px;
}

.tailored .previews .preview .details .price {
  margin: 10px 0 40px;
  font-size: 10px;
  display: inline-block;
}

.tailored .previews .preview .img-wrapp {
  height: 320px;
  display: block;
}

.asc .asc__selects {
  background: url("/images/blesk-horoskopy/ascendent.png") 50% 100% no-repeat;
  height: 330px;
}

.asc .asc__selects .input-select {
  padding-left: 5px;
  padding-right: 5px;
}

.asc .asc__selects .input-select[name="day"] {
  width: 70px;
}

.asc .asc__selects .input-select[name="month"] {
  width: 97px;
}

.asc .asc__selects .input-select[name="year"] {
  width: 70px;
}

.asc .asc__selects .select-time .input-select {
  width: 80px;
}

.asc .asc__submit {
  width: 258px;
  padding: 0;
  display: block;
}

.bio .t-title, .bio .bio__date-select {
  margin-bottom: 0;
}

.bio .bio__canvas-wrapp {
  height: 150px;
}

.bio .bio__content {
  height: auto;
  margin-top: 65px;
}

.bio .bio__info {
  height: 60px;
  padding: 0 0 5px 5px;
}

.bio .bio__info .bio__value {
  font: 30px / 40px Roboto, sans-serif;
}

.bio .bio__info .bio__name {
  margin: 4px 0;
  font: 12px / 1 Roboto Regular, sans-serif;
}

.bio .bio__info .bio__text {
  padding: 0 10px;
}

.bio--full .bio__social .fb-like__wrapp {
  text-align: center;
}

.bio__date-select .input-select {
  padding-right: 12px;
}

.bio__date-select .input-select.day {
  width: 80px;
}

.bio__date-select .input-select.month {
  width: 120px;
}

.bio__date-select .input-select.year {
  width: 80px;
}

.bio__canvas-wrapp {
  clear: both;
  height: 170px;
}

.bio__canvas-wrapp.is-blank {
  background: url("/images/blesk-horoskopy/biorythms-blank.png") center no-repeat;
}

.bio__content {
  height: 130px;
}

.bio__content .l-1of3 {
  float: left;
  width: 33.3333%;
}

.bio__info {
  text-align: center;
}

.bio__info .bio__value {
  margin: 0;
  font: 60px / 1 Roboto, sans-serif;
}

.bio__info .bio__name {
  margin: 0;
}

.bio__info .bio__text {
  padding: 0 10px;
  font-size: 12px;
  line-height: 15px;
}

.bio__info--int .bio__value, .bio__info--int .bio__name {
  color: #00cdc3;
}

.bio__info--emo .bio__value, .bio__info--emo .bio__name {
  color: #fa5ab9;
}

.bio__info--phy .bio__value, .bio__info--phy .bio__name {
  color: #9b81de;
}

.runes .loader-wrapp {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
}

.runes .loader {
  display: inline-block;
}

.rune {
  float: left;
  opacity: 0;
  cursor: pointer;
  width: 60px;
  height: 90px;
}

.is-inactive .rune {
  cursor: default;
}

.runes--love .rune .bg-23, .runes--love .rune .bg-22, .runes--love .rune .bg-21, .runes--love .rune .bg-20, .runes--love .rune .bg-19, .runes--love .rune .bg-18, .runes--love .rune .bg-17, .runes--love .rune .bg-16, .runes--love .rune .bg-15, .runes--love .rune .bg-14, .runes--love .rune .bg-13, .runes--love .rune .bg-12, .runes--love .rune .bg-11, .runes--love .rune .bg-10, .runes--love .rune .bg-9, .runes--love .rune .bg-8, .runes--love .rune .bg-7, .runes--love .rune .bg-6, .runes--love .rune .bg-5, .runes--love .rune .bg-4, .runes--love .rune .bg-3, .runes--love .rune .bg-2, .runes--love .rune .bg-1, .runes--love .rune .bg-0, .rune .bg-23, .rune .bg-22, .rune .bg-21, .rune .bg-20, .rune .bg-19, .rune .bg-18, .rune .bg-17, .rune .bg-16, .rune .bg-15, .rune .bg-14, .rune .bg-13, .rune .bg-12, .rune .bg-11, .rune .bg-10, .rune .bg-9, .rune .bg-8, .rune .bg-7, .rune .bg-6, .rune .bg-5, .rune .bg-4, .rune .bg-3, .rune .bg-2, .rune .bg-1, .rune .bg-0 {
  background: url("/images/blesk-horoskopy/runes/reverse-side.png") no-repeat;
  width: 60px;
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
}

.rune .bg-23:hover, .rune .bg-22:hover, .rune .bg-21:hover, .rune .bg-20:hover, .rune .bg-19:hover, .rune .bg-18:hover, .rune .bg-17:hover, .rune .bg-16:hover, .rune .bg-15:hover, .rune .bg-14:hover, .rune .bg-13:hover, .rune .bg-12:hover, .rune .bg-11:hover, .rune .bg-10:hover, .rune .bg-9:hover, .rune .bg-8:hover, .rune .bg-7:hover, .rune .bg-6:hover, .rune .bg-5:hover, .rune .bg-4:hover, .rune .bg-3:hover, .rune .bg-2:hover, .rune .bg-1:hover, .rune .bg-0:hover {
  animation: .5s linear tada;
  animation-delay: 0;
  animation-iteration-count: 1;
}

.is-inactive .rune .bg-23:hover, .is-inactive .rune .bg-22:hover, .is-inactive .rune .bg-21:hover, .is-inactive .rune .bg-20:hover, .is-inactive .rune .bg-19:hover, .is-inactive .rune .bg-18:hover, .is-inactive .rune .bg-17:hover, .is-inactive .rune .bg-16:hover, .is-inactive .rune .bg-15:hover, .is-inactive .rune .bg-14:hover, .is-inactive .rune .bg-13:hover, .is-inactive .rune .bg-12:hover, .is-inactive .rune .bg-11:hover, .is-inactive .rune .bg-10:hover, .is-inactive .rune .bg-9:hover, .is-inactive .rune .bg-8:hover, .is-inactive .rune .bg-7:hover, .is-inactive .rune .bg-6:hover, .is-inactive .rune .bg-5:hover, .is-inactive .rune .bg-4:hover, .is-inactive .rune .bg-3:hover, .is-inactive .rune .bg-2:hover, .is-inactive .rune .bg-1:hover, .is-inactive .rune .bg-0:hover {
  animation: none;
}

.rune .bg-0 {
  background-position: 0 0;
}

.rune .bg-1 {
  background-position: -60px 0;
}

.rune .bg-2 {
  background-position: -120px 0;
}

.rune .bg-3 {
  background-position: -180px 0;
}

.rune .bg-4 {
  background-position: -240px 0;
}

.rune .bg-5 {
  background-position: -300px 0;
}

.rune .bg-6 {
  background-position: -360px 0;
}

.rune .bg-7 {
  background-position: -420px 0;
}

.rune .bg-8 {
  background-position: -480px 0;
}

.rune .bg-9 {
  background-position: -540px 0;
}

.rune .bg-10 {
  background-position: -600px 0;
}

.rune .bg-11 {
  background-position: -660px 0;
}

.rune .bg-12 {
  background-position: -720px 0;
}

.rune .bg-13 {
  background-position: -780px 0;
}

.rune .bg-14 {
  background-position: -840px 0;
}

.rune .bg-15 {
  background-position: -900px 0;
}

.rune .bg-16 {
  background-position: -960px 0;
}

.rune .bg-17 {
  background-position: -1020px 0;
}

.rune .bg-18 {
  background-position: -1080px 0;
}

.rune .bg-19 {
  background-position: -1140px 0;
}

.rune .bg-20 {
  background-position: -1200px 0;
}

.rune .bg-21 {
  background-position: -1260px 0;
}

.rune .bg-22 {
  background-position: -1320px 0;
}

.rune .bg-23 {
  background-position: -1380px 0;
}

.runes--love .rune {
  width: 90px;
  height: 70px;
}

.runes--love .rune .bg-23, .runes--love .rune .bg-22, .runes--love .rune .bg-21, .runes--love .rune .bg-20, .runes--love .rune .bg-19, .runes--love .rune .bg-18, .runes--love .rune .bg-17, .runes--love .rune .bg-16, .runes--love .rune .bg-15, .runes--love .rune .bg-14, .runes--love .rune .bg-13, .runes--love .rune .bg-12, .runes--love .rune .bg-11, .runes--love .rune .bg-10, .runes--love .rune .bg-9, .runes--love .rune .bg-8, .runes--love .rune .bg-7, .runes--love .rune .bg-6, .runes--love .rune .bg-5, .runes--love .rune .bg-4, .runes--love .rune .bg-3, .runes--love .rune .bg-2, .runes--love .rune .bg-1, .runes--love .rune .bg-0 {
  background: url("/images/blesk-horoskopy/runes/love-reverse-side.png") no-repeat;
  width: 90px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
}

.runes--love .rune .bg-0 {
  background-position: 0 0;
}

.runes--love .rune .bg-1 {
  background-position: -90px 0;
}

.runes--love .rune .bg-2 {
  background-position: -180px 0;
}

.runes--love .rune .bg-3 {
  background-position: -270px 0;
}

.runes--love .rune .bg-4 {
  background-position: -360px 0;
}

.runes--love .rune .bg-5 {
  background-position: -450px 0;
}

.runes--love .rune .bg-6 {
  background-position: -540px 0;
}

.runes--love .rune .bg-7 {
  background-position: -630px 0;
}

.runes--love .rune .bg-8 {
  background-position: -720px 0;
}

.runes--love .rune .bg-9 {
  background-position: -810px 0;
}

.runes--love .rune .bg-10 {
  background-position: -900px 0;
}

.runes--love .rune .bg-11 {
  background-position: -990px 0;
}

.runes--love .rune .bg-12 {
  background-position: -1080px 0;
}

.runes--love .rune .bg-13 {
  background-position: -1170px 0;
}

.runes--love .rune .bg-14 {
  background-position: -1260px 0;
}

.runes--love .rune .bg-15 {
  background-position: -1350px 0;
}

.runes--love .rune .bg-16 {
  background-position: -1440px 0;
}

.runes--love .rune .bg-17 {
  background-position: -1530px 0;
}

.runes--love .rune .bg-18 {
  background-position: -1620px 0;
}

.runes--love .rune .bg-19 {
  background-position: -1710px 0;
}

.runes--love .rune .bg-20 {
  background-position: -1800px 0;
}

.runes--love .rune .bg-21 {
  background-position: -1890px 0;
}

.runes--love .rune .bg-22 {
  background-position: -1980px 0;
}

.runes--love .rune .bg-23 {
  background-position: -2070px 0;
}

.runes--love .runes-results__wrapp img {
  margin-bottom: 20px;
}

.runes__canvas {
  background: #fff;
  height: 360px;
}

.runes__canvas ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.runes__items {
  height: 150px;
  margin-top: 10px;
}

.rune-item, .rune-item--3, .rune-item--2, .rune-item--1 {
  text-align: center;
  background: url("/images/blesk-horoskopy/sprites/rune-items.jpg") no-repeat;
  width: 122px;
  height: 71px;
  margin: 14px;
  padding-top: 51px;
}

.rune-item span, .rune-item--3 span, .rune-item--2 span, .rune-item--1 span {
  position: relative;
  top: -70px;
}

.rune-item--1 {
  background-position: 0 0;
}

.rune-item--2 {
  background-position: -122px 0;
}

.rune-item--3 {
  background-position: -244px 0;
}

.runes-results {
  padding-bottom: 30px;
  display: none;
}

.runes-results__item {
  width: 66.6666%;
  min-height: 160px;
}

.runes-results__wrapp {
  float: left;
  width: 160px;
  height: 160px;
}

.runes-results__button {
  padding-left: 160px;
}

@keyframes tada {
  0% {
    transform: scale(1);
  }

  10%, 20% {
    transform: scale(.9)rotate(-3deg);
  }

  30%, 60%, 90% {
    transform: scale(1.1)rotate(3deg);
  }

  45%, 75% {
    transform: scale(1.1)rotate(-3deg);
  }

  100% {
    transform: scale(1)rotate(0);
  }
}

.autoBookmark {
  background: red url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") 540px -1392px no-repeat;
  width: 620px;
  height: 30px;
  display: none;
  position: relative;
  top: 20px;
  left: -80px;
  border-radius: 3px 3px 0 0 !important;
}

.autoBookmarkLeft {
  background: red;
  width: 70px;
  padding: 3px;
  display: none;
  position: absolute;
  top: 3px;
  left: -80px;
  border-radius: 3px !important;
}

.socialShareArrow {
  z-index: 2;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -400px -1300px no-repeat;
  width: 80px;
  height: 61px;
  display: block;
  position: absolute;
  top: -11px;
  left: -80px;
}

.socialShares {
  float: left;
  width: 350px;
  max-height: 20px;
  margin: 30px 0;
  line-height: 15px;
  position: relative;
  top: 0;
  left: 0;
}

.socialMedia {
  float: left;
  width: 530px;
}

.socialMedia .title {
  color: #5b6770;
  text-transform: uppercase;
  text-align: left;
  background-color: #f5f5f5;
  border-bottom: 1px solid #d8e1e5;
  border-radius: 4px 4px 0 0;
  margin-top: 0;
  padding: 2px 10px 0;
  font-family: Arial, sans-serif;
  font-weight: 700;
  line-height: 27px;
  font-size: 11px !important;
}

.socialMedia .fb-like {
  font-size: 0;
}

.scoailMedia .fb-comments {
  min-height: 210px;
}

.socialMedia .others {
  width: 250px;
}

.socialMedia .others div {
  float: left !important;
  margin: 0 20px 0 0 !important;
}

.socialMedia .inner_5.fb {
  padding-right: 0;
}

.socialMedia .twitter {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -100px -1300px no-repeat;
  width: 58px;
  height: 61px;
  position: relative;
}

.socialMedia .twitter .twitterCount {
  text-align: center;
  cursor: default;
  color: #666;
  background-repeat: no-repeat;
  width: 58px;
  height: 52px;
  padding: 9px 0 0 1px;
  font: 700 14px / 14px Arial, Helvetica Neue, Helvetica, sans-serif;
  display: block;
}

.socialMedia .twitter iframe {
  opacity: 0;
  top: 39px;
  left: 0;
  position: absolute !important;
}

.socialMedia .facebook {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") 0 -1300px no-repeat;
  width: 54px;
  height: 61px;
  position: relative;
}

.socialMedia .facebook .facebookCount {
  text-align: center;
  cursor: default;
  color: #666;
  background-repeat: no-repeat;
  width: 54px;
  height: 52px;
  padding: 9px 0 0 1px;
  font: 700 14px / 14px Arial, sans-serif;
  display: block;
}

.socialMedia .facebook .sharer {
  opacity: 0;
  width: 56px;
  height: 29px;
  position: absolute;
  top: 39px;
  left: 0;
}

#article .socialTopShare {
  height: 22px;
  margin-top: 16px;
  margin-left: 426px;
  position: absolute;
  overflow: hidden;
}

#article .socialTopShare .fb-like {
  margin-right: 10px;
  font-size: 0;
}

#article {
  color: #16212d;
  text-align: left;
  width: 620px;
  padding-top: 0;
  font-size: 18px;
  line-height: 25px;
  overflow: hidden;
}

#article .relatedArticles .title, #article .middleArticle .title {
  margin-top: 20px;
}

#article .relatedArticles .wrapper, #article .middleArticle .wrapper {
  border: none;
}

#article .recommended .list-article--horizontal-small {
  margin: 0 0 10px;
}

#article .recommended .list-article--horizontal-small .list-article__category-name {
  text-transform: none;
}

#article .recommended .list-article .list-article__title:hover {
  color: #000;
}

#article .inner_7 {
  width: 540px;
  margin-left: 80px;
  overflow: visible;
}

#article h1 {
  font-size: 40px;
  line-height: 1.2;
}

#article .topGallery {
  background: #16212d;
  width: 620px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  left: -80px;
}

#article .topGallery img.topImg {
  width: 620px;
  height: 328px;
}

#article .topGallery .jcarousel {
  width: 540px;
  margin: 0;
  padding-bottom: 10px;
  left: 40px;
}

#article .topGallery .photoDesc {
  color: #fff;
  padding: 20px;
  font-size: 18px;
}

#article .topGallery .photoSource {
  color: #98a3af;
  margin-left: 5px;
}

#article .topGallery .photoCarousel {
  width: 100%;
  margin: 20px 0 10px;
  display: inline-block;
  position: relative;
}

#article .topGallery .photoCarousel .jcarousel {
  padding: 0;
}

#article .topGallery .photoCarousel .jcarousel ul.jcarouselList {
  list-style-type: none !important;
}

#article .topGallery .photoCarousel.noControls {
  margin: 10px 0;
}

#article .topGallery .photoCarousel .jcarousel-next, #article .topGallery .photoCarousel .jcarousel-prev {
  background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -50px -216px no-repeat content-box content-box;
  border-left: 1px solid #98a3af;
  top: 0;
}

#article .topGallery .photoCarousel .jcarousel-next:hover, #article .topGallery .photoCarousel .jcarousel-prev:hover {
  background-position: -50px -232px;
  border-left: 1px solid #3d4952;
}

#breadcrumbs {
  color: #5c6773;
  max-width: 940px;
  height: 29px;
  margin: 0 10px;
  font-size: 12px;
  line-height: 29px;
}

#breadcrumbs a {
  color: #98a3af;
  font-size: 12px;
  line-height: 29px;
}

#breadcrumbs span {
  color: #98a3af;
  text-indent: -99px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -975px -405px no-repeat;
  width: 11px;
  height: 11px;
  margin: 0 5px;
  display: inline-block;
  position: relative;
  top: 1px;
  overflow: hidden;
}

.centerLine p {
  margin: 5px 0 25px;
}

.centerLine h1 {
  margin: 20px 0;
  font-size: 65px;
  line-height: 65px;
}

.centerLine h2 {
  margin: 20px 0 5px;
  font-size: 40px;
  line-height: 45px;
}

.centerLine h3 {
  margin: 10px 0;
  font-size: 30px;
  line-height: 35px;
}

.centerLine h4 {
  margin: 10px 0 5px;
  font-size: 20px;
  line-height: 25px;
}

#article #ads-widesquare {
  margin: 25px 0 30px;
}

#article .metaArticle {
  width: 540px;
  min-height: 68px;
  margin: 22px 0 0;
  position: relative;
}

#article .leadsection p {
  margin: 25px 0 30px;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

#article .articleBody {
  font-size: 17px;
  line-height: 26px;
}

#article .articleBody p {
  margin: 0 0 30px;
  font-size: 17px;
  line-height: 26px;
}

#article .articleBody h2 {
  margin: 36px 0 18px;
  font-size: 24px;
  line-height: 28px;
}

#article .articleBody h3 {
  margin: 30px 0 15px;
  font-size: 20px;
  line-height: 24px;
}

#article .articleBody h3 a {
  font-size: 20px;
}

#article .articleBody .wrapper h3 {
  margin: 10px 0;
  font-size: 25px;
  line-height: 1.1;
}

#article .authors {
  float: left;
  color: #5c6773;
  width: 50%;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

#article .authors .author {
  padding-right: 5px;
}

#article .authors.underArticle {
  color: #16212d;
  margin: 25px 0 0;
  font-size: 18px;
}

#article .dateTime {
  float: right;
  color: #98a3af;
  text-align: right;
  width: 190px;
  margin: 30px 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.articleCat {
  color: #7b2cea;
  text-transform: uppercase;
  margin: 0 10px 11px 0;
  padding: 15px 0 0;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: bold;
  display: block;
  position: relative;
}

.category-arts {
  display: none;
  position: relative;
  left: -80px;
}

button.next.disabled {
  opacity: .7;
  cursor: default !important;
}

.listedArticlesToBeHidden {
  display: none;
}

#article.listed, #article.notlisted {
  margin: 0 auto;
  overflow: visible;
}

#article .pseudoInner_7 {
  float: right;
  width: 540px;
  margin-right: 10px;
  padding-top: 0;
  padding-bottom: 10px;
  padding-left: 10px;
  display: block;
}

#article .pseudoInner_8 {
  width: 640px;
  margin: 0 auto;
  padding: 0;
  display: block;
  position: relative;
}

#article .headerWrapper {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}

#article.listed .titleWrapper, #article.notlisted .titleWrapper {
  border-top: 4px solid #7b2cea;
  width: 100%;
  padding-left: 80px;
  display: block;
  position: relative;
  max-width: 540px !important;
}

.nolistedTitleWrapper {
  margin-left: -80px;
  width: 620px !important;
}

#article.listed h1, #article.notlisted h1 {
  padding-top: 20px;
  padding-bottom: 20px;
}

#article.listed .centerBanner, #article.notlisted .centerBanner {
  margin: 0 auto;
  display: block;
}

#article.notlisted h2 {
  font-size: 24px;
  line-height: 28px;
  display: inline-block;
}

#article.notlisted .number {
  float: left;
  color: #fa5ab9;
  padding-right: 20px;
  font-size: 24px;
  font-weight: 700;
  display: inline-block;
  border-right: 2px solid #cad5df !important;
}

#article.notlisted ul.main > li:first-child > img.topImg {
  margin-top: 50px;
  margin-left: -80px;
}

#article.notlisted ul.main > li {
  list-style: none;
  padding-bottom: 20px !important;
}

#article.notlisted ul.main > li:last-of-type {
  border-bottom: 0;
}

#article.notlisted ul.main > li > div > .socialShares {
  margin-left: 0 !important;
  display: block !important;
}

#article ul.main {
  border-top: 1px solid #cad5df;
  margin: 0 10px;
  padding-left: 70px;
}

#article .imgInArt {
  width: 100%;
}

#article.listed ul.main > li .imgInArt > img, #article.notlisted ul.main > li .imgInArt > img {
  width: 100%;
  max-width: 600px;
}

#article.listed ul.listed.inner_8 {
  width: auto;
  padding: 0;
  position: relative;
  overflow: hidden;
}

#article.listed .number {
  float: left;
  color: #7b2cea;
  padding-right: 10px;
  font-size: 35px;
  line-height: 40px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

#article.listed h2 {
  float: left;
  max-width: 320px;
  margin-bottom: 16px;
  margin-left: 63px;
  font-size: 28px;
  line-height: 40px;
  display: inline-block;
}

#article.listed ul.listed > li {
  float: left;
  max-width: 560px;
  padding-bottom: 10px;
  display: none;
}

#article.listed ul.listed li.active {
  display: block;
}

#article.listed #related {
  width: 620px;
  padding: 0;
}

#article.notlisted #related {
  border-bottom: 0;
}

#article.listed #related .relatedArticles {
  padding: 0;
}

#article.notlisted #related h2 {
  margin-left: 69px;
  margin-top: inherit;
  border-left: 0 !important;
}

#article.listed .bottom .socialShares {
  float: left;
  width: auto;
  min-width: 350px;
  margin-top: 6px;
}

#article.listed .articleDetails .socialShares, #article.notlisted .articleDetails .socialShares {
  width: auto;
  min-width: 320px;
}

#article.notlisted .articleDetails .socialShares {
  margin-left: 80px;
}

#article.listed .bottom .counter {
  float: left;
  display: block;
}

#article.listed .bottom {
  width: 100%;
  min-height: 32px;
  padding: 10px 0;
  display: block;
  position: relative;
}

#article.listed .listingControlButtons {
  color: #7b2cea;
  float: right;
  z-index: 1000;
  font-size: 21px;
  display: none;
  position: relative;
}

#article .listingControlButtons {
  margin-bottom: 10px;
  display: none;
  position: relative;
}

#article .listingControlButtons button {
  float: left;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}

#article .bottom .listingControlButtons.smallControlButtons {
  float: right;
  margin-bottom: 20px;
  display: block;
  position: relative;
}

#article .listingControlButtons.smallControlButtons button, #article .listingControlButtons.smallControlButtons .counter {
  float: left;
}

#article .listingControlButtons.smallControlButtons .startListing.showNext {
  color: #7b2cea;
  background-color: rgba(0, 0, 0, 0);
  display: none;
}

#article .listingControlButtons.smallControlButtons button.moreArticles {
  color: #7b2cea;
  background-color: #fff;
}

#article .listingControlButtons.smallControlButtons button.showStart {
  color: #fff;
  width: 75px;
  background-color: #7b2cea !important;
}

#article .listingControlButtons.smallControlButtons .showPrev, #article .listingControlButtons.smallControlButtons .showPrev.showStart {
  background: transparent url("/images/blesk-horoskopy//images/horoskopy/article-listed-sprite.png?v=3!") "0px -37px" 0 0;
  padding: 0;
}

#article .listingControlButtons.smallControlButtons .shownext, #article .listingControlButtons.smallControlButtons .showPrev.showStart {
  background: url("/images/horoskopy/article-listed-sprite.png?v=3") -37px -37px no-repeat;
  padding: 0;
}

#article .smallControlButtons.listingControlButtons button.next:not(.moreArticles) {
  background: url("/images/horoskopy/article-listed-sprite.png?v=3") -37px 0 no-repeat;
  width: 32px;
  max-width: 32px;
  height: 32px;
  max-height: 32px;
}

#article .listingControlButtons.smallControlButtons .showStart.showPrev {
  display: none !important;
}

#article .listingControlButtons .next:not(:-webkit-any(.moreArticles, .showStart)) {
  margin-left: 10px;
}

#article .listingControlButtons .next:not(:is(.moreArticles, .showStart)) {
  margin-left: 10px;
}

#article .listingControlButtons.smallControlButtons .next:not(:-webkit-any(.moreArticles, .showStart)) {
  margin-left: 10px;
}

#article .listingControlButtons.smallControlButtons .next:not(:is(.moreArticles, .showStart)) {
  margin-left: 10px;
}

#article .listingControlButtons .prev, #article .listingControlButtons.smallControlButtons .prev {
  margin-right: 10px;
}

#article .listingControlButtons button:hover {
  cursor: pointer;
}

#article .listingControlButtons button.next:not(.moreArticles) {
  background: url("/images/horoskopy/article-listed-sprite.png?v=3") -37px 0 no-repeat;
  width: 32px;
  max-width: 32px;
  height: 32px;
  max-height: 32px;
}

#article .listingControlButtons button.startListing {
  color: #fff;
  background-color: #7b2cea;
  width: 180px;
  padding: 0;
}

#article .listingControlButtons button.startListing .text {
  text-transform: none;
}

#article .listingControlButtons:not(.smallControlButtons) button.showStart {
  color: #fff;
  width: 75px;
  padding: 0;
  background-color: #7b2cea !important;
}

#article .listingControlButtons button.moreArticles {
  float: left;
  color: #fff;
  min-width: 138px;
  padding: 0;
  display: block;
}

#article .listingControlButtons:not(.smallControlButtons) button.moreArticles {
  background-color: #7b2cea !important;
}

#article .listingControlButtons button.moreArticles > .whiteArrow {
  float: right;
}

#article .listingControlButtons button.prev, #article .listingControlButtons button.prev.showStart {
  background: url("/images/horoskopy/article-listed-sprite.png?v=3") no-repeat;
  width: 32px;
  max-width: 32px;
  height: 32px;
  max-height: 32px;
  padding: 0;
}

#article .listingControlButtons button .text {
  float: left;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  padding: 5px 32px 10px 10px;
  font-size: 16px;
  line-height: 1.2;
  display: block;
  position: relative;
}

#article .listingControlButtons:not(.smallControlButtons) .showStart .text {
  padding-left: 30px;
  padding-right: 5px;
}

#article.listed .whiteArrow {
  background: url("/images/horoskopy/article-listed-sprite.png?v=3") -37px 0 no-repeat;
  width: 32px;
  max-width: 32px;
  height: 32px;
  max-height: 32px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

#article.listed .whiteArrowBack {
  background: url("/images/horoskopy/article-listed-sprite.png?v=3") -3px 0 no-repeat;
  width: 25px;
  max-width: 32px;
  height: 32px;
  max-height: 32px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

#article.listed .smallControlButtons .whiteArrow {
  background: url("/images/horoskopy/article-listed-sprite.png?v=3") -43px -37px no-repeat;
  width: 25px;
  max-width: 32px;
  height: 32px;
  max-height: 32px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

#article.listed .smallControlButtons .whiteArrowBack {
  width: 25px;
  max-width: 32px;
  height: 32px;
  max-height: 32px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

#article.listed .smallControlButtons .showStart .text {
  padding-left: 30px;
}

#article.listed .articleDetails, #article.notlisted .articleDetails {
  margin: 10px 0;
  padding: 0 20px;
  position: relative;
}

#article.listed .relatedArticles {
  width: 100%;
}

#article.listed .relatedArticles .article img, #article.notlisted .relatedArticles .article img {
  cursor: pointer;
  width: 280px;
  height: 160px;
}

#article.listed .relatedArticles .article a {
  color: #16212d;
  cursor: pointer;
  width: auto;
  max-width: 310px;
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
}

#article.notlisted .relatedArticles .article a {
  cursor: pointer;
  width: auto;
  max-width: 260px;
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
}

#article.listed .relatedArticles .article, #article.notlisted .relatedArticles .article {
  position: relative;
}

#article.listed .relatedArticles .article:last-child, #article.notlisted .relatedArticles .article:last-child {
  border-bottom: 0;
}

#article.listed .relatedArticles .article:first-child, #article.notlisted .relatedArticles .article:first-child {
  border-top: 1px solid #cad5df;
  margin-top: 0;
}

#article.listed .relatedArticles .article p.perex {
  color: #16212d;
  cursor: pointer;
  max-width: 300px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  right: 20px;
  font-size: 15px !important;
  line-height: 20px !important;
}

#article.listed .relatedArticles .article p.perex:before {
  content: "";
  float: left;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -250px -216px no-repeat;
  width: 10px;
  height: 10px;
  margin: 5px 3px 0 0;
}

#article.notlisted .relatedArticles .article a.perex {
  color: #16212d;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-weight: 400;
  position: absolute;
  bottom: 10px;
  right: 0;
  font-size: 15px !important;
  line-height: 20px !important;
}

#article.notlisted ul.main > li:first-child .internBanner, #article.listed ul.main > li:first-child .internBanner {
  margin-left: 80px;
  max-width: 500px !important;
}

#article.listed .relatedArticles .article a.perex:before, #article.notlisted .relatedArticles .article a.perex:before {
  content: "";
  float: left;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -250px -216px no-repeat;
  width: 10px;
  height: 10px;
  margin: 5px 3px 0 0;
}

#article.listed .authors:not(.autorLeft) {
  text-align: left;
  font-weight: 700;
  line-height: 20px;
}

#article.listed .dateTime, #article.notlisted .dateTime {
  width: auto;
  margin-top: 33px;
  margin-bottom: 27px;
}

#article.listed ul > li:first-child img.imgTop {
  width: 615px;
  margin: 0 auto;
  display: block;
}

#article.listed .internBanner {
  margin-left: -70px;
}

#article.listed .listingControlButtons .counter {
  float: left;
  line-height: 32px;
}

#article.listed .errorsFooter {
  background-color: #e9eff4;
}

#article.listed .relativeObject {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

#article.listed ul.main li:first-of-type .photoDesc, #article.notlisted ul.main li:first-of-type .photoDesc {
  color: #e9eff4;
  background-color: #16212d;
  width: 595px;
  margin-bottom: 20px;
  padding: 10px;
  font: 700 12px / 15px Arial, sans-serif;
}

#article.notlisted ul.main li:first-of-type .photoDesc {
  width: 600px;
}

#article.listed ul.main li:first-of-type .photoDesc .author, #article.notlisted ul.main li:first-of-type .photoDesc .author {
  color: #98a3af;
  font-weight: 400;
}

.tagsFooter {
  text-align: left;
  color: #16212d;
  border-top: 1px solid #cad5df;
  border-bottom: 1px solid #cad5df;
  width: 640px;
  margin: 20px 0;
  padding-bottom: 10px;
  position: relative;
}

.tagsFooter a {
  font-size: 15px;
}

.tagsFooter h4 {
  color: #98a3af;
  text-transform: uppercase;
  float: left;
  width: 80px;
  margin: 10px 0;
  padding: 0;
  font-family: Roboto Regular, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  display: inline-block;
}

.tagsFooter p {
  float: left;
  width: 560px;
  display: inline-block;
  position: relative;
  top: 5px;
}

#article.listed .tagsFooter, #article.notlisted .tagsFooter {
  margin-left: 0;
  padding-left: 10px;
}

#article.listed .imgInArtDescription {
  background-color: #16212d;
  font: 700 12px / 15px Arial, sans-serif;
  color: #e9eff4 !important;
  margin: 0 !important;
  padding: 10px 10px 0 !important;
}

#article.listed .imgAuthor {
  background-color: #16212d;
  font: 700 12px / 15px Arial, sans-serif;
  color: #e9eff4 !important;
  margin: 0 !important;
  padding: 0 10px 10px !important;
}

#article.notlisted .imgAuthor {
  padding: 10px;
}

#article.notlisted .imgInArtDescription {
  max-width: 100%;
  padding: 10px;
}

#article li#related h2 {
  margin-bottom: 5px;
  padding-left: 0;
}

#article.listed .leadsection p, #article.listed .leadsection p span {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}

.listed .listed .leadsection {
  margin-left: 0 !important;
}

#article.notlisted .leadsection {
  padding-right: 10px;
}

#article.notlisted .autorLeft, #article.listed .autorLeft {
  border-top: 0;
  padding-top: 0;
  margin-top: 50px !important;
}

#article.notlisted .autorLeft .author-wrapper, #article.listed .autorLeft .author-wrapper {
  padding-top: 10px;
}

#article.notlisted .authors.autorLeft, #article.listed .authors.autorLeft {
  float: left;
  color: #5c6773;
  width: 600px;
  margin: 0 auto;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

#article.notlisted .authors.autorLeft {
  margin-top: 0 !important;
}

#article .header {
  color: #7b2cea;
  margin: 13px 0 0 10px;
  font: 400 25px / 37px Roboto, sans-serif;
}

#article .header h4 {
  margin: 15px 0;
}

ul.listed, ul.notlisted {
  float: none;
  display: block;
}

.paging {
  float: none;
  background-color: #fff;
  width: 620px;
  margin-bottom: 20px;
  padding: 1px;
  font-family: Arial, sans-serif;
  font-weight: 700;
  line-height: 40px;
  display: block;
}

.paging button {
  cursor: pointer;
  color: #fff;
  background-color: #dc0032;
  border: none;
  width: 100px;
  margin: 0;
  padding: 10px;
  font-size: 17px;
  font-weight: 700;
  line-height: 30px;
  display: block;
}

.paging button.next {
  float: right;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.paging button.prev {
  float: left;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.paging a {
  float: left;
  color: #000;
  text-align: center;
  min-width: 29px;
  height: 100%;
  padding: 10px 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-decoration: none;
  display: block;
}

.paging a[data-id="intro"] {
  text-transform: capitalize;
  margin-right: 30px;
}

.paging a[data-id="intro"].active {
  background-color: #b9c7cd;
  min-width: 29px;
  margin-left: 0;
  margin-right: 30px;
  padding: 10px 5px;
}

.paging a:hover {
  color: #000;
  text-decoration: none;
}

.paging a[data-id="related"] {
  margin-left: 30px;
}

.paging a.active:not([data-id="intro"]) {
  background-color: #b9c7cd;
  min-width: 29px;
  padding: 10px 5px;
}

.jcarousel {
  float: left;
  width: 900px;
  height: 90px;
  margin: 5px 0;
  position: relative;
  left: 20px;
  overflow: hidden;
}

.jcarousel ul {
  width: 20000em;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
}

.jcarousel li {
  float: left;
}

a.jcarousel-prev {
  text-indent: 9999px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -250px -216px no-repeat content-box content-box;
  width: 10px;
  height: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 10px;
  display: block;
  position: absolute;
  top: 35px;
  left: 5px;
  transform: rotate(180deg);
}

a.jcarousel-next {
  text-indent: -9999px;
  background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -250px -216px no-repeat content-box content-box;
  width: 10px;
  height: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 10px;
  display: block;
  position: absolute;
  top: 35px;
  right: 5px;
}

.jcarousel ul li img {
  width: 160px;
  height: 90px;
  margin: 0 10px;
}

.galleryHP {
  background: #16212d;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  position: relative;
  overflow: hidden;
}

.galleryHP .title {
  color: #fff;
  float: left;
  text-transform: uppercase;
  margin: 0 5px 0 10px;
  line-height: 20px;
  display: block;
}

.galleryHP a.galleryLink {
  color: red;
  float: left;
  text-transform: uppercase;
  line-height: 20px;
  display: block;
}

.relativeObject .grid_8 .imageWrapper {
  width: 540px;
}

.fb_iframe_widget {
  margin-right: 5px;
}

.autorLeft {
  height: 150px;
  position: relative;
}

.autorLeft .author-wrapper {
  height: 145px;
  position: relative;
}

.autorLeft .anotImg {
  float: left;
  margin: 10px;
  position: relative;
}

.autorLeft .name {
  border-bottom: 1px solid #cad5df;
  margin: 5px 20px 10px 110px;
  padding-bottom: 5px;
  display: block;
  position: relative;
}

.autorLeft .profilAuth {
  float: right;
  position: relative;
}

.autorLeft .profilAuth a {
  color: #16212d;
}

.autorLeft .leftArt {
  font-size: 15px;
}

.autorLeft .leftArt a {
  color: #16212d;
}

#top-panel .magazineLogo a {
  background: none;
  width: 150px;
  min-height: 40px;
  display: block;
}

.astroArticles .article {
  border-bottom: 1px solid #e6e1dc;
  padding-top: 10px;
  padding-bottom: 9px;
}

.astroArticles .article .imgWrapper {
  float: left;
  width: 80px;
  height: 50px;
  margin-right: 20px;
  overflow: hidden;
}

.astroArticles .article .imgWrapper .thumb {
  width: 80px;
  height: 53px;
  margin-top: -1px;
  padding-right: 20px;
}

.astroArticles .article .title {
  float: left;
  color: #16212d;
  width: 158px;
  font-family: Roboto Bold, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
}

.astroArticles .article .title .link {
  color: #16212d;
  height: 48px;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
}

.astroArticles .article .title .link:hover {
  text-decoration: underline;
}

.astroArticles .article.last {
  border-bottom: 0;
  padding-bottom: 10px;
}

.astroArticles .article:hover .title .link {
  text-decoration: underline;
}

.bigArticle .title a, .middleArticle .title a, .smallArticle .title a {
  transition: all .1s ease-out;
}

.h-articles {
  perspective-origin: top;
  perspective: 5000px;
}

.bigArticle .title:hover .hoverColor, .middleArticle .title:hover .hoverColor, .smallArticle .title:hover .hoverColor {
  text-decoration: underline;
}

.bigArticle .title, .middleArticle .title, .smallArticle .title {
  color: #16212d;
}

.bigArticle .title a, .middleArticle .title a, .smallArticle .title a {
  color: #16212d;
  text-decoration: none;
}

.bigArticle .title a a:hover, .bigArticle .title a a:focus, .middleArticle .title a a:hover, .middleArticle .title a a:focus, .smallArticle .title a a:hover, .smallArticle .title a a:focus {
  text-decoration: underline;
}

.bigArticle .perex, .middleArticle .perex, .smallArticle .perex {
  color: #16212d;
  margin-top: 22px;
  font-family: Arial, sans-serif;
  line-height: 20px;
}

.wrapper {
  overflow: hidden;
}

.bigArticle .wrapper {
  margin: 10px 0 20px;
  position: relative;
}

.bigArticle .articlePhoto img {
  width: 600px;
  height: 240px;
  position: absolute;
  top: 0;
  left: 0;
}

.bigArticle .title {
  margin-top: 0;
  padding-top: 280px;
  font: 35px / 35px Roboto Bold, sans-serif;
  position: relative;
}

.bigArticle .icon.iconPlay, .bigArticle .icon.iconPhoto {
  top: 222px;
  left: 281px;
}

.bigArticle .articleCat {
  z-index: 1;
  position: absolute;
  top: 240px;
}

.middleArticle .artPaid {
  top: -1px;
}

.middleArticle .articlePhoto {
  margin-top: 20px;
}

.middleArticle .wrapper {
  border-top: 1px solid #cad5df;
  border-bottom: 1px solid #cad5df;
  height: 95px;
  padding: 0 0 56px 220px;
  position: relative;
  overflow: visible;
}

.middleArticle .wrapper .articlePhotoAuto {
  width: 220px;
  height: 158px;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.middleArticle .wrapper .articlePhotoAuto img {
  height: auto;
  top: -20px;
}

.middleArticle .wrapper .articleContext {
  position: absolute;
  bottom: 7px;
  left: 0;
}

.middleArticle img {
  width: 200px;
  position: absolute;
  top: 19px;
  left: 0;
}

.middleArticle .title {
  float: left;
  letter-spacing: 0;
  margin: 0 20px 0 0;
  font: 25px / 28px Roboto Bold, sans-serif;
  position: relative;
  top: -4px;
}

.middleArticle .perex {
  float: left;
  height: 40px;
  margin: 22px 20px 0 0;
}

.middleArticle .icon.iconPlay, .middleArticle .icon.iconPhoto {
  bottom: 15px;
  left: 165px;
}

.smallArticle .wrapper {
  height: 160px;
  margin: 10px 0 5px;
  padding-top: 158px;
  position: relative;
  overflow: hidden;
}

.smallArticle .articlePhoto {
  width: 280px;
  height: 158px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.smallArticle .title {
  height: 115px;
  margin-top: 17px;
  font: 25px / 28px Roboto Bold, sans-serif;
  overflow: hidden;
}

.smallArticle .icon.iconPlay, .smallArticle .icon.iconPhoto {
  top: 140px;
  left: 121px;
}

.categoryTitle {
  text-transform: uppercase;
  color: red;
  word-spacing: 3px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 26px;
}

.articleContext {
  color: #98a3af;
  margin-top: 10px;
  font-size: 12px;
  line-height: 15px;
}

.articleContext a {
  color: #98a3af;
}

.articleContext .galleryIcon {
  background: url("/images/blesk-horoskopy/sprites/sprites.png?v=4") -387px -50px no-repeat;
  width: 12px;
  height: 9px;
  display: inline-block;
}

.articleContext .galleryTitle, .articleContext .forumTitle {
  padding: 0 3px;
}

.articleContext .forumIcon {
  background: url("/images/blesk-horoskopy/sprites/sprites.png?v=4") -401px -50px no-repeat;
  width: 13px;
  height: 11px;
  display: inline-block;
}

.articleContext .sharesIcon {
  background: url("/images/blesk-horoskopy/sprites/sprites.png?v=4") -373px -50px no-repeat;
  width: 12px;
  height: 10px;
  display: inline-block;
}

.articleContext .forumCount, .articleContext .sharesCount, .articleContext .galleryCount {
  color: #343f4b;
  margin-right: 5px;
}

.icon {
  width: 38px;
  height: 38px;
  display: block;
}

.icon.iconPlay {
  background: url("/images/blesk-horoskopy/play.svg") no-repeat;
  position: absolute;
}

.icon.iconPhoto {
  background: url("/images/blesk-horoskopy/sprites/sprites.png?v=4") -339px -67px no-repeat;
  position: absolute;
}

.load-more {
  color: #333f48;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 10px;
  text-decoration: none;
  display: block;
  position: relative;
}

.load-more .btn--load {
  box-sizing: border-box;
  height: 40px;
  padding: 0 58px;
}

.load-more .btn--load:hover, .load-more .btn--load:focus {
  background-color: #5d00c6;
}

.dreams--full .alphabet .character {
  text-align: center;
  text-transform: uppercase;
  border-radius: 50%;
  width: 37px;
}

.dreams--full .alphabet .character a:hover {
  text-decoration: none;
}

.dreams--full .alphabet .character.active {
  background: #7323eb;
}

.dreams--full .alphabet .character.inactive {
  color: #e6e1dc;
  margin: 0;
}

.dreams--full .alphabet .character.active a {
  color: #fff;
}

@keyframes slide-in-from-left {
  0% {
    transform-origin: 0 0 0;
    transform: scaleY(0);
  }

  100% {
    transform-origin: 0 0 0;
    transform: scaleY(1);
  }
}

.dreams .input-submit {
  width: 66px;
  padding: 0 12px;
}

.dreams .input-text {
  width: 146px;
}

.dreams .response-wrapper li {
  border: 1px solid #d2cdc8;
  border-top-width: 0;
  width: 243px;
  list-style: none;
  animation: .1s ease-out slide-in-from-left;
}

.dreams .response-wrapper li a {
  padding: 5px 10px;
  display: block;
}

.dreams .response-wrapper li a:hover {
  background-color: #f2edf8;
}

.error404 {
  text-align: center;
  padding: 50px 0;
}

.error404 .image-404 {
  padding-bottom: 20px;
}

.error404 .image-404 img {
  margin: auto;
  display: block;
}

.error404 .apologise {
  color: #7b2cea;
}

.error404 .apologise h2 {
  font-size: 20px;
  line-height: 40px;
}

.error404 .apologise h3 {
  margin-bottom: 36px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.error404 .apologise a {
  color: #fa5ab9;
  text-decoration: underline;
}

.error404 .return-to-hp a {
  width: 282px;
  height: 40px;
  margin: auto;
}

#article.listed .pseudoInner_8 > .listingControlButtons {
  top: 15px;
  left: -20px;
}

.box-gallery {
  float: left;
  width: 620px;
  height: 328px;
}

.box-gallery .gallery-main-container .image-description {
  display: none;
}

.box-gallery .gallery-main-container .gallery {
  height: 100%;
}

.box-gallery .gallery-main-container .gallery .icon-loading {
  background: url("https://img2.cncenter.cz/images/blesk-2013/loading.svg?v=1") center no-repeat;
}

.box-gallery .gallery-main-container .gallery .btn {
  height: 100%;
  top: 0;
}

.box-gallery .gallery-main-container .gallery .btn.prev .dataimg-arrow-light-left {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -950px -450px no-repeat;
}

.box-gallery .gallery-main-container .gallery .btn.next .dataimg-arrow-light-right {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -1000px -450px no-repeat;
}

.box-gallery .gallery-main-container .gallery .btn .icon-arrow {
  border-left: 5px solid rgba(0, 0, 0, 0);
}

.box-gallery .gallery-main-container .gallery .next .image-count {
  display: none;
}

.box-gallery .gallery-main-container .gallery .images-container .owl-item img {
  left: 0;
  right: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.clear-l {
  clear: left;
}

.cb {
  clear: both;
}

.footer ul {
  float: left;
  text-align: left;
  width: 22%;
  margin: 0 1.5%;
  padding: 0;
  list-style: none;
  display: block;
}

.footer ul li {
  margin: 10px 0;
  line-height: 1;
}

.footer ul li.cats a {
  font-size: 12px;
}

.footer {
  color: #8b8b8b;
  clear: both;
  background-color: #fff;
  padding-top: 4px;
  display: block;
  position: relative;
}

.footer ul#pBlesk {
  padding-left: 0;
}

.footer li.footer-img {
  height: 40px;
}

.footer li.footer-img img {
  border: none;
}

.footer a {
  color: #8b8b8b;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 11px;
  font-weight: normal;
  line-height: 1;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.logoAodkazy div {
  color: #868686;
  float: right;
  margin-top: 25px;
  font-family: Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
}

.logoAodkazy div a {
  color: #868686;
  font-family: Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
}

.footerText {
  text-align: center;
  color: #868686;
  border-top: 1px solid silver;
  margin: 1.5% 0;
  padding: 10px 0;
  font-family: Arial, sans-serif;
  font-size: 11px;
  font-weight: normal;
  text-decoration: none;
}

.footer a.logo {
  text-indent: -999px;
  height: 29px;
  display: block;
  overflow: hidden;
}

#pBlesk .logo {
  background: url("/images/newblesk/footer-logos.jpg?v=3") 0 0 no-repeat;
}

#pSport .logo {
  background: url("/images/newblesk/footer-logos.jpg?v=3") 0 -69px no-repeat;
}

#pProzeny .logo {
  background: url("/images/newblesk/footer-logos.jpg?v=3") 0 -34px no-repeat;
}

#pAbc .logo {
  background: url("/images/newblesk/footer-logos.jpg?v=3") 0 -101px no-repeat;
}

#pAuto .logo {
  background: url("/images/newblesk/footer-logos.jpg?v=3") 0 -133px no-repeat;
}

#pAha .logo {
  background: url("/images/newblesk/footer-logos.jpg?v=3") 0 -165px no-repeat;
}

#pReflex .logo {
  background: url("/images/newblesk/footer-logos.jpg?v=3") 0 -200px no-repeat;
}

#pSleviste .logo {
  background: url("/images/newblesk/footer-logos.jpg?v=3") 0 -239px no-repeat;
}

#pFunBlesk .logo {
  background: url("/images/newblesk/footer-logos.jpg?v=3") 0 -279px no-repeat;
  height: 49px;
}

#pIvio .logo {
  background: url("/images/newblesk/ivio_logo.jpg?v=5") no-repeat;
  height: 29px;
}

#piVstupenky .logo {
  background: url("/images/newblesk/footer-logos.jpg?v=3") 0 -339px no-repeat;
  height: 49px;
}

#copyrightPopup {
  z-index: 1000;
  background: #fff;
  border: 1px solid #000;
  width: 640px;
  height: 380px;
  margin-left: 151px;
  padding: 10px;
  position: fixed;
  top: 50px;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: 0 20px 60px -10px #46413c;
}

#copyrightPopup h4 {
  color: #333;
  font-size: 24px;
}

#copyrightPopup p {
  color: #333;
  margin: 6px 0;
  padding-bottom: 2px;
  font-size: 14px;
  line-height: 18px;
}

#copyrightPopup h1 {
  color: #333;
}

#copyrightPopupClose {
  color: #fff;
  cursor: pointer;
  text-align: center;
  background: #000;
  width: 141px;
  height: 29px;
  margin-left: 672px;
  font-weight: bold;
  line-height: 28px;
  position: fixed;
  top: 21px;
}

.mt10px {
  margin-top: 10px !important;
}

.mr10px {
  margin-right: 10px !important;
}

.mb10px {
  margin-bottom: 10px !important;
}

.ml10px {
  margin-left: 10px !important;
}

.gam-wrapper {
  clear: both;
  display: block;
}

.mimibazar-block.customizable {
  border-top: 0;
  margin: 0 0 10px;
}

.mimibazar-block.customizable h2 {
  color: #7b2cea;
  text-transform: none;
  font-family: Roboto Regular, sans-serif;
  font-size: 25px;
}

.mimibazar-block.customizable .item {
  margin-right: 15px;
}

.mimibazar-block.customizable .item:nth-child(4n+4) {
  margin-right: 0;
}

.mimibazar-block.customizable .item .image {
  background: #d2cdc8;
}

.mimibazar-block.customizable .item .title {
  color: #16212d;
}

.mimibazar-block.customizable .item .price {
  color: #aaa5a0;
}

.mimibazar-block.customizable .item .more {
  color: #7b2cea;
  border-bottom-color: #7b2cea;
}

.mimibazar-block.customizable .item .more:after, .mimibazar-block.customizable .item .more:before {
  background-color: #7b2cea;
}

.mimibazar-block.customizable .item .more:hover {
  color: #96918c;
}

.mimibazar-block.customizable .item .more:hover:after, .mimibazar-block.customizable .item .more:hover:before {
  background-color: #96918c;
}

#article .galleryInArticle .header {
  font: inherit;
  margin: 0;
  line-height: 18px;
}

#article.notlisted .galleryInArticle .imgAuthor {
  padding: 0;
}

.galleryInArticle {
  background: #16212d;
  padding: 10px;
}

.galleryInArticle.onlineReport {
  margin-left: -140px !important;
}

.galleryInArticle .header {
  padding: 0 40px 10px;
}

.galleryInArticle .header a {
  text-transform: uppercase;
  font-family: Roboto Regular, sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
}

.galleryInArticle .header .galleryCount {
  float: right;
  color: #e9eff4;
  font-size: 15px;
  line-height: 18px;
  display: inline-block;
}

.galleryInArticle .header .title {
  color: #fff;
  padding: 0 5px;
}

.galleryInArticle .description {
  color: #e9eff4;
  padding: 10px 40px;
  font-size: 12px;
  line-height: 15px;
}

.galleryInArticle .imgAuthor {
  color: #e9eff4;
  margin: 0 40px;
  font-size: 12px;
  line-height: 15px;
}

.galleryInArticle .smallPhoto {
  position: relative;
}

.galleryInArticle .smallPhoto .photoIn {
  float: left;
  margin: 0 0 0 20px;
  position: relative;
}

.galleryInArticle .smallPhoto .photoIn img {
  width: 158px;
  height: 100px;
}

.galleryInArticle .smallPhoto .galleryPrev {
  text-indent: 9999px;
  background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -50px -232px no-repeat content-box content-box;
  border-left: 1px solid #3d4952;
  width: 10px;
  height: 10px;
  margin: 5px 0 0;
  padding: 40px 0 40px 10px;
  display: block;
  position: relative;
  top: 0;
  transform: rotate(180deg);
}

.galleryInArticle .smallPhoto .galleryNext {
  text-indent: 9999px;
  float: left;
  background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -50px -216px no-repeat content-box content-box;
  border-left: 1px solid #98a3af;
  width: 10px;
  height: 10px;
  margin: 5px 0 0;
  padding: 40px 0 40px 10px;
  display: block;
  position: relative;
  top: 0;
  right: 5px;
}

.galleryInArticle .photoFrame {
  background-color: #16212d;
}

.galleryInArticle.relativeObject:not(.listedRelatedReal) {
  margin: 25px 0 25px -80px;
}

.galleryInArticle .bigPhoto {
  position: relative;
}

.galleryInArticle .bigPhoto .photoIn {
  width: 520px;
  margin: 0 0 0 40px;
  display: inline-block;
  position: relative;
}

.galleryInArticle .galleryPrev {
  text-indent: 9999px;
  background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -50px -232px no-repeat content-box content-box;
  border-left: 1px solid #3d4952;
  width: 10px;
  height: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 10px;
  display: block;
  position: absolute;
  top: 85px;
  left: 5px;
  transform: rotate(180deg);
}

.galleryInArticle .galleryNext {
  text-indent: 9999px;
  background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites.png?v=201") -50px -216px no-repeat content-box content-box;
  border-left: 1px solid #98a3af;
  width: 10px;
  height: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 10px;
  display: block;
  position: absolute;
  top: 85px;
  right: 5px;
}

.header-logo span {
  display: block;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.page-404 {
  text-align: center;
  background: #fff;
  border-top: 5px solid #7b2cea;
  justify-content: center;
  align-items: center;
  min-height: 274px;
  margin: 10px auto 30px;
  display: flex;
  overflow: hidden;
}

.page-404 .btn--purple {
  padding: 0 3em;
}

.page-404__apology {
  text-align: center;
  width: 45%;
}

.page-404__headline {
  color: #7b2cea;
  margin-bottom: 30px;
  font-family: Roboto-Light, Arial, Verdana, sans-serif;
  font-size: 30px;
  font-weight: normal;
  line-height: 37px;
}

.page-404__picture-wrapper {
  width: 45%;
}

.page-404__picture {
  width: 100%;
  max-width: 275px;
}

.page-404__return {
  max-width: 258px;
  display: inline-block;
}

.articleVideoTop {
  width: 615px;
  margin-left: -75px;
}

.videoInArticle {
  color: #98a3af;
  background-color: #16212d;
  margin: 10px 0;
  padding: 5px;
  font: 12px / 15px Arial, sans-serif;
}

.videoInArticle .videoHeader {
  margin: 0;
}

.videoInArticle .cnc-video-container {
  margin: 5px 0;
}

.videoInArticle .videoThumb.galleryTransition img {
  max-width: 100%;
}

.seers {
  margin-bottom: 10px;
}

.seers-link {
  margin-bottom: 15px;
  font-size: 24px;
  display: inline-block;
}

@font-face {
  font-family: opensans-regular;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: opensans-bold;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.cookie-bar {
  text-align: center;
  color: #fff;
  z-index: 200000;
  background-color: rgba(50, 50, 50, .95);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
  font-family: opensans-regular, arial, sans-serif;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.cookie-bar a, .cookie-bar a:hover {
  color: inherit;
}

.cookie-bar__title {
  font-family: opensans-bold, arial, sans-serif;
  font-size: 26px;
  line-height: 36px;
}

.cookie-bar__title br {
  display: none;
}

.cookie-bar__text {
  padding: 5px 0 10px;
  font-size: 12px;
  line-height: 16px;
}

.cookie-bar__text br {
  display: inline;
}

.cookie-bar__enable-btn {
  text-transform: uppercase;
  cursor: pointer;
  background-color: red;
  width: 140px;
  height: 35px;
  margin: 0 15px 10px auto;
  font-size: 14px;
  line-height: 35px;
  text-decoration: none;
  display: inline-block;
}

.cookie-bar__more-info {
  margin-bottom: 0;
  font-size: 11px;
  line-height: 15px;
  text-decoration: underline;
  display: inline-block;
}

.cookie-bar__more-info:hover {
  text-decoration: none;
}

.cookie-bar__close-btn {
  cursor: pointer;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  overflow: hidden;
}

.cookie-bar__close-btn:before, .cookie-bar__close-btn:after {
  content: "";
  background: #fff;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  left: 0;
}

.cookie-bar__close-btn:before {
  transform: rotate(45deg);
}

.cookie-bar__close-btn:after {
  transform: rotate(-45deg);
}

.cookie-bar__close-btn--thick:before, .cookie-bar__close-btn--thick:after {
  height: 3px;
  margin-top: -2px;
}

.xml-related__articles {
  color: #7b2cea;
  margin: 20px 10px;
}

.xml-related__articles .list-header {
  margin-bottom: 10px;
}

.xml-related__articles .list-header .title-container h2.title {
  font-size: 20px;
}

.xml-related__articles .list-article-small-horizontal {
  margin-bottom: 10px;
}

.xml-related__articles .list-article-small-horizontal .image-container {
  float: left;
  display: block;
}

.xml-related__articles .list-article-small-horizontal .title-container {
  float: left;
  width: calc(100% - 130px);
  margin-left: 10px;
  font-size: 12px;
}

.xml-related__articles .list-article-small-horizontal .title-container .title {
  border: none;
  margin-top: 5px;
  padding: 0;
  font-size: 15px;
  line-height: 20px;
}

.xml-related__articles .list-article-small-horizontal .title-container .title > a {
  color: #16212d;
}

.xml-related__articles .list-article-medium-vertical {
  float: left;
  width: 220px;
  padding: 7.5px;
}

.xml-related__articles .list-article-medium-vertical .title-container {
  margin-top: 5px;
}

.xml-related__articles .list-article-medium-vertical .title-container .title {
  margin-top: 5px;
  font-size: 15px;
  line-height: 20px;
}

.xml-related__articles .list-article-medium-vertical .title-container .title > a {
  color: #16212d;
}

#recentarticles-premium-pane .boxadmin-list-article, #recentarticles-premium-preload .boxadmin-list-article {
  z-index: 10;
  width: 640px;
  margin-left: -50px;
  padding: 0 40px 10px;
  position: relative;
}

#recentarticles-premium-pane .title-basic--small-red, #recentarticles-premium-preload .title-basic--small-red {
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  background: red;
  width: calc(100% - 20px);
  height: 30px;
  margin: 10px;
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
  line-height: 32px;
  display: block;
  overflow: hidden;
}

#recentarticles-premium-pane .list-article--vertical-smaller, #recentarticles-premium-preload .list-article--vertical-smaller {
  width: 140px;
  height: 193px;
  margin: 0 10px 20px;
}

#recentarticles-premium-pane .list-article, #recentarticles-premium-preload .list-article {
  float: left;
  background-color: #fff;
  position: relative;
}

#recentarticles-premium-pane .list-article .list-article__image, #recentarticles-premium-preload .list-article .list-article__image {
  display: block;
}

#recentarticles-premium-pane .list-article .list-article__image .dataimg-tag-blesk-premium, #recentarticles-premium-preload .list-article .list-article__image .dataimg-tag-blesk-premium {
  z-index: 1;
  line-height: 0;
  position: absolute;
  left: 0;
}

#recentarticles-premium-pane .list-article .list-article__image img, #recentarticles-premium-preload .list-article .list-article__image img {
  float: left;
  display: block;
}

#recentarticles-premium-pane .list-article .list-article__title, #recentarticles-premium-preload .list-article .list-article__title {
  color: #000;
  height: 91px;
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  display: block;
  overflow: hidden;
}

#recentarticles-premium-pane .list-article a, #recentarticles-premium-preload .list-article a {
  text-decoration: none !important;
}

#recentarticles-premium-pane .recent-articles__next-articles, #recentarticles-premium-preload .recent-articles__next-articles {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

#recentarticles-premium-pane .recent-articles__next-articles .next-articles, #recentarticles-premium-preload .recent-articles__next-articles .next-articles {
  text-align: center;
  color: #fff;
  background: red;
  border-radius: 2px;
  width: 214px;
  height: 30px;
  padding: 12px 26px 10px;
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 40px;
  display: inline;
  text-decoration: none !important;
}

@media (max-width: 426px) {
  #recentarticles-premium-pane .title-basic.title-basic--small-red, #recentarticles-premium-preload .title-basic.title-basic--small-red {
    margin: 5px 0;
  }

  #recentarticles-premium-pane .next-articles, #recentarticles-premium-preload .next-articles {
    text-align: center;
    color: #fff;
    background: red;
    border-radius: 2px;
    width: 214px;
    margin: 21px auto;
    padding-top: 12px;
    padding-bottom: 10px;
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
    text-decoration: none;
    display: block;
  }

  #recentarticles-premium-pane .list-article svg, #recentarticles-premium-preload .list-article svg {
    z-index: 10;
    width: 69px;
    height: 15px;
    position: absolute;
  }

  #recentarticles-premium-pane .dataimg-tag-blesk-premium, #recentarticles-premium-preload .dataimg-tag-blesk-premium {
    display: block;
  }

  #recentarticles-premium-pane .boxadmin-list-article, #recentarticles-premium-preload .boxadmin-list-article {
    width: 100%;
    margin-top: 40px;
    margin-left: 0;
    padding: 0;
  }

  #recentarticles-premium-pane .title-basic--small-red, #recentarticles-premium-preload .title-basic--small-red {
    width: 100%;
  }

  #recentarticles-premium-pane .list-article__wrapper-left, #recentarticles-premium-preload .list-article__wrapper-left {
    float: left;
    width: 43.75%;
    margin: 10px 0 5px;
  }

  #recentarticles-premium-pane .list-article__image img, #recentarticles-premium-preload .list-article__image img {
    max-width: 100%;
  }
}

.loading-placeholder {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading-placeholder img {
  opacity: 0;
  width: 100%;
  transition: opacity 1s;
}

.loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.loading-placeholder.item-loaded img {
  opacity: 1;
  height: auto;
}

.loading-placeholder:not(.item-loaded) {
  background-color: #e8e8e8;
  height: auto;
  position: relative;
}

.loading-placeholder:not(.item-loaded):after {
  content: " ";
  background-color: #e8e8e8;
  background-image: url("https://img2.cncenter.cz/images/default/lazy-load/lazyload_placeholder.gif");
  background-repeat: repeat-y;
  background-size: 100% 1px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-placeholder:not(.item-loaded) img {
  text-indent: -9999px;
  color: rgba(0, 0, 0, 0);
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

img.lazyload {
  height: auto;
}

#paid-zone a.link--red {
  color: red;
}

.icon-premium {
  z-index: 1;
  box-sizing: border-box;
  color: #403014;
  text-align: center;
  background-color: #e8c157;
  width: 80px;
  height: 20px;
  padding: 5px 0 0;
  font-size: 12px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.icon-premium.small {
  width: 76px;
  height: 16px;
}

.icon-premium.small .icon-premium__fallback {
  position: relative;
  top: -2px;
}

.icon-premium__x {
  color: #e50031;
}

.icon-premium__fallback {
  font-family: arial, sans-serif;
  line-height: 1;
}

.icon-premium__link {
  color: #403014;
  width: 100%;
  height: 100%;
}

.dataimg-icon-premium {
  z-index: 1;
  background-position: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.premium-x {
  background: #f8f8f9;
  margin: 0 0 30px;
}

.premium-x .subscription {
  width: 530px;
  margin: 0 auto;
}

.premium-x .subscription__headline {
  width: 95%;
}

.premium-x.recentarticles-premium {
  background: none;
  margin: 0;
}

.premium-x__archive {
  text-align: center;
}

.premium-x__archive-issue {
  width: 300px;
  margin: 0 0 20px;
}

.premium-x__archive-button {
  width: 230px;
  margin: 0;
  font-size: 18px;
  line-height: 20px;
  display: inline-block;
}

.subscription-faq {
  background: #fff;
  border: 1px solid #d1d4e4;
  margin: 15px 0;
  padding: 15px;
}

.subscription-faq__headline {
  margin: 10px 0 30px;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.subscription-faq__question {
  border-bottom: 1px solid #d1d4e4;
  margin: 0 0 15px;
  padding: 0 15px 15px 0;
  font-size: 14px;
  line-height: 20px;
  list-style-type: none;
  position: relative;
}

.subscription-faq__question:after {
  content: " ";
  background: url("/images/reflex2016/source/svg/icon-premium-arrow-up.svg") no-repeat;
  width: 10px;
  height: 6px;
  font-size: 15px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 7px;
  right: 0;
  transform: rotate(180deg);
}

.subscription-faq__question:hover {
  cursor: pointer;
}

.subscription-faq__question.active .subscription-faq__answer {
  display: block;
}

.subscription-faq__question.active:after {
  transform: rotate(0);
}

.breadcrumbs-paid-zone {
  margin: -35px 0 20px;
}

#paid-zone {
  clear: both;
  color: #2c2f3b;
  box-sizing: border-box;
  background: #fff;
  width: 590px;
  margin: 0 auto 30px;
  padding: 15px;
  position: relative;
}

#paid-zone table {
  width: 100%;
}

#paid-zone a, #paid-zone .back-to-hp {
  color: #2c2f3b;
  text-decoration: underline;
}

#paid-zone a:hover, #paid-zone .back-to-hp:hover {
  text-decoration: none;
}

#paid-zone ul {
  padding: 0;
}

#paid-zone ul.bullet-list {
  margin: -5px 0 15px;
}

#paid-zone ul.bullet-list li {
  padding-left: 17px;
  line-height: 27px;
  list-style-type: none;
  position: relative;
}

#paid-zone ul.bullet-list li:before {
  content: " ";
  background: #d1d4e4;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
}

#paid-zone .align-center {
  text-align: center;
}

#paid-zone .error {
  color: red;
}

#paid-zone .success {
  color: #65c41e;
}

#paid-zone .grey-highlight {
  color: #aaa;
}

#paid-zone .table {
  border-top: 1px solid #d1d4e4;
  padding: 20px 0;
}

#paid-zone .table td {
  vertical-align: top;
  border: none;
  padding: 7px 0;
  line-height: 22px;
  position: relative;
}

#paid-zone .table label {
  margin: 0;
}

#paid-zone .user-bonuses + .table {
  border-top: none;
}

#paid-zone .profile-info .table + .table {
  padding-bottom: 4px;
}

#paid-zone .main-info {
  text-align: left;
  border: none;
  margin: 0;
  padding: 0;
}

#paid-zone .main-info h1 {
  color: red;
  text-align: left;
  font-family: arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
}

#paid-zone .main-info h1.align-center {
  text-align: left;
}

#paid-zone .main-info p {
  text-align: left;
  margin: 25px 0 15px;
  font-size: 14px;
  font-weight: bold;
}

#paid-zone .info-text {
  border-bottom: 1px solid #d1d4e4;
  margin-top: -10px;
  margin-bottom: 30px;
  padding-bottom: 25px;
  line-height: 18px;
}

#paid-zone .info-text p {
  font-size: 12px;
}

#paid-zone .prihlaseni-info {
  float: right;
  margin: 40px 0 30px;
}

#paid-zone .logout {
  color: #2c2f3b;
  text-align: right;
  text-decoration: underline;
  position: absolute;
  top: 20px;
  right: 15px;
}

#paid-zone .logout:hover {
  text-decoration: none;
}

#paid-zone .order-form .form-field {
  margin: 0;
}

#paid-zone .backlink {
  margin: 15px 0;
}

#paid-zone .backlink.tos-links {
  margin-bottom: 20px;
}

#paid-zone .backlink.tos-links a:not(.main-button) {
  color: #2c2f3b;
}

#paid-zone .backlink div + .link-basic {
  margin-top: 10px;
}

#paid-zone .backlink h3 {
  font-family: arial, sans-serif;
  font-size: 13px;
}

#paid-zone .backlink h3 a {
  color: #2c2f3b;
  font-weight: normal;
  text-decoration: underline;
}

#paid-zone .backlink h3 a:hover {
  text-decoration: none;
}

#paid-zone .main-button, #paid-zone .button-secondary {
  color: #fff;
  background: red;
  border: none;
  border-radius: 2px;
  min-width: auto;
  height: 34px;
  margin: 0 auto;
  padding: 0 15px;
  font-family: arial, Verdana, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 35px;
  text-decoration: none;
  display: inline-block;
}

#paid-zone .main-button:hover, #paid-zone .button-secondary:hover {
  color: #fff;
  text-decoration: underline;
}

#paid-zone .button-secondary {
  color: #2c2f3b;
  background: #d1d4e4;
}

#paid-zone .button-secondary:hover {
  background: #9aa0c4;
  transition: all .3s;
}

#paid-zone .main-button + .button-secondary {
  margin-left: 12px;
}

#paid-zone .subscription-sidenote {
  background: #fcf7db;
  margin: -5px 0 20px;
  padding: 10px 15px;
  font-size: 12px;
  line-height: 18px;
}

#paid-zone .subscription__type-order {
  color: #fff;
}

#paid-zone .well-content {
  margin: 0;
  padding: 0;
}

#paid-zone .well-content.info-text {
  border-top: 1px solid #d1d4e4;
  margin: 20px 0;
  padding: 20px 0;
}

#paid-zone #activation-activate .align-center.red {
  margin-bottom: 10px;
}

#paid-zone #activation-activate .default-form input.currency-input {
  text-align: center;
  height: 28px;
  line-height: 34px;
}

#paid-zone #activation-activate .default-form .prihlasit.main-button {
  height: 34px;
  line-height: 34px;
}

#paid-zone #activation-activate .well-content.info-text ol {
  margin: 10px 0 0 20px;
  list-style: decimal;
}

#paid-zone #activation-activate .well-content.info-text ol li {
  margin: 10px 0;
  font-size: 12px;
}

#paid-zone .subscription-notice {
  background: #eef4f7;
  width: 64%;
  margin: 25px 0 20px;
  line-height: 18px;
}

#paid-zone .subscription-notice p {
  padding: 10px;
}

#paid-zone .subscription__icon {
  height: 21px;
  margin: 0 0 0 6px;
  display: inline-block;
}

#paid-zone .subscription-faq, #paid-zone .subscription-faq-100 .subscription-faq {
  width: 570px;
}

#paid-zone .back-to-hp {
  margin-top: 15px;
  display: inline-block;
}

#paid-zone .dataimg-icon-premium-master-card {
  width: 26px;
}

#paid-zone .dataimg-icon-premium-visa {
  width: 29px;
}

#paid-zone .dataimg-icon-premium-gopay {
  width: 50px;
}

#paid-zone .dataimg-icon-premium-sms {
  width: 19px;
  position: relative;
  top: -1px;
}

#paid-zone #subscription #subscription-offer {
  width: 100%;
  margin: 0;
  padding: 0;
}

#paid-zone #subscription #subscription-offer ul.subscription__payment-options-bullets {
  margin-top: 0;
  position: relative;
  top: 3px;
}

#paid-zone #subscription #subscription-offer ul.subscription__payment-options-bullets li {
  margin: 0 0 0 10px;
  padding: 0 5px 0 0;
  display: flex;
}

#paid-zone #subscription #subscription-offer .subscription__icon:not(.dataimg-payment-sms) {
  margin: -2px 0 0;
}

#paid-zone #subscription #subscription-offer .subscription__types {
  flex-wrap: wrap;
  display: flex;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type {
  width: 245px;
  padding-bottom: 65px;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type p.subscription__type-note br {
  display: none;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type-order {
  width: 89%;
  position: absolute;
  bottom: 15px;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type-voucher {
  background: #f8f8f8;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type-voucher .subscription__type-title {
  margin-right: 20px;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type-voucher .subscription__type-order {
  width: 170px;
  margin-right: 50px;
  position: static;
}

#paid-zone .subscription__voucher .subscription__voucher-title {
  padding: 0 0 20px;
  font-size: 17px;
  line-height: 22px;
  display: block;
}

#paid-zone .subscription__voucher .subscription__voucher-preview {
  width: 100%;
  margin: 0 0 20px;
}

#paid-zone .subscription__voucher .order__success-info h2 {
  text-align: center;
  color: #1e1e1e;
  opacity: 1;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone .subscription__voucher .order__success-info p {
  color: #2c2f3b;
  font-size: 12px;
  line-height: 18px;
}

#paid-zone .subscription__voucher .order__success-howto {
  border-top: 1px solid #dcdcdc;
  padding-top: 20px;
}

#subscription-login .tos-links {
  width: 600px;
  margin: 0 auto 20px;
}

#activation-login #login-wrapper {
  margin: 30px 0 35px;
}

#blesk-login #login-wrapper .main-container .login-close {
  top: -45px;
  right: -15px;
}

#blesk-login #login-wrapper #paid-zone {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  position: static;
}

#blesk-login #login-wrapper #paid-zone .tos-links {
  width: 100%;
}

#order-subscription {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

#order-subscription .table .payment td {
  padding: 8px 0;
}

#order-subscription .table .payment .table-header {
  padding-bottom: 10px;
}

#order-subscription .table .payment-row td {
  padding-bottom: 2px;
}

#order-subscription .subscription__note {
  width: 48%;
}

#order-subscription .payment-total {
  width: 100%;
  margin: 10px 0 25px;
  font-size: 18px;
  line-height: 21px;
}

#order-subscription .agreement {
  border-top: 1px solid #d1d4e4;
  padding-top: 25px;
  line-height: 20px;
}

#order-subscription .agreement a {
  color: #2c2f3b;
  text-decoration: underline;
}

#order-subscription .agreement a:hover {
  text-decoration: none;
}

#order-subscription .agreement .with-error, #order-subscription .agreement .with-error a {
  color: red;
}

#order-subscription .agreement .error-message {
  color: red;
  margin-top: 5px;
  margin-left: 22px;
  font-weight: bold;
  display: block;
}

#order-subscription .logout {
  top: 6px;
  right: 0;
}

#form-subscribe {
  text-align: center;
}

form.default-form .discount-code {
  padding-bottom: 15px;
  font-size: 13px;
  line-height: 18px;
}

form.default-form .discount-code .discount-code__label {
  margin: 0;
  display: block;
}

form.default-form .discount-code .discount-code__input {
  color: #000001;
  border: 1px solid #d1d4e4;
  border-radius: 3px;
  outline: none;
  width: 180px;
  margin: 10px 5px 10px 0;
  padding: 5px;
  transition: all .3s ease-in-out;
  display: inline-block;
}

form.default-form .discount-code .discount-code__button {
  color: #0000ca;
  cursor: pointer;
  background: none;
  border: none;
  font-weight: 700;
  display: inline-block;
}

form.default-form .discount-code .discount-code__error {
  color: #e00a17;
}

form.default-form .price-note {
  font-size: 13px;
  line-height: 18px;
}

form.default-form .discount-code__price {
  color: #e00a17;
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}

.subscription__voucher .order-form .discount-code {
  display: none;
}

#order-subscription-sms {
  width: 100%;
  margin: 0 auto;
  line-height: 18px;
}

#order-subscription-sms h1 {
  margin-bottom: 25px;
}

#order-subscription-sms ul.bullet-list {
  margin-top: 8px;
}

#order-subscription-sms #steps {
  border-bottom: 1px solid #d1d4e4;
  margin: 12px 0 25px;
  padding: 0 0 25px;
}

#order-subscription-sms #steps .for-1:before {
  content: "1.";
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
}

#order-subscription-sms #steps .for-2:before {
  content: "2.";
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
}

#order-subscription-sms #steps .for-3:before {
  content: "3.";
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
}

#order-subscription-sms #steps li {
  margin: 25px 0;
  padding: 0 0 0 18px;
  list-style-type: none;
  position: relative;
}

#order-subscription-sms #steps li:first-child {
  margin-top: 0;
  padding-right: 10px;
}

#order-subscription-sms #steps li:last-child {
  margin-bottom: 0;
}

#order-subscription-sms #steps li form {
  margin-top: 10px;
  position: relative;
}

#order-subscription-sms #steps li form div {
  position: absolute;
}

#order-subscription-sms #steps li form span {
  color: #aaa;
  font-size: 13px;
  font-weight: 700;
  line-height: 0;
  display: block;
  position: absolute;
  top: 24px;
  left: 10px;
}

#order-subscription-sms #steps li .error-message {
  color: red;
  margin-top: 10px;
  font-weight: bold;
  position: relative;
}

#order-subscription-sms #steps li .sms_input {
  float: left;
  border: 1px solid #aaa;
  border-radius: 2px;
  width: 92px;
  height: 34px;
  margin: 5px 10px 0 0;
  padding: 0 0 0 44px;
  font-size: 13px;
}

#order-subscription-sms #steps li .sms_input.error {
  border-color: red;
}

#order-subscription-sms #steps li .main-button {
  height: 36px;
  margin: 5px 0 0;
  line-height: 36px;
}

#order-subscription-sms #additional-info h2 {
  margin-bottom: 18px;
  font-family: arial, sans-serif;
  font-size: 14px;
}

#order-subscription-sms #additional-info .subscription-sidenote {
  margin: 30px 0;
}

#activation-login.not-subscribed .main-info p {
  float: left;
  width: 75%;
  margin: 18px 0 30px;
  line-height: 21px;
}

#activation-login.not-subscribed .prihlaseni-info {
  margin: 21px 0 30px;
}

#activation-login.not-subscribed .subscription {
  clear: both;
}

#activation-login .recurring-date #recurring-info {
  color: red;
  cursor: pointer;
  padding: 0 2px;
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  position: relative;
}

#activation-login .recurring-date #recurring-info #recurring-info-popup {
  text-align: left;
  color: #2c2f3b;
  background: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 0;
  width: 160px;
  padding: 10px;
  font-size: 13px;
  font-weight: 300;
  line-height: 17px;
  display: none;
  position: absolute;
  top: 0;
  right: 10px;
}

#paid-zone .tos-links {
  width: 570px;
  margin-top: 25px;
}

#paid-zone #login-wrapper .main-container {
  box-shadow: none;
  text-align: center;
  width: 570px;
  margin: 0;
  padding: 25px;
  display: block;
}

#paid-zone #login-wrapper .main-container a {
  color: #fff;
  background: #ed1c24;
  padding: 12px 30px;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}

#paid-zone #login-wrapper .main-container a:hover {
  background: #a60c12;
  text-decoration: none;
}

#paid-zone #login-wrapper .main-container .login-tabs {
  width: 282px;
  left: -1px;
}

#paid-zone #login-wrapper .main-container .login-tabs a.active {
  border: 1px solid #ccc;
  border-bottom: 0;
}

#paid-zone #login-wrapper .social-networks .button-facebook {
  width: 245px;
}

#paid-zone #login-wrapper .form-row .info {
  width: 230px;
}

.cnc-modal {
  z-index: 99999;
  background-color: rgba(0, 0, 0, .4);
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
}

.cnc-modal-body {
  height: 690px;
}

.cnc-modal-open {
  overflow: hidden;
}

.cnc-modal-content {
  width: auto;
  max-width: 640px;
  margin: 30px auto;
  padding: 0;
  animation-name: animatetop;
  animation-duration: .4s;
  position: relative;
}

.cnc-modal-close {
  position: absolute;
  top: 20px;
  right: 45px;
}

.cnc-modal-close .dataimg-cross {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.cnc-modal-close .dataimg-cross svg {
  height: 20px;
}

.cnc-modal-close .dataimg-cross svg path {
  fill: #16212d;
}

@keyframes animatetop {
  from {
    opacity: 0;
    top: -300px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

@media (max-width: 340px) {
  .cnc-modal-close {
    right: 28px;
  }
}

@media (max-width: 430px) {
  .cnc-modal-close {
    right: 33px;
  }
}

.activation-steps {
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 10px auto;
  display: flex;
}

.activation-step {
  color: #2e2e2e;
  background: #ececec;
  width: calc(50% - 55px);
  padding: 9px 20px 8px;
  font-weight: 700;
  position: relative;
}

.activation-step:last-of-type {
  text-indent: 25px;
  max-width: calc(50% - 30px);
  left: -30px;
}

.activation-step:before {
  content: "";
  z-index: 1;
  border: 35px solid rgba(0, 0, 0, 0);
  border-left: 30px solid #ececec;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 100%;
}

.activation-step.active-step {
  color: red;
  background: #fafafa;
}

.activation-step.active-step:before {
  border-left: 30px solid #fafafa;
}

.activation-steps__step {
  font-size: 15px;
  line-height: 25px;
}

.activation-steps__info {
  font-size: 17px;
  line-height: 28px;
}

.trhak-premium {
  display: flex;
}

.trhak-premium__image {
  margin-right: 15px;
}

.trhak-activate .currency-input {
  width: 60%;
}

.trhak-activate .prihlasit.main-button {
  margin-top: 15px;
}

.trhak-activate .error-message {
  color: red;
  margin-top: 15px;
  display: block;
}

.trhak-activate #code2 {
  width: 35%;
}

.trhak-activate__image {
  margin-right: 25px;
}

.trhak.info-text {
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.trhak-headline {
  margin: -15px 0 15px;
  display: block;
}

@media screen and (max-width: 640px) {
  .trhak-premium {
    display: block;
  }

  .trhak-premium__image {
    float: left;
    width: 75px;
    margin-top: 10px;
  }

  .trhak-activate__image {
    width: 70px;
    margin-right: 10px;
  }

  .trhak-headline {
    margin-top: -30px;
    line-height: 18px;
  }

  #paid-zone #activation-activate .trhak-premium.info-text ol {
    padding: 0;
  }

  #paid-zone #activation-activate .trhak-activate #form-activate .currency-input {
    width: calc(100% - 12px);
    margin-bottom: 10px;
    padding: 5px;
    font-size: 12px;
  }
}

#paid-zone {
  font-size: 13px;
}

#paid-zone form.default-form .form-field input[type="radio"] + label:after {
  top: 3.75px;
  left: 3.2px;
}

#paid-zone form.default-form .form-field input[type="radio"] + label:before {
  border-width: 1px;
  border-color: #aaa;
  top: 1px;
}

#paid-zone form.default-form .form-field input[type="checkbox"] + label:before {
  border-width: 1px;
  border-color: #aaa;
}

#paid-zone .red {
  color: red;
}

#paid-zone .table {
  clear: both;
}

#paid-zone .main-button {
  line-height: 36px;
}

#paid-zone .subscription {
  box-sizing: border-box;
  width: 690px;
  margin: 0 auto 20px -15px;
  padding: 30px 0;
}

#paid-zone .subscription a {
  color: red;
}

#paid-zone .subscription .subscription__type-order {
  color: #fff;
  text-decoration: none;
}

#paid-zone .subscription .subscription__type-order--white {
  color: red;
  background-color: #fff;
}

#paid-zone .subscription .subscription__type-order--white:hover {
  color: #fff;
  background-color: red;
}

#paid-zone .tos-links {
  border-top: 1px solid #d1d4e4;
  margin: 30px 0 20px;
  padding: 30px 0 1px;
}

#paid-zone .tos-links .title {
  text-align: left;
  margin-bottom: 0;
  font-family: arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
}

#paid-zone .tos-links .title a {
  color: #2c2f3b;
  text-decoration: underline;
}

#paid-zone .tos-links .title a:hover {
  text-decoration: none;
}

#paid-zone .tos-links .link-basic {
  padding-left: 17px;
  position: relative;
}

#paid-zone .tos-links .link-basic:before {
  content: " ";
  background: #d1d4e4;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
}

.recentartlices-premium-celebrity {
  z-index: 10;
  width: 640px;
  margin-top: 40px;
  margin-left: -40px;
  padding: 0 40px 25px;
  position: relative;
}

.recentartlices-premium-celebrity .title-basic {
  margin-top: 0;
}

.recentartlices-premium-celebrity a {
  text-decoration: none !important;
}

.recentartlices-premium-celebrity .artPaid {
  z-index: 1;
  width: 97px;
  height: 21px;
  position: absolute;
  left: 0;
}

.recentartlices-premium-celebrity .recent-articles__next-articles {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.recentartlices-premium-celebrity .next-articles {
  text-align: center;
  color: #fff;
  background: red;
  border-radius: 2px;
  width: 214px;
  height: 30px;
  padding: 12px 26px 10px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 40px;
  display: inline;
  text-decoration: none !important;
}

#recentarticles-premium-pane .recentartlices-premium-celebrity {
  margin-left: -50px;
}

.subscription-faq {
  background: #fff;
  width: 570px;
  margin: 20px auto;
  padding: 15px;
}

.subscription-faq__headline {
  margin: 10px 0 30px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.subscription-faq__questions {
  padding: 0;
}

.subscription-faq__answer {
  margin-top: 0;
  display: none;
}

.subscription-faq__answer:hover {
  cursor: default;
}

.subscription-faq__answer p {
  margin: 15px 0 0;
}

.subscription-faq__contact {
  text-align: center;
  font-size: 13px;
  line-height: 20px;
}

.subscription-faq__question, .subscription-types-switcher {
  border-bottom: 1px solid #d1d4e4;
  margin: 0 0 12px;
  padding: 0 15px 12px 0;
  font-size: 13px;
  line-height: 20px;
  list-style-type: none;
  position: relative;
}

.subscription-faq__question:after, .subscription-types-switcher:after {
  content: " ";
  background: url("/images/reflex2016/source/svg/icon-premium-arrow-up.svg") no-repeat;
  width: 10px;
  height: 6px;
  font-size: 15px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 7px;
  right: 0;
  transform: rotate(180deg);
}

.subscription-faq__question.active .subscription-faq__answer, .subscription-types-switcher.active .subscription-faq__answer {
  display: block;
}

.subscription-faq__question.active:after, .subscription-types-switcher.active:after {
  transform: rotate(0);
}

.subscription-faq__question strong, .subscription-types-switcher strong {
  display: block;
}

.subscription-faq__question strong:hover, .subscription-types-switcher strong:hover {
  cursor: pointer;
}

.subscription-types-switcher {
  border-bottom: 0;
  font-size: 13px;
}

.subscription-types-switcher:after {
  filter: invert(15%) sepia(82%) saturate(5998%) hue-rotate(358deg) brightness(105%) contrast(115%);
  top: 5px;
}

.subscription-types-switcher__holder {
  text-align: center;
  margin-top: 5px;
}

.subscription-types-switcher__holder ~ div, .subscription-types-switcher__holder ~ ul {
  display: none;
}

.subscription-types-switcher__holder.active ~ div, .subscription-types-switcher__holder.active ~ ul {
  display: block;
}

.subscription-types-switcher__holder strong:before {
  content: "Zobrazit";
}

.subscription-types-switcher__holder.active strong:before {
  content: "Skrýt";
}

.subscription-types-switcher__holder.active .subscription-types-switcher:after {
  transform: rotate(0);
}

@media only screen and (max-width: 480px) {
  .subscription-faq__contact a {
    text-decoration: underline;
  }

  .subscription-faq__contact a:hover, .subscription-faq__contact a:active {
    text-decoration: none;
  }
}

#blesk-premium-corporate {
  width: 620px;
  margin: 0 auto 30px;
  padding: 30px 0;
  font-family: arial, sans-serif;
}

#blesk-premium-corporate .header {
  text-align: center;
}

#blesk-premium-corporate .header .svg-title svg {
  width: auto;
  height: 45px;
}

#blesk-premium-corporate .header .headline, #blesk-premium-corporate .header .subtitle {
  text-align: center;
  float: left;
  width: 100%;
}

#blesk-premium-corporate .header .headline {
  margin-top: 20px;
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
}

#blesk-premium-corporate .header .subtitle {
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;
  line-height: 17px;
}

#blesk-premium-corporate .body .ttFormCorporateSubscription {
  border-top: 1px solid #cad5df;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 15px;
}

#blesk-premium-corporate .body .ttFormCorporateSubscription .subscription-success-message {
  text-align: center;
  z-index: 1000;
  color: red;
  background: #fff;
  border: 1px solid red;
  border-radius: 5px;
  width: 620px;
  max-width: 90%;
  padding: 15px 0;
  font-size: 17px;
  display: block;
  position: fixed;
  top: 50%;
  box-shadow: 0 7px 24px rgba(0, 0, 0, .5);
}

#blesk-premium-corporate .body .form-field label, #blesk-premium-corporate .body .form-field .error-message {
  width: 100%;
  margin-top: 5px;
  font-size: 12px;
  line-height: 20px;
  display: block;
}

#blesk-premium-corporate .body .form-field .error-message {
  color: red;
  text-align: left;
  font-size: 10px;
  font-weight: bold;
  display: none;
}

#blesk-premium-corporate .body .form-field.with-error .error-message {
  display: block;
}

#blesk-premium-corporate .body .subscription-type {
  float: left;
  cursor: pointer;
  background-color: #fff;
  width: 190px;
  height: 110px;
  margin-right: 25px;
  display: block;
}

#blesk-premium-corporate .body .subscription-type.selected {
  border: 1px solid red;
  width: 188px;
  height: 108px;
}

#blesk-premium-corporate .body .subscription-type:last-child {
  margin-right: 0;
}

#blesk-premium-corporate .body .subscription-type .main {
  margin: 10px;
  position: relative;
}

#blesk-premium-corporate .body .subscription-type .main input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

#blesk-premium-corporate .body .subscription-type .main label {
  float: right;
  width: 80%;
  max-height: 17px;
  margin: 7px 0 0;
  font-size: 15px;
  font-weight: bold;
  line-height: 30px;
}

#blesk-premium-corporate .body .subscription-type .main .price {
  color: red;
  float: right;
  width: 80%;
  margin-top: 15px;
  font-size: 30px;
  line-height: 30px;
}

#blesk-premium-corporate .body .subscription-type .main .price .price__currency {
  font-size: 20px;
}

#blesk-premium-corporate .body .subscription-type .main .dph {
  float: right;
  width: 80%;
  font-size: 13px;
  line-height: 20px;
}

#blesk-premium-corporate .body .subscription-type .customRadio .checkmark {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 11px;
  left: 7px;
}

#blesk-premium-corporate .body .subscription-type .customRadio .checkmark:after {
  content: "";
  background: #000;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: none;
  position: absolute;
  top: 2px;
  left: 2px;
}

#blesk-premium-corporate .body .subscription-type .customRadio input:checked ~ .checkmark:after {
  display: block;
}

#blesk-premium-corporate .body input {
  color: #000;
  border: 1px solid #aaa;
  border-radius: 2px;
  padding: 12px 10px 11px;
  font-family: arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
}

#blesk-premium-corporate .body input::placeholder {
  color: #aaa;
  opacity: 1;
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 15px;
}

#blesk-premium-corporate .body .email label {
  margin-top: 15px;
}

#blesk-premium-corporate .body .email input {
  width: 292px;
  margin-top: 10px;
}

#blesk-premium-corporate .body .message {
  margin: 25px 0;
}

#blesk-premium-corporate .body .message #message {
  color: #000;
  border: 1px solid #aaa;
  border-radius: 2px;
  width: 598px;
  height: 117px;
  min-height: 117px;
  margin-top: 10px;
  padding: 13px 11px 12px;
  font-family: arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
}

#blesk-premium-corporate .body .message #message::placeholder {
  color: #aaa;
  opacity: 1;
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 15px;
}

#blesk-premium-corporate .body .captcha label {
  margin-bottom: 6px;
}

#blesk-premium-corporate .body .captcha input {
  width: 122px;
}

#blesk-premium-corporate .body .captcha .captcha-sub-row .submit-mob-clear {
  clear: initial;
}

#blesk-premium-corporate .body .captcha .captcha-sub-row div.captcha-img {
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 2px;
  max-height: 40px;
  margin-right: 15px;
  padding: 4px;
}

#blesk-premium-corporate .body .captcha .captcha-sub-row input.submit {
  float: right;
  color: #fff;
  background-color: red;
  border: 0;
  border-radius: 2px;
  width: 190px;
  max-height: 40px;
  padding: 12px 0;
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
}

#blesk-premium-corporate .body .corporate-order {
  text-align: center;
  border-bottom: 1px solid #cad5df;
  padding-top: 15px;
  padding-bottom: 12px;
  font-size: 12px;
  line-height: 20px;
}

.paid-zone__corporate-activation #paid-zone {
  box-sizing: border-box;
  width: 600px;
}

.paid-zone__corporate-activation #paid-zone .content {
  background-color: #fff;
}

.paid-zone__corporate-activation #paid-zone .content .sub-wrapper .main-info p {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
}

.paid-zone__corporate-activation #paid-zone .content .sub-wrapper #subscription-login {
  margin-bottom: -15px;
  margin-left: -15px;
}

.paid-zone__corporate-activation #paid-zone a.main-button {
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 38px;
}

.paid-zone__corporate-activation #paid-zone p {
  font-size: 14px;
  line-height: 1.6;
}

.paid-zone__corporate-activation #paid-zone header.main-info {
  text-align: center;
  background-color: #fff;
  padding-bottom: 0;
}

.paid-zone__corporate-activation #paid-zone header.main-info h1 {
  color: #1e1e1e;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 10px;
  padding-bottom: 15px;
  font-family: arial, sans-serif;
  font-size: 25px;
  line-height: 32px;
}

.paid-zone__corporate-activation #paid-zone header.main-info h1.align-center {
  text-align: center;
}

.paid-zone__corporate-activation #paid-zone .info-text {
  background: #fff;
  border: 0;
  margin: 15px 0 0;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.paid-zone__corporate-activation #paid-zone .info-text p {
  margin: 0 0 15px;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 1.6;
}

.paid-zone__corporate-activation #paid-zone .info-text ol {
  margin: 15px 0;
  padding: 0 0 0 15px;
  list-style: decimal;
}

.paid-zone__corporate-activation #paid-zone .info-text ol li {
  margin: 0 0 20px;
  line-height: 1.6;
}

.paid-zone__corporate-activation #paid-zone #login-wrapper {
  background: #e9eff4;
  width: 600px;
  padding: 30px 0 0;
}

.paid-zone__corporate-activation #paid-zone #login-wrapper .main-container {
  margin-bottom: -5px;
}

.paid-zone__corporate-activation #paid-zone #form-activate {
  margin-top: 18px;
  padding: 0 0 20px;
}

.paid-zone__corporate-activation #paid-zone #form-activate .form-field {
  width: 312px;
  margin: 0 auto 20px;
}

.paid-zone__corporate-activation #paid-zone #form-activate .form-field input[type="text"] {
  text-align: center;
  box-sizing: border-box;
  border: 1px solid red;
  width: 200px;
  height: 42px;
  padding: 0 10px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  line-height: 42px;
  display: inline-block;
}

.paid-zone__corporate-activation #paid-zone #form-activate .form-field input[type="submit"] {
  color: #fff;
  float: right;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  width: 113px;
  height: 42px;
  max-height: 40px;
  margin: 0 0 5px -5px;
  padding: 0 0 2px;
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
}

.paid-zone__corporate-activation #paid-zone #form-activate .form-field .error-message {
  float: left;
}

.paid-zone__corporate-activation .paid-zone__faq {
  box-sizing: border-box;
  width: 600px;
  margin: 40px auto;
}

.subscription--article {
  box-sizing: border-box;
  color: #fff;
  text-align: left;
  background-image: linear-gradient(111deg, #1f1e26, #27272e);
  width: 540px;
  padding: 48px 50px;
  position: relative;
}

@media (max-width: 750px) {
  .subscription--article {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.subscription--article.fade-out:before {
  content: " ";
  background: linear-gradient(rgba(233, 239, 244, 0) 0 2%, #e9eff4 70%, #e9eff4);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
  left: 0;
}

.subscription--article * {
  box-sizing: border-box;
}

.subscription--article .subscription__logo {
  width: 100%;
  height: auto;
  display: flex;
}

.subscription--article .subscription__logo svg, .subscription--article .subscription__logo img {
  max-height: 16px;
}

.subscription--article .subscription__logo svg:first-child, .subscription--article .subscription__logo img:first-child {
  border-right: 1px solid #646464;
  margin-right: 22px;
  padding-right: 22px;
}

.subscription--article .subscription__logo svg:last-child, .subscription--article .subscription__logo img:last-child {
  opacity: .6;
}

.subscription--article .subscription__headline {
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  margin-top: 26px;
  margin-bottom: 6px;
  margin-left: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.subscription--article .subscription__description {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.subscription--article .subscription__button-wrapper {
  color: #fff;
}

.subscription--article .subscription__button-wrapper span, .subscription--article .subscription__button-wrapper .subscription__button--link {
  font-size: 12px;
}

.subscription--article .subscription__button-wrapper .subscription__type-order {
  margin-right: 15px;
  display: inline-block;
}

.subscription--article .subscription__type-order {
  text-align: center;
  color: #fff;
  background-color: #f66533;
  border: none;
  border-radius: 0;
  width: 190px;
  height: 45px;
  margin: 20px 0 0;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 45px;
  display: block;
  position: static;
}

.subscription--article .subscription__type-order:hover {
  color: #fff;
}

.subscription--article .subscription__button--link {
  color: #fff;
  text-decoration: underline;
}

.subscription--article .subscription__button--link:hover {
  color: #fff;
  text-decoration: none;
}

#article .article-body .subscription--article, #article.listed .subscription--article {
  box-sizing: border-box;
  color: #fff;
  text-align: left;
  background-image: linear-gradient(111deg, #1f1e26, #27272e);
  width: 540px;
  padding: 48px 50px;
  position: relative;
}

@media (max-width: 750px) {
  #article .article-body .subscription--article, #article.listed .subscription--article {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

#article .article-body .subscription--article.fade-out:before, #article.listed .subscription--article.fade-out:before {
  content: " ";
  background: linear-gradient(rgba(233, 239, 244, 0) 0 2%, #e9eff4 70%, #e9eff4);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
  left: 0;
}

#article .article-body .subscription--article *, #article.listed .subscription--article * {
  box-sizing: border-box;
}

#article .article-body .subscription--article .subscription__logo, #article.listed .subscription--article .subscription__logo {
  width: 100%;
  height: auto;
  display: flex;
}

#article .article-body .subscription--article .subscription__logo svg, #article .article-body .subscription--article .subscription__logo img, #article.listed .subscription--article .subscription__logo svg, #article.listed .subscription--article .subscription__logo img {
  max-height: 16px;
}

#article .article-body .subscription--article .subscription__logo svg:first-child, #article .article-body .subscription--article .subscription__logo img:first-child, #article.listed .subscription--article .subscription__logo svg:first-child, #article.listed .subscription--article .subscription__logo img:first-child {
  border-right: 1px solid #646464;
  margin-right: 22px;
  padding-right: 22px;
}

#article .article-body .subscription--article .subscription__logo svg:last-child, #article .article-body .subscription--article .subscription__logo img:last-child, #article.listed .subscription--article .subscription__logo svg:last-child, #article.listed .subscription--article .subscription__logo img:last-child {
  opacity: .6;
}

#article .article-body .subscription--article .subscription__headline, #article.listed .subscription--article .subscription__headline {
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  margin-top: 26px;
  margin-bottom: 6px;
  margin-left: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

#article .article-body .subscription--article .subscription__description, #article.listed .subscription--article .subscription__description {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

#article .article-body .subscription--article .subscription__button-wrapper, #article.listed .subscription--article .subscription__button-wrapper {
  color: #fff;
}

#article .article-body .subscription--article .subscription__button-wrapper span, #article .article-body .subscription--article .subscription__button-wrapper .subscription__button--link, #article.listed .subscription--article .subscription__button-wrapper span, #article.listed .subscription--article .subscription__button-wrapper .subscription__button--link {
  font-size: 12px;
}

#article .article-body .subscription--article .subscription__button-wrapper .subscription__type-order, #article.listed .subscription--article .subscription__button-wrapper .subscription__type-order {
  margin-right: 15px;
  display: inline-block;
}

#article .article-body .subscription--article .subscription__type-order, #article.listed .subscription--article .subscription__type-order {
  text-align: center;
  color: #fff;
  background-color: #f66533;
  border: none;
  border-radius: 0;
  width: 190px;
  height: 45px;
  margin: 20px 0 0;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 45px;
  display: block;
  position: static;
}

#article .article-body .subscription--article .subscription__type-order:hover, #article.listed .subscription--article .subscription__type-order:hover {
  color: #fff;
}

#article .article-body .subscription--article .subscription__button--link, #article.listed .subscription--article .subscription__button--link {
  color: #fff;
  text-decoration: underline;
}

#article .article-body .subscription--article .subscription__button--link:hover, #article.listed .subscription--article .subscription__button--link:hover {
  color: #fff;
  text-decoration: none;
}

#article.listed .subscription--article {
  width: 100%;
}

#paid-zone hr {
  border: 0;
  border-top: 1px solid #d1d4e4;
  height: 0 !important;
}

#paid-zone label {
  display: initial;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

#paid-zone input[type="checkbox"] {
  display: initial;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

#paid-zone input {
  font-family: Arial, sans-serif;
}

#paid-zone input[type="checkbox"] {
  margin-right: 10px;
}

#paid-zone strong {
  font-family: Arial, sans-serif;
  font-weight: bold;
}

#paid-zone h3 {
  margin: 0;
}

#paid-zone .main-button {
  text-transform: uppercase;
  cursor: pointer;
  height: 40px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
}

#paid-zone #activation-activate .default-form input#code {
  text-align: center;
  border: 1px solid #d1d4e4;
  height: 22px;
  padding: 5px 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

#paid-zone .main-info h1, #paid-zone .main-info p {
  font-family: Arial, sans-serif;
  font-weight: bold;
}

#paid-zone .tos-links .title {
  font-family: Arial, sans-serif;
}

#paid-zone .tos-links .link-basic:before {
  top: 6px;
}

#paid-zone .logout {
  top: 0;
  right: 0;
}

#paid-zone .subscription-faq__headline {
  margin-bottom: 25px;
}

#paid-zone .backlink h3 {
  font-family: Arial, sans-serif;
}

#paid-zone .subscription-bpz + .backlink {
  margin-top: 50px;
}

#paid-zone #activation-login {
  position: relative;
}

#paid-zone .subscription__voucher .subscription-notice {
  display: none;
}

#paid-zone .subscription__voucher .order__success-title {
  color: red;
  text-align: center;
  border: 0;
  outline: 0;
  margin: 0 40px 20px;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
}

#paid-zone .subscription__voucher .order__success-title h1 {
  font-size: 18px;
  line-height: 21px;
}

#paid-zone .subscription__voucher .order__success-voucher {
  margin: 0 0 30px;
}

#paid-zone .subscription__voucher .order__success-voucher .order__success-generated {
  width: 100%;
}

#paid-zone .subscription__voucher .order__success-download {
  margin: 30px 0;
}

#paid-zone .subscription__voucher .order__success-pdf {
  color: #fff;
  text-align: center;
  background: red;
  width: 260px;
  margin: 0 auto;
  padding: 11px 15px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

#paid-zone .subscription__voucher .dataimg-download-icon {
  float: left;
  width: 21px;
  height: 18px;
  margin-right: 5px;
}

#paid-zone .subscription__voucher .order__success-info {
  margin: 30px auto;
}

#paid-zone .subscription__voucher .order__success-info h2 {
  text-align: center;
  color: #1e1e1e;
  opacity: 1;
  margin-bottom: 20px;
  font: 700 15px / 18px Open Sans, sans-serif;
}

#paid-zone .subscription__voucher .order__success-info p {
  color: #2c2f3b;
  font: 12px / 18px Open Sans, sans-serif;
}

#paid-zone .subscription__voucher .order__success-howto {
  border-top: 1px solid #dcdcdc;
  padding-top: 20px;
  font-family: Open Sans, sans-serif;
}

#paid-zone .subscription__voucher .order__success-howto h2 {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone .subscription__voucher .order__success-howto ul {
  list-style: none;
}

#paid-zone .subscription__voucher .order__success-howto ul li {
  margin: 5px 0;
  font-size: 13px;
  line-height: 20px;
}

#paid-zone .subscription__voucher .order__success-contact {
  border-top: 1px solid #dcdcdc;
  margin-top: 20px;
  padding-top: 20px;
  font-size: 12px;
  line-height: 18px;
}

#blesk-login #login-wrapper h1, #blesk-login #login-wrapper h2 {
  color: red;
}

#blesk-login #login-wrapper .login-button {
  background: red;
}

#blesk-login #terms-and-conditions-form {
  overflow: hidden;
}

#blesk-login #terms-and-conditions-form a {
  color: red;
}

#blesk-login #terms-and-conditions-form .login-button {
  float: left;
  color: #fff;
  margin-right: 10px;
}

#paid-zone #login-wrapper .form-row .required:after {
  font-size: 12px;
}

#login-wrapper.horoskopy h1 {
  font-weight: initial;
  margin: initial;
}

#order-subscription .table .payment input {
  margin: -2px 10px 0 0;
}

#order-subscription-sms #additional-info h2 {
  font-family: Arial, sans-serif;
  font-weight: bold;
}

#order-subscription-sms #steps li form span {
  top: 23px;
}

#order-subscription-sms #steps li .sms_input {
  width: 86px;
  padding: 0 0 0 45px;
}

#article .wrapper .subscription--article.fade-out:before {
  background: linear-gradient(rgba(255, 255, 255, 0) 0 2%, #e5edf2 70%, #e5edf2);
}

@font-face {
  font-family: tablet_gothic_condensed;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.woff") format("woff"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.ttf?v=3") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot");
  src: local(Roboto Bold), local(Roboto-Bold), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff") format("woff"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.ttf") format("truetype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.svg#Roboto") format("svg");
}

.subcategory-menu {
  color: #1e1e1e;
  border-bottom: 1px solid #cdcdcd;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin: 0 0 30px;
  padding: 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  display: flex;
}

.subcategory-menu__logo-link {
  text-transform: uppercase;
  color: inherit;
  font-family: tablet_gothic_condensed, Times New Roman, serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 67px;
  text-decoration: none;
  display: flex;
}

.subcategory-menu__logo-image {
  margin-bottom: 10px;
}

.subcategory-menu__list {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.subcategory-menu__item {
  padding: 15px 14px 14px;
  position: relative;
}

.subcategory-menu__item:last-child {
  margin-right: 0;
}

.subcategory-menu__item--hidden {
  display: none;
}

.subcategory-menu__item--with-submenu {
  cursor: pointer;
  padding-right: 23px;
  position: relative;
}

.subcategory-menu__item--with-submenu:hover {
  background-color: #eee;
}

.subcategory-menu__item--with-submenu:hover:after {
  transition: transform .3s linear;
  transform: translateY(-50%)scaleY(-1);
}

.subcategory-menu__item--with-submenu:after {
  content: "";
  background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/arrow-down-dark-grey.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform .3s linear;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%)scaleY(1);
}

.subcategory-menu__link {
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
  display: block;
}

.subcategory-menu__link:hover {
  text-decoration: underline;
}

.subcategory-menu__submenu {
  z-index: 21;
  background-color: #eee;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
}

.subcategory-menu__submenu[data-side="left"] {
  left: 0;
  right: auto;
}

.subcategory-menu .subcategory-menu__item--with-submenu:hover .subcategory-menu__submenu {
  display: block;
}

.subcategory-menu__submenu-item--hidden {
  display: none;
}

.subcategory-menu__submenu-link {
  border-bottom: 1px solid #dcdcdc;
  padding: 8px 5px 6px;
}

.subcategory-menu__submenu-item:last-child .subcategory-menu__submenu-link {
  border-bottom: none;
}

.subcategory-menu {
  border-bottom: none;
  margin-bottom: 0;
}

.blesk-sticky-header .blesk-header-nav__secondary-logo img {
  width: 125px;
  height: 32px;
}

.clear {
  clear: both;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.block-center {
  margin-left: auto;
  margin-right: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.visually-hidden {
  clip: rect(1px 1px 1px 1px) !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  position: absolute !important;
  overflow: hidden !important;
}

@font-face {
  font-family: tablet_gothic_condensed;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.woff") format("woff"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.ttf?v=3") format("truetype");
}

.blesk-logo {
  background-color: #ed1c24;
  justify-content: center;
  align-items: center;
  display: flex;
}

.blesk-logo__image {
  display: block;
}

@keyframes show-navigation {
  from {
    opacity: 0;
    transform: translateY(-300px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hide-navigation {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.blesk-full-nav {
  z-index: 10002;
  color: #fff;
  opacity: 0;
  background-color: rgba(0, 0, 0, .95);
  width: 100%;
  height: 100%;
  padding: 80px 0 30px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

@media (max-width: 1099px) {
  .blesk-full-nav {
    padding-top: 50px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav {
    padding-top: 20px;
  }
}

.blesk-full-nav, .blesk-full-nav *, .blesk-full-nav:before, .blesk-full-nav:after, .blesk-full-nav :before, .blesk-full-nav :after {
  box-sizing: border-box;
}

.blesk-full-nav--show {
  opacity: 1;
  animation: .2s ease-in-out show-navigation;
  display: block;
}

.blesk-full-nav--hide {
  opacity: 0;
  animation: .2s ease-in-out hide-navigation;
}

.blesk-full-nav a {
  color: inherit;
}

.blesk-full-nav a:focus {
  outline: none;
}

.blesk-full-nav__container {
  width: 960px;
  margin: 0 auto;
  padding: 0 10px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__container {
    width: 768px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__container {
    width: 100%;
    max-width: 480px;
  }
}

.blesk-full-nav__input {
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  padding: 0 15px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.blesk-full-nav__input:focus {
  outline: none;
}

.blesk-full-nav__input--search {
  width: 550px;
  height: 48px;
  padding-right: 40px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__input--search {
    width: 100%;
  }
}

.blesk-full-nav__link-with-icon {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
  display: flex;
}

.blesk-full-nav__link-with-icon:hover {
  text-decoration: underline;
}

.blesk-full-nav__link-with-icon:last-child {
  margin-bottom: 0;
}

@media (max-width: 1099px) {
  .blesk-full-nav__link-with-icon {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

@media (max-width: 575px) {
  .blesk-full-nav__link-with-icon {
    font-size: 15px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__link-with-icon img {
    margin-left: 15px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__link-with-icon img {
    margin-right: 10px;
  }
}

.blesk-full-nav__header {
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__header {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__header {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}

.blesk-full-nav__logo {
  width: 136px;
  height: 48px;
}

.blesk-full-nav__logo:focus {
  outline: none;
}

.blesk-full-nav__search {
  margin: auto auto auto 60px;
  position: relative;
}

@media (max-width: 1099px) {
  .blesk-full-nav__search {
    flex: 1;
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__search {
    order: 1;
    min-width: 100%;
    margin: 20px 0 0;
  }
}

.blesk-full-nav__search-icon {
  opacity: .65;
  cursor: pointer;
  background: none;
  border: none;
  width: 18px;
  height: 18px;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.blesk-full-nav__search-icon:focus {
  outline: none;
}

.blesk-full-nav__close-button {
  cursor: pointer;
  will-change: transform;
  will-change: opacity;
  background: none;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0;
  transition: transform .2s ease-in-out;
  display: flex;
}

.blesk-full-nav__close-button:hover {
  opacity: .8;
}

.blesk-full-nav__close-button:active {
  opacity: .6;
}

.blesk-full-nav__close-button:hover {
  transform: rotate(90deg);
}

.blesk-full-nav__close-button:focus {
  outline: none;
}

.blesk-full-nav__close-button svg {
  width: 25px;
  height: 25px;
}

.blesk-full-nav__close-button path {
  fill: #fff;
}

.blesk-full-nav__main {
  align-items: flex-start;
  margin-bottom: 40px;
  display: flex;
}

@media (min-width: 1100px) {
  .blesk-full-nav__main {
    float: left;
    margin-right: 35px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__main {
    order: 1;
  }
}

@media (min-width: 768px) {
  .blesk-full-nav__main {
    width: 745px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__main {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .blesk-full-nav__list-section {
    min-width: 160px;
    margin-right: 35px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__list-section {
    width: 100%;
    margin-bottom: 30px;
  }
}

.blesk-full-nav__list-section:last-child {
  margin-right: 0;
}

@media (max-width: 767px) {
  .blesk-full-nav__list-section:last-child {
    margin-bottom: 0;
  }
}

.blesk-full-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 767px) {
  .blesk-full-nav__list {
    flex-wrap: wrap;
    display: flex;
  }
}

.blesk-full-nav__lists-group {
  flex-wrap: wrap;
  display: flex;
}

@media (min-width: 768px) {
  .blesk-full-nav__lists-group {
    min-width: 355px;
    margin-bottom: -35px;
    margin-right: 35px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__lists-group {
    flex-direction: column;
  }
}

.blesk-full-nav__lists-group .blesk-full-nav__list-section {
  margin-bottom: 35px;
}

.blesk-full-nav__lists-group .blesk-full-nav__list-section:nth-child(2n) {
  margin-right: 0;
}

@media (max-width: 767px) {
  .blesk-full-nav__lists-group .blesk-full-nav__list-section {
    margin-bottom: 30px;
  }
}

.blesk-full-nav__list-title {
  color: #fff;
  border-bottom: 2px solid #ed1c24;
  margin: 0 0 15px;
  padding: 0 0 5px;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
}

.blesk-full-nav__list-title a {
  text-decoration: none;
  color: inherit !important;
}

.blesk-full-nav__list-title a:hover {
  text-decoration: underline;
}

.blesk-full-nav__list-item {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .blesk-full-nav__list-item {
    width: 50%;
    margin-bottom: 15px;
  }
}

.blesk-full-nav__list-item:last-child {
  margin-bottom: 0;
}

.blesk-full-nav__list-link {
  text-decoration: none;
}

.blesk-full-nav__list-link:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .blesk-full-nav__list-link {
    font-size: 15px;
  }
}

.blesk-full-nav__sidebar--projects {
  clear: right;
  margin-bottom: 30px;
  padding-top: 0;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar--projects {
    margin-bottom: 10px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar {
    float: right;
    width: 160px;
    padding-top: 25px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
  }
}

.blesk-full-nav__sidebar--specials {
  margin-bottom: 40px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar--specials {
    margin-bottom: 30px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar-group {
    margin-bottom: 40px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-group {
    display: flex;
  }
}

.blesk-full-nav__sidebar-group:last-child {
  margin-bottom: 0;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-group--other {
    margin-bottom: 15px;
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__sidebar-group--other {
    margin-right: 0;
  }
}

.blesk-full-nav__sidebar-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-list {
    flex-wrap: wrap;
    display: flex;
  }

  .blesk-full-nav__sidebar-list--projects {
    margin-right: -30px;
  }
}

@media (max-width: 575px) {
  .blesk-full-nav__sidebar-list--projects {
    margin-right: 0;
  }
}

.blesk-full-nav__premium-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img2.cncenter.cz/images/default/svg/premium-sticker.svg?v=2");
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 20px;
  padding-top: 5px;
  font-size: 0;
  display: flex;
  position: static;
}

@media (min-width: 1100px) {
  .blesk-full-nav__premium-link {
    margin-bottom: 20px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__premium-link {
    margin-right: 30px;
  }
}

.blesk-full-nav__stardance-tipovacka-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img.cncenter.cz/img/1/origin/9202230_v0.jpg?v=0");
  background-repeat: no-repeat;
  background-size: 108px 18px;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 18px;
  padding-top: 5px;
  font-size: 0;
  display: flex;
  position: static;
  box-shadow: 0 0 2px rgba(245, 239, 220, .2), 0 0 6px rgba(252, 244, 219, .6);
}

@media (min-width: 1100px) {
  .blesk-full-nav__stardance-tipovacka-link {
    margin-bottom: 20px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__stardance-tipovacka-link {
    margin-right: 30px;
  }
}

.blesk-full-nav__ikiosek-link, .blesk-full-nav__bazar-link {
  justify-content: flex-start;
  text-decoration: none;
  position: relative;
}

.blesk-full-nav__ikiosek-link:hover, .blesk-full-nav__bazar-link:hover {
  text-decoration: underline;
}

@media (max-width: 1099px) {
  .blesk-full-nav__ikiosek-link, .blesk-full-nav__bazar-link {
    width: auto;
  }
}

.blesk-full-nav__bazar-link {
  margin-top: 20px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__bazar-link {
    margin-top: 0;
    margin-left: 20px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar-specials-item {
    margin-bottom: 10px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-specials-item {
    margin-right: 10px;
  }

  .blesk-full-nav__sidebar-specials-item:last-child {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__sidebar-specials-item {
    margin-bottom: 10px;
  }
}

.blesk-full-nav__sidebar-specials-item:last-child {
  margin-bottom: 0;
}

.blesk-full-nav__sidebar-specials-link {
  background-color: #fff;
  border-radius: 2px;
  padding: 0 15px;
  font-weight: 700;
  line-height: 32px;
  text-decoration: none;
  display: inline-block;
  color: #1e1e1e !important;
}

.blesk-full-nav__sidebar-specials-link:hover {
  text-decoration: underline;
}

.blesk-full-nav__sidebar-projects-item {
  margin-bottom: 20px;
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar-projects-item:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-projects-item {
    align-items: center;
    width: 25%;
    padding-right: 20px;
    display: flex;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__sidebar-projects-item {
    width: calc(50% - 10px);
    padding-right: 0;
  }

  .blesk-full-nav__sidebar-projects-item:nth-child(odd) {
    margin-right: 20px;
  }
}

.blesk-full-nav__footer {
  border-top: 1px solid rgba(255, 255, 255, .5);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
}

@media (min-width: 1100px) {
  .blesk-full-nav__footer {
    margin-top: 30px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__footer {
    flex-direction: column;
    order: 1;
  }
}

@media (max-width: 575px) {
  .blesk-full-nav__footer {
    flex-flow: column wrap;
    align-items: center;
  }
}

.blesk-full-nav__socials {
  align-items: center;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__socials {
    order: 2;
    margin-bottom: 32px;
  }
}

.blesk-full-nav__socials-list {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.blesk-full-nav__socials-item {
  margin-right: 15px;
}

.blesk-full-nav__socials-item:last-child {
  margin-right: 0;
}

.blesk-full-nav__socials-link {
  will-change: opacity;
  justify-content: center;
  align-items: center;
  display: flex;
}

.blesk-full-nav__socials-link:hover {
  opacity: .8;
}

.blesk-full-nav__socials-link:active {
  opacity: .6;
}

.blesk-full-nav__socials-link:focus {
  outline: none;
}

.blesk-full-nav__contacts {
  align-items: center;
  font-size: 15px;
  line-height: 17px;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__contacts {
    order: 2;
  }
}

.blesk-full-nav__contacts-phone {
  border-left: 1px solid #fff;
  margin-left: 10px;
  padding-left: 10px;
}

.blesk-full-nav__applications {
  align-items: center;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications {
    flex-direction: column;
    order: 1;
    align-items: center;
    margin-bottom: 32px;
  }
}

.blesk-full-nav__applications-titles {
  flex-direction: column;
  margin-right: 12px;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications-titles {
    margin-right: 0;
  }
}

.blesk-full-nav__applications-title {
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
}

.blesk-full-nav__applications-title > a {
  text-decoration: none;
}

.blesk-full-nav__applications-title > a:hover {
  text-decoration: underline;
}

.blesk-full-nav__applications-subtitle {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications-subtitle {
    text-align: center;
  }
}

.blesk-full-nav__applications-subtitle > a {
  text-decoration: none;
}

.blesk-full-nav__applications-subtitle > a:hover {
  text-decoration: underline;
}

.blesk-full-nav__applications-stores {
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications-stores {
    margin-top: 32px;
  }
}

.blesk-full-nav__applications-google {
  margin-right: 8px;
  line-height: 0;
}

.blesk-full-nav__applications-apple {
  line-height: 0;
}

.blesk-header {
  z-index: 10001;
  color: #fff;
  width: 100%;
  min-width: 1080px;
  height: 126px;
  font-size: 12px;
  line-height: 14px;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.blesk-header, .blesk-header *, .blesk-header:before, .blesk-header:after, .blesk-header :before, .blesk-header :after {
  box-sizing: border-box;
}

.blesk-header--responsive {
  min-width: 0;
}

@media (min-width: 1100px) {
  .blesk-header--responsive {
    min-width: 1080px;
    height: 125px;
    padding-top: 0 !important;
  }
}

@media (max-width: 1099px) {
  .blesk-header--responsive {
    height: auto;
  }
}

.blesk-header img {
  display: block;
}

.blesk-header a:hover {
  color: inherit;
}

.blesk-header a:focus {
  outline: none;
}

.blesk-header__link {
  color: #fff;
  text-decoration: none;
}

.blesk-header__link:not([href]):hover {
  text-decoration: none;
}

.blesk-header__top {
  background-color: #ed1c24;
}

@media (max-width: 767px) {
  .blesk-header__top {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.blesk-header__bottom {
  background-color: #ed1c24;
}

@media (min-width: 768px) {
  .blesk-header__bottom {
    background-color: #fff;
    height: 44px;
  }
}

.blesk-header__container {
  margin: 0 auto;
}

@media (min-width: 1100px) {
  .blesk-header--full-width .blesk-header__container {
    width: auto;
    min-width: 1110px;
    max-width: 1620px;
    padding: 0 30px;
  }

  .blesk-header__container {
    width: 1080px;
  }
}

.blesk-header__magazines {
  height: 0;
}

@media (min-width: 1100px) {
  .blesk-header__magazines {
    height: 34px;
  }
}

@media (max-width: 1099px) {
  .blesk-header__magazines {
    display: none;
  }

  .blesk-header--breaking-visible .blesk-header__magazines {
    display: block;
  }
}

.blesk-header__magazines--hide {
  display: none !important;
}

.blesk-header__inner {
  align-items: center;
  padding: 4px 0 0;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-header__inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .blesk-header__inner {
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    justify-content: space-between;
    padding: 8px 0;
  }
}

.blesk-header__logo {
  margin: 4px 30px 10px 0;
}

@media (max-width: 767px) {
  .blesk-header__logo {
    display: none;
  }
}

.blesk-header__day-info {
  margin-right: auto;
  display: flex;
}

@media (min-width: 768px) {
  .blesk-header__day-info {
    align-items: center;
  }
}

@media (max-width: 767px) {
  .blesk-header__day-info {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .blesk-header__day-info {
    font-size: 11px;
  }
}

.blesk-header__day-info a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .blesk-header__date {
    padding-right: 10px;
  }

  .blesk-header__name-day {
    border-left: 1px solid #fff;
    padding-left: 10px;
  }
}

.blesk-header__weather {
  color: #fff;
  will-change: opacity;
  align-self: stretch;
  align-items: center;
  padding: 0 10px 0 30px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.blesk-header__weather:hover {
  opacity: .8;
}

.blesk-header__weather:active {
  opacity: .6;
}

.blesk-header__weather:hover {
  text-decoration: none;
}

.blesk-header__weather:after {
  content: "";
  z-index: 1;
  border-top: 2px solid #fff;
  border-bottom: none;
  border-left: none;
  border-right: 2px solid #fff;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%)rotate(45deg);
}

@media (min-width: 768px) {
  .blesk-header__weather {
    margin-right: 30px;
  }
}

.blesk-header__weather-icon {
  height: 26px;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}

.blesk-header__daily-feed {
  will-change: opacity;
}

.blesk-header__daily-feed:hover {
  opacity: .8;
}

.blesk-header__daily-feed:active {
  opacity: .6;
}

@media (min-width: 768px) {
  .blesk-header__daily-feed {
    display: none;
  }
}

.blesk-header-nav .blesk-header__daily-feed {
  width: 22px;
  height: 22px;
  margin-inline-start: auto;
  margin-inline-end: 16px;
  position: relative;
}

.blesk-header-nav .blesk-header__daily-feed svg {
  width: 100%;
  height: 100%;
}

.blesk-header__login {
  color: #fff;
  will-change: opacity;
  flex-direction: column;
  align-items: center;
  max-height: 18px;
  font-size: 9px;
  line-height: 10px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.blesk-header__login:hover {
  opacity: .8;
}

.blesk-header__login:active {
  opacity: .6;
}

.blesk-header__login--desktop {
  margin-top: -2px;
}

.blesk-header__login:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .blesk-header__login {
    display: none;
  }
}

.blesk-header-nav .blesk-header__login {
  color: #fff;
  flex-direction: row;
  max-height: none;
  font-size: 11px;
  font-weight: 400;
  display: flex;
}

.blesk-header-nav .blesk-header__login:hover {
  color: #fff;
}

@media (min-width: 768px) {
  .blesk-header-nav .blesk-header__login {
    display: none;
  }
}

.blesk-header__login:has(.blesk-header__login-text--initials) {
  text-align: center;
  color: red;
  background: #fff;
  border-radius: 51%;
  justify-content: center;
  width: 18px;
  height: 18px;
  font-weight: 700;
  line-height: 18px;
}

.blesk-header__login:has(.blesk-header__login-text--initials):hover {
  color: red;
  text-decoration: none;
}

.blesk-header__login-image {
  border-radius: 51%;
  margin-bottom: 2px;
}

@media (max-width: 767px) {
  .blesk-header__login-image {
    width: 22px;
    height: 22px;
  }

  .blesk-header-nav .blesk-header__login-image {
    order: 1;
    margin-bottom: 0;
    margin-left: 5px;
    margin-right: 0;
  }
}

.blesk-header__search-icon {
  cursor: pointer;
  will-change: opacity;
  background: none;
  border: none;
  min-width: 18px;
  margin-left: 15px;
  padding: 0;
  transition: opacity .2s linear;
}

.blesk-header__search-icon:hover {
  opacity: .8;
}

.blesk-header__search-icon:active {
  opacity: .6;
}

.blesk-header__search-icon:focus {
  outline: none;
}

@media (max-width: 767px) {
  .blesk-header__search-icon {
    display: none;
  }

  .body--article .blesk-header__top .cnc-menu nav, .body--article .blesk-header__inner, .body--article .blesk-header-nav__menu-list, .body--article .blesk-header__search-icon, .body--article .blesk-header-nav__ikiosek-link, .body--article .blesk-header-nav__premium-link {
    display: none !important;
  }
}

.blesk-header-magazines .cnc-menu {
  height: 34px;
  margin-bottom: 0;
  line-height: 34px;
  box-shadow: none !important;
}

.blesk-header-magazines .cnc-menu:after {
  content: none !important;
}

@media (min-width: 1100px) {
  .blesk-header-magazines .cnc-menu {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
  }
}

.blesk-header-magazines .cnc-menu nav {
  z-index: auto;
  background: none;
  width: auto;
  min-height: auto;
  top: auto;
  left: auto;
}

.blesk-header-magazines .cnc-menu li ul {
  top: 34px;
}

.blesk-header-magazines .cnc-menu li ul li a {
  padding: 0;
}

.blesk-header-magazines .cnc-menu__list {
  align-items: center;
  display: flex;
}

.blesk-header-magazines .cnc-menu__list:after {
  content: none;
}

.blesk-header-magazines .cnc-menu__list a {
  padding: 0 20px;
  color: rgba(255, 255, 255, .6) !important;
  cursor: pointer !important;
  border: none !important;
  border-right: 1px solid rgba(0, 0, 0, .2) !important;
  margin: 0 !important;
}

.blesk-header-magazines .cnc-menu__list a:hover {
  color: #fff !important;
}

.blesk-header-magazines a[href*="javascript"]:hover:before, .blesk-header-magazines a[href*="javascript"]:hover:after {
  background-color: #fff !important;
}

.blesk-header-magazines a[href*="javascript"]:before, .blesk-header-magazines a[href*="javascript"]:after {
  pointer-events: none !important;
  background-color: rgba(255, 255, 255, .6) !important;
}

.blesk-header-magazines li:last-child a {
  border-right: none !important;
}

.blesk-header-magazines .breaking-news:not(.breaking-news--mobile) {
  height: 34px !important;
}

@media (max-width: 959px) {
  .blesk-header-magazines .breaking-news:not(.breaking-news--mobile) {
    height: 75px !important;
  }
}

@media (max-width: 321px) {
  .blesk-header-magazines .breaking-news:not(.breaking-news--mobile) {
    max-height: 94px;
  }
}

.blesk-header-nav {
  color: #1e1e1e;
  will-change: transform;
  background-color: #fff;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  font-family: arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

@media (max-width: 1099px) {
  .blesk-header-nav {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav {
    background-color: #ed1c24;
    justify-content: space-between;
  }
}

.blesk-header-nav a {
  color: inherit;
  font-family: inherit;
}

.blesk-header-nav a:focus {
  outline: none;
}

@media (min-width: 768px) {
  .blesk-header-nav__logo {
    min-width: 182px;
    height: 74px;
    display: none;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav__logo {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.blesk-sticky-header .blesk-header-nav__logo {
  margin-top: -12px;
  margin-bottom: -12px;
  margin-right: 30px;
  display: flex;
}

.blesk-header-nav__hamburger-button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  flex-shrink: 0;
  padding: 0 0 0 17px;
  position: relative;
}

.blesk-header-nav__hamburger-button:focus {
  outline: none;
}

.blesk-header-nav__hamburger-button:before {
  content: "";
  color: #1e1e1e;
  background-color: currentColor;
  width: 13px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  box-shadow: 0 4px, 0 -4px;
}

@media (max-width: 767px) {
  .blesk-header-nav__hamburger-button:before {
    color: #fff;
    width: 20px;
    height: 3px;
    box-shadow: 0 6px, 0 -6px;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__hamburger-button {
    margin-right: 25px;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav__hamburger-button {
    order: -1;
    width: 14px;
    font-size: 0;
  }
}

.blesk-header-nav__menu-list {
  align-items: center;
  margin: 0 auto 0 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.blesk-sticky-header .blesk-header-nav__menu-list {
  width: 100%;
  overflow: hidden;
}

@media (max-width: 767px) {
  .blesk-header-nav__menu-list {
    display: none;
  }
}

.blesk-header-nav__menu-item {
  margin-right: 23px;
}

.blesk-sticky-header .blesk-header-nav__menu-item {
  white-space: nowrap;
  order: 0;
}

.blesk-sticky-header .blesk-header-nav__menu-item--show {
  visibility: visible;
}

.blesk-sticky-header .blesk-header-nav__menu-item--hidden {
  visibility: hidden;
  order: 1;
  display: block;
}

.blesk-header-nav__menu-item:last-child {
  margin-right: 0;
}

@media (max-width: 1099px) {
  .blesk-header-nav__menu-item:nth-child(n+8) {
    display: none;
  }
}

.blesk-header-nav__menu-link {
  text-decoration: none;
}

.blesk-header-nav__menu-link:hover {
  text-decoration: underline;
}

.blesk-header-nav__menu-link.menu-link_soutez {
  color: #000;
  text-transform: uppercase;
  background-color: #ffcf00;
  padding: 5px;
}

.blesk-header-nav__menu-link.menu-link_soutez:hover {
  color: #000;
}

.blesk-header-nav__search-icon {
  display: none;
}

@media (min-width: 768px) {
  .blesk-sticky-header .blesk-header-nav__search-icon {
    display: block;
  }
}

.blesk-header-nav__premium-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img2.cncenter.cz/images/default/svg/premium-sticker.svg?v=2");
  background-repeat: no-repeat;
  background-size: contain;
  width: 83px;
  min-width: 83px;
  height: 18px;
  margin-left: 15px;
  position: static;
}

.blesk-sticky-header .blesk-header-nav__premium-link {
  margin-left: 20px;
}

@media (max-width: 767px) {
  .blesk-header-nav__premium-link {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__premium-link {
    margin-right: 18px;
  }
}

.blesk-header-nav__tipovacka-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img.cncenter.cz/img/1/origin/9202230_v0.jpg?v=0");
  background-repeat: no-repeat;
  background-size: contain;
  width: 108px;
  min-width: 108px;
  height: 18px;
  margin-left: 15px;
  position: static;
}

@media (max-width: 767px) {
  .blesk-header-nav__tipovacka-link {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__tipovacka-link {
    margin-right: 18px;
  }
}

.blesk-header-nav__secondary-logo {
  display: none;
}

.blesk-sticky-header .blesk-header-nav__secondary-logo {
  color: inherit;
  flex-shrink: 0;
  margin-left: 15px;
  margin-right: 20px;
  padding-top: 5px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 26px;
  line-height: 29px;
  display: block;
}

@media (max-width: 767px) {
  .blesk-sticky-header .blesk-header-nav__secondary-logo {
    display: none;
  }
}

.blesk-sticky-header .blesk-header-nav__secondary-logo img {
  margin: -5px 0 0;
  display: block;
}

.blesk-header-nav__secondary-menu-list {
  margin-right: auto;
  display: none;
}

.blesk-sticky-header .blesk-header-nav__secondary-menu-list {
  width: 100%;
  height: auto;
  display: flex;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .blesk-sticky-header .blesk-header-nav__secondary-menu-list {
    display: none;
  }
}

.blesk-sticky-header .blesk-header-nav__secondary-menu-list--with-open-submenu {
  overflow-x: unset;
}

.blesk-sticky-header .blesk-header-nav__secondary-menu-list--with-open-submenu .subcategory-menu__item.blesk-header-nav__menu-item--hidden, .blesk-sticky-header .subcategory-menu__list ~ .blesk-header-nav__menu-list {
  display: none;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item {
  color: #000;
  margin-right: 25px;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 0;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item:last-child {
  margin-right: 0;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item--hidden {
  display: block;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item--with-submenu:hover {
  background: none;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item:not(.subcategory-menu__item--with-submenu) {
  padding-right: 0;
}

.blesk-sticky-header .subcategory-menu__item {
  display: block;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__link {
  font-size: inherit;
  line-height: inherit;
  border: none;
  padding: 0;
}

.blesk-header-nav__secondary-menu-list--with-open-submenu .subcategory-menu__item--with-submenu:hover .subcategory-menu__submenu {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.blesk-sticky-header {
  z-index: 9999;
  align-items: center;
  min-height: auto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

@media (min-width: 768px) {
  .blesk-sticky-header {
    padding-left: 0;
  }
}

.blesk-sticky-header, .blesk-sticky-header *, .blesk-sticky-header:before, .blesk-sticky-header:after, .blesk-sticky-header :before, .blesk-sticky-header :after {
  box-sizing: border-box;
}

.blesk-full-nav__sidebar-specials-link {
  font-family: Roboto Bold, Arial, sans-serif;
}

.runes-big {
  float: left;
  height: 490px;
  clear: none !important;
}

.vyklad-snu {
  clear: none !important;
}

.js-most-read {
  float: right;
  width: 300px;
}

.internal-promo.variation {
  border-top: 0;
  margin: 0 0 10px;
}

.internal-promo.variation h2 {
  color: #7b2cea;
  text-transform: none;
  font-family: Roboto Regular, sans-serif;
  font-size: 25px;
}

.internal-promo.variation .internal-promo-items__item {
  margin-right: 15px;
}

.internal-promo.variation .internal-promo-items__item:nth-child(4n+4) {
  margin-right: 0;
}

.internal-promo.variation .internal-promo-item__image {
  background: #d2cdc8;
}

.internal-promo.variation .internal-promo-item__title {
  color: #16212d;
}

.internal-promo.variation .internal-promo-item__more {
  color: #7b2cea;
  border-bottom-color: #7b2cea;
}

.internal-promo.variation .internal-promo-item__more:after, .internal-promo.variation .internal-promo-item__more:before {
  background-color: #7b2cea;
}

.internal-promo.variation .internal-promo-item__more:hover {
  color: #96918c;
}

.internal-promo.variation .internal-promo-item__more:hover:after, .internal-promo.variation .internal-promo-item__more:hover:before {
  background-color: #96918c;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel {
  tap-highlight-color: transparent;
  z-index: 1;
  width: 100%;
  display: none;
  position: relative;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-controls .owl-nav .owl-prev, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-dot {
  cursor: hand;
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  min-height: 1px;
  position: relative;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
  width: 100%;
  display: block;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .owl-video-wrapper {
  background: #000;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: url("owl.video.play.png") no-repeat;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  transition: scale .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.owl-carousel .owl-video-play-icon:hover {
  transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  transition: opacity .4s;
}

.owl-carousel .owl-video-frame {
  z-index: 1;
  position: relative;
}

.no-js .owl-carousel {
  display: block;
}

.gallery-main-container, .gallery-vertical-container {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.gallery-main-container:after, .gallery-main-container:before, .gallery-vertical-container:after, .gallery-vertical-container:before {
  content: "";
  clear: both;
  display: table;
}

.gallery-main-container .gallery, .gallery-vertical-container .gallery {
  width: 100%;
  height: 91%;
}

.gallery-main-container .gallery .icon-loading, .gallery-vertical-container .gallery .icon-loading {
  width: 35px;
  height: 35px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container, .gallery-vertical-container .gallery .images-container {
  width: 100%;
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item, .gallery-vertical-container .gallery .images-container .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item img, .gallery-vertical-container .gallery .images-container .owl-item img {
  width: auto;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container .owl-item.advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.advertisement:after {
  content: "REKLAMA";
  color: #000;
  text-align: center;
  opacity: 0;
  z-index: -1;
  background: rgba(255, 255, 255, .3);
  width: 130px;
  height: 37px;
  padding: 0;
  font-size: 28px;
  font-weight: 600;
  transition: opacity .2s;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 65px);
}

.gallery-main-container .gallery .images-container .owl-item.active > .advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.active > .advertisement:after {
  opacity: 1;
}

.gallery-main-container .gallery .images-container .owl-stage-outer, .gallery-vertical-container .gallery .images-container .owl-stage-outer, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .btn, .gallery-vertical-container .gallery .btn {
  z-index: 10;
  cursor: pointer;
  background: rgba(0, 0, 0, .6);
  width: 9%;
  height: 91%;
  display: block;
  position: absolute;
}

.gallery-main-container .gallery .btn .icon-arrow, .gallery-vertical-container .gallery .btn .icon-arrow {
  width: 13px;
  height: 26px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .next, .gallery-vertical-container .gallery .next {
  right: 0;
}

.gallery-main-container .gallery .next .image-count, .gallery-vertical-container .gallery .next .image-count {
  text-align: center;
  color: #fff;
  margin: auto;
  font-size: 26px;
  font-weight: 300;
  position: absolute;
  bottom: 5px;
  left: 4%;
  right: 4%;
}

.gallery-main-container .image-description, .gallery-vertical-container .image-description {
  color: #aaa;
  padding: 20px 0;
}

.gallery-main-container .image-description div, .gallery-vertical-container .image-description div {
  display: inline-block;
}

.gallery-main-container .image-description .description, .gallery-vertical-container .image-description .description {
  padding: 0 5px 0 0;
}

.gallery-main-container .image-description .author, .gallery-vertical-container .image-description .author {
  font-style: italic;
}

.gallery-container.vertical .gallery-vertical-container {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container img[data-src^="http"], .gallery-container.vertical .gallery-vertical-container img[data-src^="http"] + .image-description {
  display: none;
}

.gallery-container.vertical .gallery-vertical-container img {
  margin: 10px 0 0;
}

.gallery-container.vertical .gallery-vertical-container .load-button {
  letter-spacing: 1px;
  margin: 20px auto;
  padding: 10px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description {
  padding: 20px 10px;
}

.gallery-container.vertical .gallery-vertical-container .image-description .image-description {
  clear: both;
  width: 100%;
  padding: 15px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description .author {
  float: left;
  display: block;
}

.gallery-container.vertical .gallery-vertical-container .image-description .photo-count {
  float: right;
  color: #fff;
  font-weight: 700;
}

.gallery-container.vertical .gallery-vertical-container .gallery {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container .images-container {
  margin-top: 35px;
}

.owl-nav.disabled {
  display: none;
}

.gallery-with-video .gallery-item .video-item {
  width: 81%;
  margin: auto;
}

.astroArticles .article {
  border-bottom: 1px solid #cad5df;
}

.astroArticles .article.last {
  border: none;
}

.astroArticles .article:hover .title .link {
  color: #1e1e1e;
}

.astroArticles .article .title {
  font-size: 14px;
  line-height: 1.14;
}

.astroArticles .article .title .link {
  height: 45px;
}

.astroArticles .article .imgWrapper {
  margin-right: 10px;
}

.astroArticles .article .imgWrapper .thumb {
  margin-right: 10px;
  padding: 0;
  font-size: 11px;
}

.astroArticles .btn--b {
  margin-top: 10px;
}

.astroArticles .btn--b:hover {
  background-color: #5d00c6;
}

.h-daily {
  box-shadow: none;
  background: #fff;
  height: 480px;
}

.h-daily .t-title {
  margin: 49px 0 15px;
}

.h-daily .h-daily__content {
  height: 392px;
}

.h-daily .h-daily__content .sign-info {
  height: 276px;
  font-size: 14px;
}

.h-daily .h-daily__footer {
  background: #fff;
  border-top: 1px solid #cad5df;
  width: calc(100% - 20px);
  margin: 0 auto;
}

.h-daily .h-daily__sign {
  color: #1e1e1e;
  margin: 12px 0;
}

.h-daily .tabs .tabs__link {
  box-sizing: border-box;
  color: #1e1e1e;
  border-bottom: 2px solid #7b2cea;
  height: 49px;
  font-size: 14px;
  line-height: 49px;
}

.h-daily .tabs .tabs__link:hover {
  text-decoration: underline;
}

.h-daily .is-active .tabs__link {
  color: #fff;
  background-color: #7b2cea;
}

.h-daily .is-active .tabs__link:hover {
  text-decoration: none;
}

.h-daily--full {
  height: auto;
}

.h-daily--full.h-chinese .t-title--gray {
  font-size: 30px;
}

.h-daily--full .h-daily__content {
  height: auto;
}

.h-daily--full .h-daily__content .sign-info {
  height: auto;
  min-height: 270px;
}

.h-daily--full .alt-signs {
  justify-content: space-between;
  margin-left: -35px;
  display: flex;
}

.h-daily--full .alt-signs .sign {
  flex-grow: 0;
  flex-shrink: 0;
  width: 139px;
}

.h-daily--full .link-small {
  font-size: 12px;
}

.h-daily--full .another-sign {
  background: url("/images/blesk-horoskopy/another-sign2x.png?v=2") 100% 100% / 139px 55px no-repeat;
  height: 65px;
  left: 53px;
}

.h-daily--full .another-sign i {
  background: url("/images/blesk-horoskopy/arrow-purple.svg") no-repeat;
}

.h-daily--full .sms-banner {
  border-top: 1px solid #cad5df;
  border-bottom: 1px solid #cad5df;
  height: 117px;
  margin: 0 10px;
}

.h-daily--full .sms-text {
  width: 390px;
  margin-left: 20px;
}

.h-daily--full .sms-text .t-title {
  margin-top: 22px;
  font-size: 22px;
}

.h-daily--full .sms-image {
  margin-top: 35px;
}

.h-daily--full .sms-claim {
  margin-top: 25px;
}

.h-daily--full .sms-partner strong {
  color: #7b2cea;
}

.h-daily--full .sign-up {
  z-index: 4;
  top: 58%;
  bottom: auto;
}

.h-daily--full .addon .t-title {
  margin: 10px 0 5px;
}

.h-daily--full .h-daily__addons {
  padding: 25px 30px 30px;
}

.h-daily--full .ico--laska {
  background: url("/images/blesk-horoskopy/ico-heart.png") no-repeat;
}

.h-daily--full .ico--kariera {
  background: url("/images/blesk-horoskopy/ico-money.png") no-repeat;
}

.h-daily--full .ico--zdravi {
  background: url("/images/blesk-horoskopy/ico-health.png") no-repeat;
}

.h-daily--full .h-daily__fb {
  color: #7b2cea;
  justify-content: space-between;
  width: 80px;
  font-size: 12px;
  display: flex;
}

.sign-up {
  box-shadow: 0 -13px 38px -15px #aaa;
}

.sign-up .t-title {
  margin: 0;
}

.sign-up .sign-up__submit {
  box-sizing: border-box;
  letter-spacing: normal;
  width: 280px;
  font-size: 13px;
  font-weight: normal;
}

.sign-up .sign-up__submit:hover {
  background-color: #d12b8d;
}

.zodiac-menu {
  float: left;
  box-sizing: border-box;
  background-color: #fff;
  justify-content: space-around;
  align-items: center;
  width: calc(100% - 20px);
  height: 100px;
  margin: 0 auto 0 10px;
  display: flex;
}

.zodiac-menu__item {
  background: none;
}

.zodiac-menu__item.is-active .zodiac-menu__link {
  color: #fa5ab9;
  text-decoration: underline;
}

.zodiac-menu__item.is-active .zodiac-menu__link.aries span {
  background-position: 0 0;
}

.zodiac-menu__item .zodiac-menu__link.aries span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") 0 -60px no-repeat;
}

.zodiac-menu__item .zodiac-menu__link.aries:hover span, .zodiac-menu__item .zodiac-menu__link.aries:focus span {
  background-position: 0 0;
}

.zodiac-menu__item.is-active .zodiac-menu__link.taurus span {
  background-position: -80px 0;
}

.zodiac-menu__item .zodiac-menu__link.taurus span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -80px -60px no-repeat;
}

.zodiac-menu__item .zodiac-menu__link.taurus:hover span, .zodiac-menu__item .zodiac-menu__link.taurus:focus span {
  background-position: -80px 0;
}

.zodiac-menu__item.is-active .zodiac-menu__link.gemini span {
  background-position: -160px 0;
}

.zodiac-menu__item .zodiac-menu__link.gemini span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -160px -60px no-repeat;
}

.zodiac-menu__item .zodiac-menu__link.gemini:hover span, .zodiac-menu__item .zodiac-menu__link.gemini:focus span {
  background-position: -160px 0;
}

.zodiac-menu__item.is-active .zodiac-menu__link.cancer span {
  background-position: -240px 0;
}

.zodiac-menu__item .zodiac-menu__link.cancer span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -240px -60px no-repeat;
}

.zodiac-menu__item .zodiac-menu__link.cancer:hover span, .zodiac-menu__item .zodiac-menu__link.cancer:focus span {
  background-position: -240px 0;
}

.zodiac-menu__item.is-active .zodiac-menu__link.leo span {
  background-position: -320px 0;
}

.zodiac-menu__item .zodiac-menu__link.leo span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -320px -60px no-repeat;
}

.zodiac-menu__item .zodiac-menu__link.leo:hover span, .zodiac-menu__item .zodiac-menu__link.leo:focus span {
  background-position: -320px 0;
}

.zodiac-menu__item.is-active .zodiac-menu__link.virgo span {
  background-position: -400px 0;
}

.zodiac-menu__item .zodiac-menu__link.virgo span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -400px -60px no-repeat;
}

.zodiac-menu__item .zodiac-menu__link.virgo:hover span, .zodiac-menu__item .zodiac-menu__link.virgo:focus span {
  background-position: -400px 0;
}

.zodiac-menu__item.is-active .zodiac-menu__link.libra span {
  background-position: -480px 0;
}

.zodiac-menu__item .zodiac-menu__link.libra span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -480px -60px no-repeat;
}

.zodiac-menu__item .zodiac-menu__link.libra:hover span, .zodiac-menu__item .zodiac-menu__link.libra:focus span {
  background-position: -480px 0;
}

.zodiac-menu__item.is-active .zodiac-menu__link.scorpio span {
  background-position: -560px 0;
}

.zodiac-menu__item .zodiac-menu__link.scorpio span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -560px -60px no-repeat;
}

.zodiac-menu__item .zodiac-menu__link.scorpio:hover span, .zodiac-menu__item .zodiac-menu__link.scorpio:focus span {
  background-position: -560px 0;
}

.zodiac-menu__item.is-active .zodiac-menu__link.sagittarius span {
  background-position: -640px 0;
}

.zodiac-menu__item .zodiac-menu__link.sagittarius span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -640px -60px no-repeat;
}

.zodiac-menu__item .zodiac-menu__link.sagittarius:hover span, .zodiac-menu__item .zodiac-menu__link.sagittarius:focus span {
  background-position: -640px 0;
}

.zodiac-menu__item.is-active .zodiac-menu__link.capricorn span {
  background-position: -720px 0;
}

.zodiac-menu__item .zodiac-menu__link.capricorn span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -720px -60px no-repeat;
}

.zodiac-menu__item .zodiac-menu__link.capricorn:hover span, .zodiac-menu__item .zodiac-menu__link.capricorn:focus span {
  background-position: -720px 0;
}

.zodiac-menu__item.is-active .zodiac-menu__link.aquarius span {
  background-position: -800px 0;
}

.zodiac-menu__item .zodiac-menu__link.aquarius span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -800px -60px no-repeat;
}

.zodiac-menu__item .zodiac-menu__link.aquarius:hover span, .zodiac-menu__item .zodiac-menu__link.aquarius:focus span {
  background-position: -800px 0;
}

.zodiac-menu__item.is-active .zodiac-menu__link.pisces span {
  background-position: -880px 0;
}

.zodiac-menu__item .zodiac-menu__link.pisces span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -880px -60px no-repeat;
}

.zodiac-menu__item .zodiac-menu__link.pisces:hover span, .zodiac-menu__item .zodiac-menu__link.pisces:focus span {
  background-position: -880px 0;
}

.zodiac-menu__link {
  color: #7b2cea;
}

.zodiac-menu__link span {
  width: 75px;
}

.zodiac-menu__link:hover {
  color: #fa5ab9;
}

.subcategory-menu[data-over] .subcategory-menu__submenu {
  background: #fff;
  flex-direction: column;
  display: flex !important;
}

.subcategory-menu__list {
  position: relative;
}

.subcategory-menu__link {
  white-space: normal;
}

.subcategory-menu__link.active {
  text-decoration: underline;
}

.subcategory-menu__item--with-submenu {
  position: relative;
}

.subcategory-menu__item--with-submenu:hover, .subcategory-menu__item--with-submenu.over {
  background: #fff;
}

.subcategory-menu__item--with-submenu:hover:after, .subcategory-menu__item--with-submenu.over:after {
  transform: translateY(-50%)scaleY(1);
}

.subcategory-menu__item--with-submenu .subcategory-menu__submenu {
  box-sizing: border-box;
  background: #fff;
  justify-content: flex-end;
  width: 230px;
  padding: 20px 25px 15px 0;
  top: 46px;
  right: 0;
  box-shadow: 0 7px 6px rgba(0, 0, 0, .2);
}

.subcategory-menu__item--with-submenu .subcategory-menu__submenu-link {
  border-bottom: 1px solid #cad5df;
  margin: 0 0 10px;
  padding: 0 0 10px;
  line-height: 1.4;
}

.subcategory-menu__item--with-submenu .subcategory-menu__submenu-link.active {
  border-bottom: 1px solid #cad5df;
}

.subcategory-menu__item--with-submenu .subcategory-menu__submenu-item {
  margin-left: 25px;
}

.subcategory-menu__item--with-submenu .subcategory-menu__submenu-item:last-child .subcategory-menu__submenu-link {
  border: 0;
  margin: 0;
  padding: 0 0 5px;
}

.blesk-sticky-header .subcategory-menu__item {
  flex-shrink: 0;
  position: relative;
}

.blesk-sticky-header .blesk-header-nav__secondary-logo {
  margin-left: 0;
}

.blesk-sticky-header .subcategory-menu__item--with-submenu:hover {
  display: block;
}

.blesk-sticky-header .subcategory-menu__submenu {
  right: -15px;
}

.xml-related__articles {
  box-sizing: border-box;
  background: #fff;
  width: 300px;
  margin: 0;
  padding: 0 20px 10px;
  overflow: hidden;
}

.xml-related__articles .category-name {
  margin-bottom: 3px;
}

.xml-related__articles .list-header {
  float: left;
  margin-top: 15px;
}

.xml-related__articles .list-header .title-container h2.title {
  margin: 0 0 5px;
  font: 30px / 37px Roboto, sans-serif;
}

.xml-related__articles img.lazyload {
  width: 80px;
  margin: 0;
  font-size: 11px;
}

.xml-related__articles article.list-article-small-horizontal {
  width: 100%;
  margin: 0;
  padding: 10px 0 9px;
}

.xml-related__articles article.list-article-small-horizontal .loading-placeholder {
  width: 80px;
}

.xml-related__articles article.list-article-small-horizontal .title-container {
  width: calc(100% - 90px);
}

.xml-related__articles article.list-article-small-horizontal .title-container .title {
  margin: 0;
  font-family: Roboto Bold, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.14;
}

.xml-related__articles .list-article {
  border-bottom: 1px solid #cad5df;
}

.xml-related__articles .list-article:last-child {
  border: none;
}

.xml-related__articles.ecommerce {
  margin-bottom: 20px;
}

.xml-related__articles.ecommerce img {
  object-fit: contain;
  background: #d2cdc8;
  height: 53px;
}

.xml-related__articles.ecommerce img.ecommerce__bazar-logo {
  height: initial;
}

.most-read__wrapper {
  margin: 0 10px;
}

.xml-related__articles--alt img.lazyload {
  width: 100%;
}

.xml-related__articles--alt .xml-related__articles {
  background: none;
  width: 100%;
  padding: 0;
}

.xml-related__articles--alt .list-header {
  width: 100%;
}

.xml-related__articles--alt .list-article-medium-vertical {
  box-sizing: border-box;
  border: none;
  width: 220px;
  height: 240px;
  margin-right: 20px;
  padding: 0;
}

.xml-related__articles--alt .list-article-medium-vertical:nth-child(4) {
  margin: 0;
}

.xml-related__articles--alt .list-article-medium-vertical .title-container .title {
  font-family: Roboto Bold, Arial, sans-serif;
}

.xml-related__articles-wrapper {
  float: left;
  flex-wrap: wrap;
  display: flex;
}

.offset .xml-related__articles img.lazyload {
  position: absolute;
  top: -38px;
}

.offset .xml-related__articles .loading-placeholder {
  height: 53px;
}

.img-tarots .link-wrapp {
  margin: 20px 10px 0 0;
}

.img-tarots .img-wrapp {
  left: 225px;
}

.img-chinese:hover p {
  text-decoration: underline;
}

.img-chinese p {
  margin-top: -10px;
}

.img-chinese .img-wrapp {
  bottom: -19px;
  left: 175px;
}

.bio {
  padding-left: 20px;
}

.bio .bio__value {
  font: 22px / 24px Roboto, sans-serif;
}

.bio .bio__name {
  font: 11px / 1 Roboto Regular, sans-serif;
}

.bio--full .bio__date-select {
  margin-right: 0;
}

.bio--full .bio__canvas-wrapp, .bio--full .bio__content {
  margin: 0;
}

.bio--full .bio__value {
  font-size: 50px;
}

.bio--full .bio__social {
  margin-top: 0;
}

.bio__canvas-wrapp {
  margin-top: 85px;
}

.bio__date-select {
  margin: 19px -50px 0 0;
}

.bio__content {
  margin-top: 98px;
}

.img-indian:hover .t-small {
  text-decoration: underline;
}

.img-indian p {
  margin-top: -5px;
}

.img-indian .t-title {
  margin-top: 15px;
}

.img-indian .text-wrapp {
  color: #919191;
  margin: 17px 10px 0 0;
  font-size: 14px;
}

.img-indian .img-wrapp {
  left: auto;
  right: 25px;
}

.pendulum .result {
  margin-top: 0;
}

.pendulum p {
  color: #919191;
  margin-top: -17px;
  font-size: 14px;
}

.happiness .back {
  height: 100%;
}

.happiness .loader {
  background: rgba(123, 44, 234, .8) url("https://img2.cncenter.cz/images/blesk-horoskopy/spinner.svg") center no-repeat;
}

.happiness .share-buttons {
  color: #fff;
  justify-content: space-between;
  width: 90px;
  margin: 13px 0;
  font-size: 12px;
  display: flex;
}

.happiness .link-small {
  font-size: 12px;
  position: absolute;
  bottom: 10px;
}

.share-buttons__button {
  margin-left: 10px;
}

.love .link-small.is-active {
  color: #7b2cea;
  text-decoration: none;
}

.love--full {
  background: none;
}

.love--full .tabs .tabs__link {
  color: #1e1914;
  border-bottom: 0;
  width: 50%;
  height: 49px;
  line-height: 49px;
}

.love--full .tabs .is-active .tabs__link {
  color: #fff;
  background-color: #7b2cea;
  border-left: 0;
  border-right: 0;
}

.love--full .love__content-wrapper {
  border-top: 2px solid #7b2cea;
}

.love--full .love__content {
  height: 427px;
}

.love--full .t-title {
  margin-bottom: 20px;
}

.love--full .info {
  color: #919191;
  margin-bottom: 26px;
}

.love--full .input-select {
  color: #6f6a65;
  background-color: #fcfcfc;
  border: 1px solid #cad5df;
}

.love--full .input-select.love__by-date__day {
  width: 64px;
  margin-right: 12px;
}

.love--full .input-select.love__by-date__month {
  width: 114px;
  margin-right: 12px;
}

.love--full .input-select.love__by-date__year {
  width: 75px;
}

.love--full .input-text {
  background-color: #fcfcfc;
  width: 256px;
}

.love--full .input-text::placeholder {
  color: #6f6a65;
}

.love--full .love__btn-wrapp {
  margin-top: 20px;
}

.love--full .love__submit {
  width: 279px;
}

.love--full .vs {
  color: #cad5df;
  line-height: 50px;
}

.love--full .result-title {
  margin: 20px;
}

.love--full .result-title--names {
  text-align: center;
}

.love--full .result-text {
  height: 290px;
  margin: 0 20px;
}

.love--full .result-text--names {
  height: 290px;
  font: 230px / 290px Roboto, sans-serif;
}

.love--full .result-btn--names {
  width: 280px;
}

.love__content--result {
  height: 429px;
}

.lunar .lunar__image {
  width: 16px;
  height: 16px;
}

.lunar .lunar__links {
  margin-top: -5px;
}

.lunar .lunar__values {
  width: 235px;
  margin: 17px -7px 0 0;
}

.lunar .item {
  border-right: 1px solid #cad5df;
  margin-right: 13px;
  padding-right: 13px;
}

.lunar .item:last-child {
  border: none;
  margin: 0;
  padding: 0;
}

.lunar .img-wrapp {
  left: 37%;
}

.lunar .t-small {
  margin-right: 5px;
}

.lunar .t-title {
  margin-top: 15px;
}

.seers {
  z-index: 1;
  margin: 0;
  position: relative;
}

.seers .info {
  position: relative;
}

.seers .t-title {
  margin: 0;
  padding-top: 15px;
}

.seers .article {
  border: none;
  padding: 0;
}

.seers .thumb {
  box-shadow: none;
  width: 90px;
  height: 119px;
  top: 185px;
  transform: rotate(7deg);
}

.seers .t-title--purple {
  font-size: 30px;
}

.seers .calleman-widget-icon {
  display: none;
}

.seers .calleman-widget-btn-secondary {
  box-sizing: border-box;
  color: #6f6a65;
  text-transform: uppercase;
  border-color: #cad5df;
  border-radius: 0;
  flex-grow: 0;
  width: 125px;
  height: 40px;
  font-size: 13px;
  font-weight: 500;
}

.seers .calleman-widget-toggle-buttons {
  margin-top: 5px;
}

.seers .btn--b {
  width: calc(100% - 65px);
  position: absolute;
  bottom: 10px;
}

.seers .seers-wrapper {
  margin-top: 25px;
}

.seers .calleman-widget {
  box-sizing: border-box;
  width: 260px;
}

.seers .calleman-widget-content {
  box-sizing: border-box;
  z-index: 1;
  width: 300px;
  margin: 0;
  padding: 10px 20px 0;
  font-family: Roboto Regular, arial, sans-serif;
  font-size: 15px;
  position: absolute;
  top: 65px;
  left: -20px;
}

.seers .calleman-widget-btn-primary {
  letter-spacing: normal;
  text-transform: uppercase;
  height: 40px;
  font-size: 13px;
  font-weight: normal;
  transition: all .15s ease-in-out;
}

.seers .calleman-widget-btn-primary:hover {
  background-color: #5d00c6;
}

.seers .calleman-widget-separator {
  font-style: normal;
}

.seers .calleman-widget-form-control {
  padding-left: 20px;
  font-size: 13px;
}

.seers .calleman-widget-input-group-text {
  font-size: 13px;
}

.seers .calleman-widget-full-info .calleman-widget-alternative {
  margin-bottom: 20px;
  line-height: 1.4;
}

.seers .calleman-widget-full-info .calleman-widget-info {
  line-height: 1.7;
}

.seers .calleman-widget-full-info .calleman-widget-info .calleman-widget-strong {
  color: #7b2cea;
}

.seers .calleman-widget-full-info .calleman-widget-info .calleman-widget-price {
  font-size: 11px;
}

.seers--full .calleman-widget-content {
  margin-top: 20px;
  padding: 0;
  top: auto;
}

.seers--full .article {
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
  padding-bottom: 40px;
}

.seers--full .calleman-widget-toggle-buttons {
  margin-top: 25px;
}

.seers--full .l-wp--80 {
  min-height: 60px;
}

.seers--full .calleman-widget-form {
  padding: 0 20px 8px;
}

.seers--full .calleman-widget-full-info {
  padding: 20px 20px 0;
}

.asc__btn-wrapp {
  margin-top: 9px;
}

.vyklad-snu.l-out--4 .t-title {
  margin: 15px 0 7px;
}

.box-runes-numb {
  height: 480px;
}

.box-runes-numb .img-wrapp {
  position: absolute;
}

.box-runes-numb .img-wrapp--sharp {
  background: #fff;
  width: 300px;
  height: 230px;
}

.box-runes-numb .img-wrapp--sharp img {
  padding: 60px 0 0 10px;
}

.runes-big.l-out--4 {
  position: relative;
  overflow: hidden;
}

.runes-big.l-out--4 .btn--b {
  box-sizing: border-box;
  width: 260px;
  height: 40px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.runes-big.l-out--4 .t-title {
  position: absolute;
  left: 20px;
}

.card-of-day .img-wrapp {
  left: auto;
  right: 177px;
}

.card-of-day .btn--purple {
  box-sizing: border-box;
  width: 136px;
}

.card-of-day .btn-wrapp {
  margin: 21px 0 0;
}

.h-articles .l-out--8, .h-articles + .l-out--8 {
  margin-top: 0;
}

.h-articles .cnc-ads {
  margin: 15px 0 25px;
}

.searchText {
  background: none;
  margin: 0;
}

.wikiBlesk .middleArticle .wrapper {
  border: none;
  padding: 17px 0 40px 220px;
}

.wikiBlesk .category-headline {
  margin: 0;
  font-size: 30px;
  line-height: 1.1;
}

.redesign {
  background: #e5edf2;
}

.redesign .category-headline {
  color: #1e1e1e;
  margin: -7px 0 13px 10px;
  font-family: Roboto, sans-serif;
  font-size: 30px;
  font-weight: 500;
}

.redesign .page-container {
  background: #e5edf2;
}

.redesign .breadcrumbs--alt #breadcrumbs {
  float: left;
  margin: 20px 10px 7px;
}

.redesign .breadcrumbs--alt .category-headline {
  float: left;
  clear: both;
}

.redesign .btn--pink:hover {
  background: #d12b8d;
}

.redesign .l-row {
  margin-bottom: 40px;
}

.redesign .l-h--80 {
  height: 80px;
}

.redesign .l-h--260 {
  height: 260px;
}

.redesign .l-h--490 {
  height: 480px;
}

.redesign .box--nb--purple {
  padding: 0;
}

.redesign .box {
  border: none;
  padding: 0;
}

.redesign .box .t-title {
  margin: 20px 0 5px;
}

.redesign .btn--b, .redesign .btn {
  letter-spacing: normal;
  font-size: 13px;
  font-weight: normal;
}

.redesign .t-title {
  margin: 15px 0 5px;
  font-size: 30px;
}

.redesign .t-link--gray {
  color: #1e1e1e;
}

.redesign .t-small {
  font-size: 12px;
}

.redesign .box--pink, .redesign .box--purple, .redesign .box__wrapp {
  border: none;
}

.redesign .corner--pink:after, .redesign .corner--purple:after, .redesign .shadow:after {
  display: none;
}

.redesign #breadcrumbs {
  margin: -19px 10px 7px;
}

@font-face {
  font-family: Roboto;
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-300.eot");
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-300.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-300.woff") format("woff"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-300.ttf") format("truetype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-300.svg#robotolight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto Bold;
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot");
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff") format("woff"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.ttf") format("truetype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.svg#robotobold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto Regular;
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.eot");
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.woff") format("woff"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.ttf") format("truetype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.svg#robotoregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

html {
  color: #222;
  background: #f0f1f4;
  font-family: Roboto Regular, sans-serif;
  font-size: 62.5%;
  line-height: 1.4;
}

body {
  color: #1e1e1e;
  background: #f0f1f4 url("/images/blesk-horoskopy/bg-pattern.jpg");
  font-size: 1.5em;
}

nav ul, nav ol {
  margin: 0;
  padding: 0;
}

menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  color: #7b2cea;
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: underline;
}

.disp-inline {
  display: inline-block;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

.page-container {
  background: #f0f1f4 url("/images/blesk-horoskopy/bg-pattern.jpg");
}

.homepage-layout .l-out--8, .homepage-layout .l-out--4 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.homepage-layout .cnc-ads {
  margin: 0;
}

.l-page {
  width: 960px;
  margin: 0 auto;
  padding: 30px 60px 120px;
}

.l-row {
  margin-bottom: 30px;
  position: relative;
}

.l-row .l-out--4 {
  margin: 0 10px;
}

.l-row .l-out--4.margin-sp10 {
  margin: 10px;
}

.l-row .l-out--4.margin-sp10.box-runes-numb h3 {
  width: 270px;
  position: absolute;
  margin: 18px !important;
}

.l-row .l-out--4.margin-sp10.box-runes-numb h3.t-purple:hover {
  color: #6e00ea;
}

.l-row .l-out--4.margin-sp10.box-runes-numb h3.t-pink:hover {
  color: #db0a5b;
}

.l-row .l-out--4.margin-sp10.box-runes-numb h3:hover {
  text-decoration: underline;
}

.l-row .l-out--4.margin-sp10.box-runes-numb .img-wrapp {
  position: absolute;
}

.l-row:before, .l-row:after {
  content: " ";
  display: table;
}

.l-row:after {
  clear: both;
}

.l-row.mb-10 {
  margin-bottom: 10px;
}

.l-g--12, .l-g--11, .l-g--10, .l-g--9, .l-g--8, .l-g--7, .l-g--6, .l-g--5, .l-g--4, .l-g--3, .l-g--2, .l-g--1, .zodiac-menu__item .zodiac-menu__item--last {
  float: left;
  margin: 0;
  display: block;
}

.l-in--12, .l-in--11, .l-in--10, .l-in--9, .l-in--8, .l-in--7, .l-in--6, .l-in--5, .l-in--4, .l-in--3, .l-in--2, .l-in--1 {
  float: left;
  padding: 10px;
  display: block;
}

.l-out--12, .l-out--11, .l-out--10, .l-out--9, .l-out--8, .l-out--7, .l-out--6, .l-out--5, .l-out--4, .l-out--3, .l-out--2, .l-out--1 {
  float: left;
  margin: 0 10px;
  display: block;
}

.l-g--1, .zodiac-menu__item .zodiac-menu__item--last {
  width: 80px;
}

.l-in--1, .l-out--1 {
  width: 60px;
}

.l-g--2 {
  width: 160px;
}

.l-in--2, .l-out--2 {
  width: 140px;
}

.l-g--3 {
  width: 240px;
}

.l-in--3, .l-out--3 {
  width: 220px;
}

.l-g--4 {
  width: 320px;
}

.l-in--4, .l-out--4 {
  width: 300px;
}

.l-g--5 {
  width: 400px;
}

.l-in--5, .l-out--5 {
  width: 380px;
}

.l-g--6 {
  width: 480px;
}

.l-in--6, .l-out--6 {
  width: 460px;
}

.l-g--7 {
  width: 560px;
}

.l-in--7, .l-out--7 {
  width: 540px;
}

.l-g--8 {
  width: 640px;
}

.l-in--8, .l-out--8 {
  width: 620px;
}

.l-g--9 {
  width: 720px;
}

.l-in--9, .l-out--9 {
  width: 700px;
}

.l-g--10 {
  width: 800px;
}

.l-in--10, .l-out--10 {
  width: 780px;
}

.l-g--11 {
  width: 880px;
}

.l-in--11, .l-out--11 {
  width: 860px;
}

.l-g--12 {
  width: 960px;
}

.l-in--12, .l-out--12 {
  width: 940px;
}

.l-h--80:before, .l-h--80:after, .l-h--230:before, .l-h--230:after, .l-h--490:before, .l-h--490:after, .l-h--600:before, .l-h--600:after, .l-h--750:before, .l-h--750:after {
  content: " ";
  display: table;
}

.l-h--80:after, .l-h--230:after, .l-h--490:after, .l-h--600:after, .l-h--750:after {
  clear: both;
}

.l-h--750 {
  height: 744px;
}

.l-h--600 {
  height: 594px;
}

.l-h--490 {
  height: 484px;
}

.l-h--230 {
  height: 224px;
}

.l-h--80 {
  height: 74px;
}

.l-m--10 {
  margin: 10px;
}

.l-mtb--10 {
  margin: 10px 0;
}

.l-p--10 {
  padding: 10px;
}

.l-ptb--10 {
  padding: 10px 0;
}

.l-mt--10 {
  margin-top: 10px;
}

.l-mr--10 {
  margin-right: 10px;
}

.l-mb--10 {
  margin-bottom: 10px !important;
}

.l-ml--10 {
  margin-left: 10px;
}

.l-pt--10 {
  padding-top: 10px;
}

.l-pr--10 {
  padding-right: 10px;
}

.l-pb--10 {
  padding-bottom: 10px;
}

.l-pl--10 {
  padding-left: 10px;
}

.l-ci--10 {
  padding-left: 10px;
  padding-right: 10px;
}

.l-co--10 {
  margin-left: 10px;
  margin-right: 10px;
}

.l-m--20 {
  margin: 20px;
}

.l-mtb--20 {
  margin: 20px 0;
}

.l-p--20 {
  padding: 20px;
}

.l-ptb--20 {
  padding: 20px 0;
}

.l-mt--20 {
  margin-top: 20px;
}

.l-mr--20 {
  margin-right: 20px;
}

.l-mb--20 {
  margin-bottom: 20px !important;
}

.l-ml--20 {
  margin-left: 20px;
}

.l-pt--20 {
  padding-top: 20px;
}

.l-pr--20 {
  padding-right: 20px;
}

.l-pb--20 {
  padding-bottom: 20px;
}

.l-pl--20 {
  padding-left: 20px;
}

.l-ci--20 {
  padding-left: 20px;
  padding-right: 20px;
}

.l-co--20 {
  margin-left: 20px;
  margin-right: 20px;
}

.l-m--30 {
  margin: 30px;
}

.l-mtb--30 {
  margin: 30px 0;
}

.l-p--30 {
  padding: 30px;
}

.l-ptb--30 {
  padding: 30px 0;
}

.l-mt--30 {
  margin-top: 30px;
}

.l-mr--30 {
  margin-right: 30px;
}

.l-mb--30 {
  margin-bottom: 30px !important;
}

.l-ml--30 {
  margin-left: 30px;
}

.l-pt--30 {
  padding-top: 30px;
}

.l-pr--30 {
  padding-right: 30px;
}

.l-pb--30 {
  padding-bottom: 30px;
}

.l-pl--30 {
  padding-left: 30px;
}

.l-ci--30 {
  padding-left: 30px;
  padding-right: 30px;
}

.l-co--30 {
  margin-left: 30px;
  margin-right: 30px;
}

.l-m--40 {
  margin: 40px;
}

.l-mtb--40 {
  margin: 40px 0;
}

.l-p--40 {
  padding: 40px;
}

.l-ptb--40 {
  padding: 40px 0;
}

.l-mt--40 {
  margin-top: 40px;
}

.l-mr--40 {
  margin-right: 40px;
}

.l-mb--40 {
  margin-bottom: 40px !important;
}

.l-ml--40 {
  margin-left: 40px;
}

.l-pt--40 {
  padding-top: 40px;
}

.l-pr--40 {
  padding-right: 40px;
}

.l-pb--40 {
  padding-bottom: 40px;
}

.l-pl--40 {
  padding-left: 40px;
}

.l-ci--40 {
  padding-left: 40px;
  padding-right: 40px;
}

.l-co--40 {
  margin-left: 40px;
  margin-right: 40px;
}

.l-m--50 {
  margin: 50px;
}

.l-mtb--50 {
  margin: 50px 0;
}

.l-p--50 {
  padding: 50px;
}

.l-ptb--50 {
  padding: 50px 0;
}

.l-mt--50 {
  margin-top: 50px;
}

.l-mr--50 {
  margin-right: 50px;
}

.l-mb--50 {
  margin-bottom: 50px !important;
}

.l-ml--50 {
  margin-left: 50px;
}

.l-pt--50 {
  padding-top: 50px;
}

.l-pr--50 {
  padding-right: 50px;
}

.l-pb--50 {
  padding-bottom: 50px;
}

.l-pl--50 {
  padding-left: 50px;
}

.l-ci--50 {
  padding-left: 50px;
  padding-right: 50px;
}

.l-co--50 {
  margin-left: 50px;
  margin-right: 50px;
}

.l-wp--10 {
  width: 10%;
}

.l-wp--20 {
  width: 20%;
}

.l-wp--30 {
  width: 30%;
}

.l-wp--40 {
  width: 40%;
}

.l-wp--50 {
  width: 50%;
}

.l-wp--60 {
  width: 60%;
}

.l-wp--70 {
  width: 70%;
}

.l-wp--80 {
  width: 80%;
}

.l-wp--90 {
  width: 90%;
}

.l-wp--100 {
  width: 100%;
}

.l-w--278 {
  width: 278px;
}

.l-bl, .l-bb, .l-br, .l-bt {
  border: 0 solid #fff;
}

.l-bt {
  border-top-width: 1px;
}

.l-br {
  border-right-width: 1px;
}

.l-bb {
  border-bottom-width: 1px;
}

.l-bl {
  border-left-width: 1px;
}

.l-nb {
  border: none;
}

.pos-r {
  position: relative;
}

.pos-a {
  position: absolute;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.fn {
  float: none;
}

.block {
  display: block;
}

.hide {
  display: none;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.over-h {
  overflow: hidden;
}

.ma {
  margin-left: auto;
  margin-right: auto;
}

.link-block {
  color: inherit;
  text-decoration: none;
  display: block;
}

.link-block:hover, .link-block:focus {
  text-decoration: none;
}

.mt20px {
  margin-top: 20px;
}

.mr20px {
  margin-right: 20px;
}

.mb20px {
  margin-bottom: 20px;
}

.ml20px {
  margin-left: 20px;
}

p, h1, h2, h3, h5, h6 {
  margin: 0;
}

p strong {
  font-family: Roboto Bold, sans-serif;
  font-weight: normal;
}

.zodiac-menu__link, .zodiac-menu__item .zodiac-menu__item--last {
  font-size: 12px;
}

.t-left {
  text-align: left;
}

.t-right {
  text-align: right;
}

.t-center {
  text-align: center;
}

.t-upper {
  text-transform: uppercase;
}

.t-lower {
  text-transform: lowercase;
}

.t-title, .t-title--30 {
  color: #7b2cea;
  margin: 13px 0;
  font: 33px / 37px Roboto, sans-serif;
}

.t-title .t-upper, .t-title--30 .t-upper {
  letter-spacing: -.08ex;
}

.t-title--30 {
  font-size: 30px;
}

.t-subtitle, .t-subtitle--cerise, .t-subtitle--gray, .t-subtitle--purple, .t-subtitle--pink {
  font: 15px Roboto Regular, sans-serif;
}

.t-personal {
  color: #888;
  float: left;
  width: 450px;
  font-size: 14px;
  line-height: 22px;
}

.box--pink .t-title, .box--pink .t-title a {
  color: #fa5ab9;
}

.box--pink .t-title a:hover, .box--pink .t-title a:focus {
  color: #db0a5b;
}

.box--nb--pink .t-title, .box--no-border--pink .t-title {
  color: #fff;
}

.t-pink, .t-pink a {
  color: #fa5ab9;
}

.t-pink a:hover, .t-pink a:focus {
  color: #db0a5b;
}

.box--purple .t-title, .box--purple .t-title a {
  color: #7b2cea;
}

.box--purple .t-title a:hover, .box--purple .t-title a:focus {
  color: #6600da;
}

.box--nb--purple .t-title, .box--no-border--purple .t-title {
  color: #fff;
}

.t-purple, .t-purple a {
  color: #7b2cea;
}

.t-purple a:hover, .t-purple a:focus {
  color: #6600da;
}

.box--gray .t-title, .box--gray .t-title a {
  color: #827d78;
}

.box--gray .t-title a:hover, .box--gray .t-title a:focus {
  color: #46413c;
}

.box--nb--gray .t-title, .box--no-border--gray .t-title {
  color: #fff;
}

.t-gray, .t-gray a {
  color: #827d78;
}

.t-gray a:hover, .t-gray a:focus {
  color: #46413c;
}

.box--cerise .t-title, .box--cerise .t-title a, .box--cerise .t-title a:hover, .box--cerise .t-title a:focus {
  color: #dc3296;
}

.box--nb--cerise .t-title, .box--no-border--cerise .t-title {
  color: #fff;
}

.t-cerise, .t-cerise a, .t-cerise a:hover, .t-cerise a:focus {
  color: #dc3296;
}

.t-purple-light {
  color: #9b81de;
}

.t-subtitle--pink {
  color: #fa5ab9;
}

.t-title--pink {
  color: #fa5ab9;
  margin: 13px 0;
  font: 35px / 37px Roboto, sans-serif;
}

.t-title--pink a {
  color: #fa5ab9;
}

.t-title--pink a:hover, .t-title--pink a:focus {
  color: #db0a5b;
}

.t-link--pink {
  color: #fa5ab9;
}

.t-link--pink:hover, .t-link--pink:focus {
  color: #db0a5b;
}

.t-subtitle--purple {
  color: #7b2cea;
}

.t-title--purple {
  color: #7b2cea;
  margin: 13px 0;
  font: 35px / 37px Roboto, sans-serif;
}

.t-title--purple a {
  color: #7b2cea;
}

.t-title--purple a:hover, .t-title--purple a:focus {
  color: #6600da;
}

.t-link--purple {
  color: #7b2cea;
}

.t-link--purple:hover, .t-link--purple:focus {
  color: #6600da;
}

.t-subtitle--gray {
  color: #827d78;
}

.t-title--gray {
  color: #827d78;
  margin: 13px 0;
  font: 35px / 37px Roboto, sans-serif;
}

.t-title--gray a {
  color: #827d78;
}

.t-title--gray a:hover, .t-title--gray a:focus {
  color: #46413c;
}

.t-link--gray {
  color: #827d78;
}

.t-link--gray:hover, .t-link--gray:focus {
  color: #46413c;
}

.t-subtitle--cerise {
  color: #dc3296;
}

.t-title--cerise {
  color: #dc3296;
  margin: 13px 0;
  font: 35px / 37px Roboto, sans-serif;
}

.t-title--cerise a, .t-title--cerise a:hover, .t-title--cerise a:focus, .t-link--cerise, .t-link--cerise:hover, .t-link--cerise:focus {
  color: #dc3296;
}

.t-title--white, .t-white {
  color: #fff;
}

.t-small {
  font-family: Roboto Bold, sans-serif;
  font-size: 10px;
}

.t-small .arrow-r {
  margin-bottom: -1px;
}

.t-condensed {
  letter-spacing: -.09ex;
}

.ico {
  background: url("/images/blesk-horoskopy/sprites/icons/icons.png") no-repeat;
  width: 20px;
  height: 20px;
  display: block;
}

.ico--fin {
  background-position: 0 0;
}

.ico--health {
  background-position: -20px 0;
}

.ico--love {
  background-position: -40px 0;
}

.ico--prace-a-penize, .ico--kariera {
  background-position: -60px 0;
}

.ico--zdravi, .ico--zdravi-a-kondice {
  background-position: -80px 0;
}

.ico--domov-a-rodina, .ico--laska-a-sex, .ico--laska-a-pratelstvi, .ico--laska {
  background-position: -100px 0;
}

.ico--vasi-blizci, .ico--rodina-a-vztahy {
  background-position: -175px 0;
  width: 25px;
}

.ico--tarot, .ico--tarot--obstacles, .ico--tarot--relation, .ico--tarot--future, .ico--tarot--present, .ico--tarot--past, .ico--tarot--career, .ico--tarot--love, .ico--tarot--health {
  background: url("/images/blesk-horoskopy/sprites/sprites.png?v=4") no-repeat;
  height: 30px;
  margin: 90px auto 0;
  display: block;
}

.ico--tarot--health {
  background-position: -472px 0;
  width: 58px;
}

.ico--tarot--love {
  background-position: -472px -30px;
  width: 45px;
}

.ico--tarot--career {
  background-position: -472px -60px;
  width: 58px;
}

.ico--tarot--past {
  background-position: -530px -60px;
  width: 67px;
}

.ico--tarot--present {
  background-position: -472px -90px;
  width: 84px;
}

.ico--tarot--future {
  background-position: -472px -120px;
  width: 90px;
}

.ico--tarot--relation {
  background-position: -472px -150px;
  width: 94px;
}

.ico--tarot--obstacles {
  background-position: -341px -105px;
  width: 131px;
}

.arrow, .arrow-r, .arrow-r--white, .arrow-r--pink {
  background: url("/images/blesk-horoskopy/sprites/arrows.png?v=2") no-repeat;
}

.arrow-r, .arrow-r--white, .arrow-r--pink {
  background-position: 0 0;
  width: 10px;
  height: 10px;
  margin-left: 2px;
  display: inline-block;
}

.arrow-r--pink {
  background-position: -10px 0;
}

.arrow-r--white {
  background-position: -20px 0;
}

.box {
  background: #fff;
  border: 1px solid #fff;
  padding: 4px 0 0;
}

.box--nb, .box--no-border, .box--nb--cerise, .box--no-border--cerise, .box--nb--gray, .box--no-border--gray, .box--nb--purple, .box--no-border--purple, .box--nb--pink, .box--no-border--pink {
  background: #fff;
  border: none;
  padding: 5px 0 1px;
}

.box--cerise, .box--gray, .box--purple, .box--pink {
  background-color: #fff;
  border-top-style: solid;
  border-top-width: 5px;
  padding: 0;
}

.box--cerise .box__wrapp, .box--gray .box__wrapp, .box--purple .box__wrapp, .box--pink .box__wrapp {
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.box--pink {
  border-top-color: #fa5ab9;
}

.box--purple {
  border-top-color: #7b2cea;
}

.box--gray {
  border-top-color: #827d78;
}

.box--cerise {
  border-top-color: #dc3296;
}

.box--nb--pink, .box--no-border--pink {
  background-color: #fa5ab9;
}

.box--nb--purple, .box--no-border--purple {
  background-color: #7b2cea;
}

.box--nb--gray, .box--no-border--gray {
  background-color: #827d78;
}

.box--nb--cerise, .box--no-border--cerise {
  background-color: #dc3296;
}

.box--no-bg {
  background: none;
}

.corner--cerise, .corner--gray, .corner--purple, .corner--pink {
  position: relative;
}

.corner--cerise:after, .corner--gray:after, .corner--purple:after, .corner--pink:after {
  content: " ";
  z-index: 3;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.corner--pink:after {
  background: url("/images/blesk-horoskopy/sprites/sprites.png") -450px 0 no-repeat;
}

.box.corner--pink:after {
  bottom: -1px;
  right: -1px;
}

.corner--purple:after {
  background: url("/images/blesk-horoskopy/sprites/sprites.png") -450px -25px no-repeat;
}

.box.corner--purple:after {
  bottom: -1px;
  right: -1px;
}

.corner--gray:after {
  background: url("/images/blesk-horoskopy/sprites/sprites.png") -450px -50px no-repeat;
}

.box.corner--gray:after {
  bottom: -1px;
  right: -1px;
}

.corner--cerise:after {
  background: url("/images/blesk-horoskopy/sprites/sprites.png") -450px -75px no-repeat;
}

.box.corner--cerise:after {
  bottom: -1px;
  right: -1px;
}

.bg--white {
  background-color: #fff;
}

.tabs .tabs__item {
  display: inline;
}

.tabs .tabs__link {
  float: left;
  text-align: center;
  color: #96918c;
  border-bottom: 1px solid #fff;
  width: 309px;
  height: 35px;
  font: 15px / 35px Roboto Bold, sans-serif;
  text-decoration: none;
  display: block;
}

.tabs .is-active .tabs__link {
  cursor: default;
  background: #fff;
  border-bottom-color: #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.tabs--3-items .tabs__link {
  width: 206px;
}

.input-select, .input-text, input.btn {
  -webkit-appearance: none;
  appearance: none;
}

.l-btn--140 {
  width: 140px;
}

.btn, .btn--cerise, .btn--gray, .btn--purple, .btn--pink {
  box-sizing: content-box;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .15ex;
  color: #fff;
  background-color: #827d78;
  border: none;
  height: 40px;
  margin: 0;
  padding: 0 1em;
  font: bold 12px / 40px Arial, sans-serif;
  text-decoration: none;
  display: inline-block;
}

.btn:hover, .btn:focus, .btn.is-active, .btn--cerise:hover, .btn--gray:hover, .btn--purple:hover, .btn--pink:hover, .btn--cerise:focus, .btn--gray:focus, .btn--purple:focus, .btn--pink:focus, .is-active.btn--cerise, .is-active.btn--gray, .is-active.btn--purple, .is-active.btn--pink {
  background-color: #46413c;
  outline: none;
  text-decoration: none;
}

.btn:active, .btn--cerise:active, .btn--gray:active, .btn--purple:active, .btn--pink:active {
  outline: none;
}

a.btn {
  padding: 0 1em;
  display: inline-block;
}

a.btn--b {
  display: block;
  position: relative;
}

.p3d {
  transform-style: preserve-3d;
}

.shadow, .shadow--small, .shadow--third, .shadow--half, .shadow--first {
  transform-style: flat;
  z-index: 3;
  position: relative;
}

.shadow:after, .shadow--small:after, .shadow--third:after, .shadow--half:after, .shadow--first:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  cursor: default;
  background: url("/images/blesk-horoskopy/btn-shadow.png") no-repeat;
  width: 260px;
  height: 40px;
  position: absolute;
  bottom: -40px;
  right: 0;
}

.shadow:active, .shadow--small:active, .shadow--third:active, .shadow--half:active, .shadow--first:active {
  transform: matrix(.95, 0, 0, 1, -7, 0);
}

.shadow:active:after, .shadow--small:active:after, .shadow--third:active:after, .shadow--half:active:after, .shadow--first:active:after {
  background-position: 0 -7px;
}

.shadow .btn, .shadow .btn--b, .shadow--small .btn, .shadow--third .btn, .shadow--half .btn, .shadow--first .btn, .shadow--small .btn--b, .shadow--third .btn--b, .shadow--half .btn--b, .shadow--first .btn--b {
  z-index: 3;
}

.shadow--first:after {
  background: url("/images/blesk-horoskopy/btn-shadow-half.png") 0 -4px no-repeat;
  right: 40px;
}

.shadow--first:active {
  transform: matrix(.95, 0, 0, 1, -4, 0);
}

.shadow--first:active:after {
  background-position: 0 -19px;
}

.shadow--half:after {
  background: url("/images/blesk-horoskopy/btn-shadow-half.png") no-repeat;
}

.shadow--half:active {
  transform: matrix(.95, 0, 0, 1, -6, 0);
}

.shadow--half:active:after {
  background-position: 0 -7px;
}

.shadow--third:after {
  background: url("/images/blesk-horoskopy/btn-shadow-half.png") 0 -4px no-repeat;
}

.shadow--third:active {
  transform: matrix(.95, 0, 0, 1, -4, 0);
}

.shadow--third:active:after {
  background-position: 0 -9px;
}

.shadow--small:after {
  background: url("/images/blesk-horoskopy/btn-shadow-small.png") 100% 0 no-repeat;
}

.shadow--small:active {
  transform: matrix(.95, 0, 0, 1, -4, 0);
}

.shadow--small:active:after {
  background-position: 100% -7px;
}

.btn--pink {
  background-color: #fa5ab9;
}

.btn--purple {
  background-color: #7b2cea;
}

.btn--gray {
  background-color: #827d78;
}

.btn--cerise {
  background-color: #dc3296;
}

.btn--pink:hover, .btn--pink:focus, .btn--pink.is-active {
  background-color: #db0a5b;
}

.btn--purple:hover, .btn--purple:focus, .btn--purple.is-active {
  background-color: #6600da;
}

.btn--gray:hover, .btn--gray:focus, .btn--gray.is-active {
  background-color: #46413c;
}

.btn--cerise:hover, .btn--cerise:focus, .btn--cerise.is-active {
  background-color: #dc3296;
}

.btn--fb, .custom-tweet a {
  background: url("/images/blesk-horoskopy/sprites/sprites.png?v=4") -320px 0 no-repeat;
  width: 70px;
  height: 22px;
  display: block;
}

.fb-like__wrapp {
  z-index: 4;
  position: relative;
}

.custom-tweet a {
  background-position: -320px -22px;
}

.input-text {
  box-sizing: content-box;
  color: #6e6964;
  background-color: #fff;
  border: 1px solid #cad5df;
  outline: none;
  height: 18px;
  padding: 10px;
  font: 15px / 18px Roboto Regular, sans-serif;
}

.input-text:focus {
  color: #1e1914;
  background: #b487f3;
  border-color: #a36bf0;
}

.input-text:focus::placeholder {
  color: #1e1914;
}

.input-text::placeholder {
  color: #6e6964;
}

.input-text.is-invalid {
  background-color: #fb8bce;
  border-color: #db0a5b;
  box-shadow: 0 0 20px -3px #ff4d4d;
}

input[type="radio"]:focus {
  outline: 1px solid #fff;
  box-shadow: 0 0 8px 2px #fb83ca;
}

input[type="checkbox"]:focus {
  outline: 1px solid #fff;
  box-shadow: 0 0 8px 2px #fb83ca;
}

input[type="radio"].is-invalid {
  outline: 1px solid red;
  box-shadow: 0 0 8px 2px #ff5252;
}

input[type="checkbox"].is-invalid {
  outline: 1px solid red;
  box-shadow: 0 0 8px 2px #ff5252;
}

select::-ms-expand {
  display: none;
}

.input-select {
  color: #827d78;
  text-indent: 1px;
  text-overflow: "";
  background: #f5f3f1 url("/images/blesk-horoskopy/select-arrow.png") 100% no-repeat;
  border: 1px solid #fff;
  height: 40px;
  padding: 8px 10px;
  font: 15px / 20px Roboto Regular, sans-serif;
}

.input-select:focus {
  background-color: #e6e1dc;
  border-color: #827d78;
  outline: none;
}

.input-select.is-invalid {
  border-color: #db0a5b;
  box-shadow: 0 0 20px -3px #ff4d4d;
}

.loader {
  background: url("/images/blesk-horoskopy/loader-purple.gif") no-repeat;
  width: 42px;
  height: 42px;
  display: block;
}

.flash--wrapp {
  z-index: 1900;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.flash {
  z-index: 1900;
  background-color: rgba(250, 90, 185, .05);
  border-radius: 5px;
  width: 320px;
  margin: 20px auto;
  position: relative;
  box-shadow: 0 5px 25px -5px #46413c;
}

.flash__close {
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 10px;
}

.flash__content {
  color: #fff;
  text-shadow: 0 1px #db0a5b;
  padding: 10px 20px 10px 10px;
}

.how-to {
  padding: 0;
  list-style-type: none;
}

.how-to .item {
  margin-bottom: 30px;
  line-height: 18px;
}

.how-to .item span {
  float: left;
  background: url("/images/blesk-horoskopy/sprites/sprites.png?v=4") no-repeat;
  width: 32px;
  height: 32px;
  margin: 5px 5px 0 0;
  display: block;
}

.how-to .item-1 span {
  background-position: -415px 0;
}

.how-to .item-2 span {
  background-position: -415px -32px;
}

.how-to .item-3 span {
  background-position: -415px -64px;
}

#page-header {
  position: relative;
}

.l-main-header {
  margin-top: 25px;
}

.main-header__logo, .main-header__sublogo {
  float: left;
}

.main-header__logo img, .main-header__sublogo img {
  float: left;
  width: 240px;
  margin: 0 0 10px 10px;
}

.main-header__bpz-logo {
  width: 111px;
  height: 33px;
  display: block;
  position: absolute;
  top: -5px;
  right: -5px;
}

.main-header__sublogo img {
  margin: 0;
}

.main-header__content {
  border-top: 0 solid #dcd7d2;
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 10px 0;
  position: absolute;
  top: -90px;
}

.main-header__sign-up {
  float: right;
  margin-top: -15px;
}

.signIn {
  float: left;
  margin-top: -15px;
}

.signIn a {
  color: #fa5ab9;
  font-size: 13px;
}

.main-menu {
  background-color: #fa5ab9;
  position: relative;
  box-shadow: 0 10px 12px -12px #827d78;
}

.main-menu:after {
  content: " ";
  z-index: 10;
  background: url("/images/blesk-horoskopy/witch.png") no-repeat;
  width: 106px;
  height: 119px;
  display: block;
  position: absolute;
  top: -83px;
  right: 339px;
}

.main-menu__item {
  float: left;
  background-color: #fa5ab9;
  width: 13%;
  display: block;
}

.main-menu__item:nth-child(4) {
  width: 15%;
  margin-right: calc(3.5% - 1px);
}

.main-menu__item:nth-child(5) {
  width: 14%;
  margin-left: calc(4.5% - 1px);
}

.main-menu__item .main-menu__submenu {
  opacity: .2;
  color: #fff;
  float: left;
  z-index: 5;
  background: #dc3296;
  width: 244px;
  list-style: none;
  transition: opacity .15s ease-in-out;
  display: none;
  position: absolute;
  top: 40px;
  left: auto;
  box-shadow: 0 10px 10px rgba(0, 0, 0, .2);
}

.main-menu__item:hover, .main-menu__item:focus {
  background-color: #dc3296;
}

.main-menu__item:hover > .main-menu__submenu, .main-menu__item:focus > .main-menu__submenu {
  opacity: 1;
  display: block;
}

.main-menu__item:hover .main-menu__link, .main-menu__item:focus .main-menu__link {
  background-color: #dc3296;
}

.main-menu__link {
  color: #fff;
  text-align: center;
  background-color: #fa5ab9;
  height: 40px;
  font: 20px / 40px Roboto, sans-serif;
  text-decoration: none;
  display: block;
  position: relative;
}

.is-active .main-menu__link, .main-menu__link:hover, .main-menu__link:focus {
  background-color: #dc3296;
  text-decoration: none;
}

.main-header__menu .submenu .item, .main-menu__submenu .submenu__item {
  padding: 8px 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
}

.main-header__menu .submenu .item:hover, .main-menu__submenu .submenu__item:hover {
  background: #b41e78;
}

.main-header__menu .submenu .item .link, .main-menu__submenu .submenu__link {
  color: #fff;
  padding: 0 0 0 20px;
  font-size: 16px;
  text-decoration: none;
  display: block;
}

.submenu__item.is-active .submenu__link {
  font-family: Roboto Bold, sans-serif;
}

.main-header__menu .link {
  color: #fa5ab9;
  padding: 0 10px;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 40px;
  text-decoration: none;
  display: inline-block;
}

.main-header__menu .link:hover, .main-header__menu .link:focus {
  text-decoration: none;
}

.main-header__menu {
  width: 230px;
  position: absolute;
  top: 10px;
  right: 0;
}

.main-header__menu .submenu {
  color: #fff;
  float: left;
  z-index: 1;
  background: #dc3296;
  width: 230px;
  list-style: none;
  display: none;
  position: absolute;
  top: 40px;
  left: 0;
  box-shadow: 0 10px 10px rgba(0, 0, 0, .5);
}

.main-header__menu .ico.heart {
  background: url("/images/blesk-horoskopy/heart.png") no-repeat;
  width: 62px;
  height: 53px;
  position: absolute;
  top: -20px;
  right: 0;
}

.main-header__menu ul {
  margin: 0;
  padding: 0;
}

.main-header__menu ul:hover {
  background: #dc3296;
}

.main-header__menu ul:hover .link {
  color: #fff;
}

.main-header__menu ul:hover .submenu {
  display: block;
}

.main-header__menu .submenu .item {
  height: 38px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 38px;
}

.main-header__menu .submenu .item .link {
  color: #fff;
  padding: 0 0 0 20px;
  text-decoration: none;
  display: block;
}

.zodiac-menu {
  list-style-type: none;
}

.zodiac-menu__item .zodiac-menu__item--last {
  background: url("/images/blesk-horoskopy/zodiac-divider.png") 0 20px no-repeat;
  margin: 0;
  display: inline;
}

.zodiac-menu__item--last {
  background-image: none;
}

.zodiac-menu__link {
  color: #1e1e1e;
  text-align: center;
  flex-direction: column;
  line-height: 20px;
  text-decoration: none;
  display: flex;
}

.is-active .zodiac-menu__link, .zodiac-menu__link:hover, .zodiac-menu__link:focus {
  color: #7b2cea;
}

.zodiac-menu__link span {
  height: 60px;
  display: block;
}

.zodiac-menu__link.aries span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") 0 -60px no-repeat;
}

.is-active .zodiac-menu__link.aries span, .zodiac-menu__link.aries:hover span, .zodiac-menu__link.aries:focus span {
  background-position: 0 0;
}

.zodiac-menu__link.taurus span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -80px -60px no-repeat;
}

.is-active .zodiac-menu__link.taurus span, .zodiac-menu__link.taurus:hover span, .zodiac-menu__link.taurus:focus span {
  background-position: -80px 0;
}

.zodiac-menu__link.gemini span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -160px -60px no-repeat;
}

.is-active .zodiac-menu__link.gemini span, .zodiac-menu__link.gemini:hover span, .zodiac-menu__link.gemini:focus span {
  background-position: -160px 0;
}

.zodiac-menu__link.cancer span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -240px -60px no-repeat;
}

.is-active .zodiac-menu__link.cancer span, .zodiac-menu__link.cancer:hover span, .zodiac-menu__link.cancer:focus span {
  background-position: -240px 0;
}

.zodiac-menu__link.leo span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -320px -60px no-repeat;
}

.is-active .zodiac-menu__link.leo span, .zodiac-menu__link.leo:hover span, .zodiac-menu__link.leo:focus span {
  background-position: -320px 0;
}

.zodiac-menu__link.virgo span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -400px -60px no-repeat;
}

.is-active .zodiac-menu__link.virgo span, .zodiac-menu__link.virgo:hover span, .zodiac-menu__link.virgo:focus span {
  background-position: -400px 0;
}

.zodiac-menu__link.libra span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -480px -60px no-repeat;
}

.is-active .zodiac-menu__link.libra span, .zodiac-menu__link.libra:hover span, .zodiac-menu__link.libra:focus span {
  background-position: -480px 0;
}

.zodiac-menu__link.scorpio span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -560px -60px no-repeat;
}

.is-active .zodiac-menu__link.scorpio span, .zodiac-menu__link.scorpio:hover span, .zodiac-menu__link.scorpio:focus span {
  background-position: -560px 0;
}

.zodiac-menu__link.sagittarius span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -640px -60px no-repeat;
}

.is-active .zodiac-menu__link.sagittarius span, .zodiac-menu__link.sagittarius:hover span, .zodiac-menu__link.sagittarius:focus span {
  background-position: -640px 0;
}

.zodiac-menu__link.capricorn span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -720px -60px no-repeat;
}

.is-active .zodiac-menu__link.capricorn span, .zodiac-menu__link.capricorn:hover span, .zodiac-menu__link.capricorn:focus span {
  background-position: -720px 0;
}

.zodiac-menu__link.aquarius span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -800px -60px no-repeat;
}

.is-active .zodiac-menu__link.aquarius span, .zodiac-menu__link.aquarius:hover span, .zodiac-menu__link.aquarius:focus span {
  background-position: -800px 0;
}

.zodiac-menu__link.pisces span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -880px -60px no-repeat;
}

.is-active .zodiac-menu__link.pisces span, .zodiac-menu__link.pisces:hover span, .zodiac-menu__link.pisces:focus span {
  background-position: -880px 0;
}

.chinese .zodiac-menu__link.rat span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") 0 -180px no-repeat;
}

.chinese .is-active .zodiac-menu__link.rat span, .chinese .zodiac-menu__link.rat:hover span, .chinese .zodiac-menu__link.rat:focus span {
  background-position: 0 -120px;
}

.chinese .zodiac-menu__link.ox span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -80px -180px no-repeat;
}

.chinese .is-active .zodiac-menu__link.ox span, .chinese .zodiac-menu__link.ox:hover span, .chinese .zodiac-menu__link.ox:focus span {
  background-position: -80px -120px;
}

.chinese .zodiac-menu__link.tiger span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -160px -180px no-repeat;
}

.chinese .is-active .zodiac-menu__link.tiger span, .chinese .zodiac-menu__link.tiger:hover span, .chinese .zodiac-menu__link.tiger:focus span {
  background-position: -160px -120px;
}

.chinese .zodiac-menu__link.hare span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -240px -180px no-repeat;
}

.chinese .is-active .zodiac-menu__link.hare span, .chinese .zodiac-menu__link.hare:hover span, .chinese .zodiac-menu__link.hare:focus span {
  background-position: -240px -120px;
}

.chinese .zodiac-menu__link.dragon span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -320px -180px no-repeat;
}

.chinese .is-active .zodiac-menu__link.dragon span, .chinese .zodiac-menu__link.dragon:hover span, .chinese .zodiac-menu__link.dragon:focus span {
  background-position: -320px -120px;
}

.chinese .zodiac-menu__link.snake span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -400px -180px no-repeat;
}

.chinese .is-active .zodiac-menu__link.snake span, .chinese .zodiac-menu__link.snake:hover span, .chinese .zodiac-menu__link.snake:focus span {
  background-position: -400px -120px;
}

.chinese .zodiac-menu__link.horse span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -480px -180px no-repeat;
}

.chinese .is-active .zodiac-menu__link.horse span, .chinese .zodiac-menu__link.horse:hover span, .chinese .zodiac-menu__link.horse:focus span {
  background-position: -480px -120px;
}

.chinese .zodiac-menu__link.goat span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -560px -180px no-repeat;
}

.chinese .is-active .zodiac-menu__link.goat span, .chinese .zodiac-menu__link.goat:hover span, .chinese .zodiac-menu__link.goat:focus span {
  background-position: -560px -120px;
}

.chinese .zodiac-menu__link.monkey span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -640px -180px no-repeat;
}

.chinese .is-active .zodiac-menu__link.monkey span, .chinese .zodiac-menu__link.monkey:hover span, .chinese .zodiac-menu__link.monkey:focus span {
  background-position: -640px -120px;
}

.chinese .zodiac-menu__link.rooster span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -720px -180px no-repeat;
}

.chinese .is-active .zodiac-menu__link.rooster span, .chinese .zodiac-menu__link.rooster:hover span, .chinese .zodiac-menu__link.rooster:focus span {
  background-position: -720px -120px;
}

.chinese .zodiac-menu__link.dog span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -800px -180px no-repeat;
}

.chinese .is-active .zodiac-menu__link.dog span, .chinese .zodiac-menu__link.dog:hover span, .chinese .zodiac-menu__link.dog:focus span {
  background-position: -800px -120px;
}

.chinese .zodiac-menu__link.pig span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -880px -180px no-repeat;
}

.chinese .is-active .zodiac-menu__link.pig span, .chinese .zodiac-menu__link.pig:hover span, .chinese .zodiac-menu__link.pig:focus span {
  background-position: -880px -120px;
}

.indian .zodiac-menu__link.hawk span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") 0 -300px no-repeat;
}

.indian .is-active .zodiac-menu__link.hawk span, .indian .zodiac-menu__link.hawk:hover span, .indian .zodiac-menu__link.hawk:focus span {
  background-position: 0 -240px;
}

.indian .zodiac-menu__link.beaver span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -80px -300px no-repeat;
}

.indian .is-active .zodiac-menu__link.beaver span, .indian .zodiac-menu__link.beaver:hover span, .indian .zodiac-menu__link.beaver:focus span {
  background-position: -80px -240px;
}

.indian .zodiac-menu__link.elk span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -160px -300px no-repeat;
}

.indian .is-active .zodiac-menu__link.elk span, .indian .zodiac-menu__link.elk:hover span, .indian .zodiac-menu__link.elk:focus span {
  background-position: -160px -240px;
}

.indian .zodiac-menu__link.woodpecker span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -240px -300px no-repeat;
}

.indian .is-active .zodiac-menu__link.woodpecker span, .indian .zodiac-menu__link.woodpecker:hover span, .indian .zodiac-menu__link.woodpecker:focus span {
  background-position: -240px -240px;
}

.indian .zodiac-menu__link.salmon span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -320px -300px no-repeat;
}

.indian .is-active .zodiac-menu__link.salmon span, .indian .zodiac-menu__link.salmon:hover span, .indian .zodiac-menu__link.salmon:focus span {
  background-position: -320px -240px;
}

.indian .zodiac-menu__link.bear span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -400px -300px no-repeat;
}

.indian .is-active .zodiac-menu__link.bear span, .indian .zodiac-menu__link.bear:hover span, .indian .zodiac-menu__link.bear:focus span {
  background-position: -400px -240px;
}

.indian .zodiac-menu__link.raven span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -480px -300px no-repeat;
}

.indian .is-active .zodiac-menu__link.raven span, .indian .zodiac-menu__link.raven:hover span, .indian .zodiac-menu__link.raven:focus span {
  background-position: -480px -240px;
}

.indian .zodiac-menu__link.snake span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -560px -300px no-repeat;
}

.indian .is-active .zodiac-menu__link.snake span, .indian .zodiac-menu__link.snake:hover span, .indian .zodiac-menu__link.snake:focus span {
  background-position: -560px -240px;
}

.indian .zodiac-menu__link.owl span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -640px -300px no-repeat;
}

.indian .is-active .zodiac-menu__link.owl span, .indian .zodiac-menu__link.owl:hover span, .indian .zodiac-menu__link.owl:focus span {
  background-position: -640px -240px;
}

.indian .zodiac-menu__link.goose span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -720px -300px no-repeat;
}

.indian .is-active .zodiac-menu__link.goose span, .indian .zodiac-menu__link.goose:hover span, .indian .zodiac-menu__link.goose:focus span {
  background-position: -720px -240px;
}

.indian .zodiac-menu__link.otter span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -800px -300px no-repeat;
}

.indian .is-active .zodiac-menu__link.otter span, .indian .zodiac-menu__link.otter:hover span, .indian .zodiac-menu__link.otter:focus span {
  background-position: -800px -240px;
}

.indian .zodiac-menu__link.wolf span {
  background: url("/images/blesk-horoskopy/sprites/signs/signs.png") -880px -300px no-repeat;
}

.indian .is-active .zodiac-menu__link.wolf span, .indian .zodiac-menu__link.wolf:hover span, .indian .zodiac-menu__link.wolf:focus span {
  background-position: -880px -240px;
}

.sign-up__close, .modal__close, .choose-signs .choose__sign-link {
  transition: all .25s ease-in-out;
}

#frm-ot #ot-btn-submit:after, .t-link--pink, .t-link--purple, .t-link--gray, .t-link--cerise, .btn, .shadow, .shadow:after, .shadow:active, .shadow--first, .shadow--first:after, .shadow--first:active, .shadow--half, .shadow--half:after, .shadow--half:active, .shadow--third, .shadow--third:after, .shadow--third:active, .shadow--small, .shadow--small:after, .shadow--small:active, .btn--pink, .btn--purple, .btn--gray, .btn--cerise {
  transition: all .15s ease-in-out;
}

.cards-container li, .bio__canvas, .main-menu__link, .main-header__menu .submenu .item, .main-menu__submenu .submenu__item {
  transition: background-color .15s ease-in-out;
}

.ir {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  overflow: hidden;
}

.ir:before {
  content: "";
  width: 0;
  height: 150%;
  display: block;
}

.hidden {
  visibility: hidden;
  display: none !important;
}

.visuallyhidden {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after, .clear {
  clear: both;
}

.float_center {
  float: right;
  text-align: left;
  position: relative;
  left: -50%;
}

.float_center > .floated, .float_center > .fl {
  position: relative;
  left: 50%;
}

.js-modal-hidden {
  top: -110%;
}

.modal {
  z-index: 903;
  background: #fff;
  width: 640px;
  position: absolute;
  top: 960px;
  box-shadow: 0 10px 70px 5px rgba(0, 0, 0, .5);
}

.modal__loading {
  z-index: 10;
  background: rgba(255, 255, 255, .15) url("/images/blesk-horoskopy/loader-white.gif") center no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal__close {
  color: #7b2cea;
  cursor: pointer;
  background: url("/images/blesk-horoskopy/sprites/sprites.png?v=4") -579px 0 no-repeat;
  width: 21px;
  height: 21px;
  display: block;
  position: absolute;
  top: 25px;
  right: 20px;
}

.modal__close:hover {
  transform: rotate(180deg);
}

.modal--overlay {
  z-index: 902;
  background-color: rgba(50, 45, 40, .3);
  width: 100%;
  height: 100%;
  position: absolute;
}

.no-rgba .modal--overlay {
  background-image: url("/images/blesk-horoskopy/overlay-bg.png");
}

.modal--tailored-order {
  width: auto;
}

.modal--tailored-order .t-title {
  color: #6e00ea;
  background-color: #9b81de;
  margin: 0;
  padding: 13px 0 13px 20px;
}

.sign-up__close, .modal--change-date-time-form .modal__close, .modal--change-date-form .modal__close {
  background: url("/images/blesk-horoskopy/sprites/sprites.png?v=4") -542px 0 no-repeat;
  width: 35px;
  height: 35px;
  position: absolute;
  top: -17px;
  right: -17px;
}

.modal--change-date-form .l-1of3 {
  width: 100px;
}

.modal--change-date-form .btn--purple {
  padding: 0 30px;
}

.modal--change-date-time-form .l-1of3 {
  width: 107px;
}

.modal--change-date-time-form .btn--purple {
  padding: 0 30px;
}

.modal--personal-form .sign-up {
  box-shadow: 0 20px 60px -3px #322d28;
}

.sign-up {
  z-index: 2;
  background: #7323eb;
  width: 620px;
  height: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0 -20px 40px -13px #aaa5a0;
}

.h-daily__content--ah .sign-up {
  bottom: -77px;
}

.h-num .h-daily__content--ah .sign-up {
  width: 619px;
  bottom: -1px;
}

.sign-up .sign-up__wrapp {
  padding: 20px 0;
}

.sign-up .l-1of3 {
  width: 87px;
}

.sign-up .sign-up__day {
  width: 67px;
}

.sign-up .sign-up__month {
  width: 117px;
}

.sign-up .sign-up__year {
  width: 77px;
}

.sign-up .input-text {
  border-color: #6e00ea;
}

.sign-up .input-select {
  background-color: #fff;
  border-color: #6e00ea;
  padding-left: 5px;
}

.sign-up .input-select:focus, .sign-up .input-text:focus {
  box-shadow: 0 0 20px -3px #2c005e;
}

.sign-up .input-select.is-invalid, .sign-up .input-text.is-invalid {
  background-color: #fb80c9;
  border-color: #db0a5b;
  box-shadow: 0 0 22px -3px #ff5252;
}

.sign-up__email, .sign-up__name {
  width: 260px;
}

.sign-up__submit {
  width: 258px;
  margin-top: 8px;
}

.sign-up__left {
  width: 320px;
  font-size: 15px;
}

.sign-up__left label, .sign-up__left .t-title {
  color: #fff;
}

.sign-up__left .t-title {
  letter-spacing: -.05ex;
  margin: 0;
}

.sign-up__left input {
  margin-right: 2px;
}

.sign-up__right {
  width: 282px;
}

.sign-up__close {
  cursor: pointer;
}

.sign-up__close:hover {
  transform: rotate(180deg);
}

.commercial-info {
  z-index: 1899;
  text-align: center;
  color: #5a5550;
  background: #f0f1f4;
  width: 100%;
  height: 20px;
  font: 12px / 20px Arial, Helvetica, sans-serif;
  position: fixed;
  bottom: 0;
  left: 0;
}

.commercial-info a {
  color: #9b81de;
}

.commercial-info a:hover, .commercial-info a:focus {
  color: #6e00ea;
}

.common-text h1, .common-text h2, .common-text h3, .common-text h4, .common-text h5, .common-text h6 {
  margin: 1.75em 0 .25em;
  font-family: Roboto, sans-serif;
}

.common-text p {
  margin: .5em 0;
}

.common-text.pink h1, .common-text.pink h2, .common-text.pink h3, .common-text.pink h4, .common-text.pink h5, .common-text.pink h6, .common-text .pink h1, .common-text .pink h2, .common-text .pink h3, .common-text .pink h4, .common-text .pink h5, .common-text .pink h6 {
  color: #fa5ab9;
}

.common-text.purple h1, .common-text.purple h2, .common-text.purple h3, .common-text.purple h4, .common-text.purple h5, .common-text.purple h6, .common-text .purple h1, .common-text .purple h2, .common-text .purple h3, .common-text .purple h4, .common-text .purple h5, .common-text .purple h6 {
  color: #7b2cea;
}

.common-text.gray h1, .common-text.gray h2, .common-text.gray h3, .common-text.gray h4, .common-text.gray h5, .common-text.gray h6, .common-text .gray h1, .common-text .gray h2, .common-text .gray h3, .common-text .gray h4, .common-text .gray h5, .common-text .gray h6 {
  color: #827d78;
}

@media print {
  * {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="javascript:"]:after, a[href^="#"]:after {
    content: "";
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

.fixed {
  position: fixed;
  top: 0;
}

.stickyContent {
  margin: 0 0 10px;
}

.homepageTabs {
  float: left;
  position: relative;
}

.ad300x600 {
  width: 300px;
  height: 600px;
}

.runy_art {
  height: 240px;
}

